import React, { useEffect, useState } from 'react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { searchContent } from '@plone/volto/actions';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { Link } from 'react-router-dom';
import { Container, Grid, Card } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import PortalDefaultCardBg from './default_card_bg.svg';

const messages = defineMessages({
  loginfirst: {
    id: 'loginfirst',
    defaultMessage: 'You must log in first to see your courses.',
  },
  welcome: {
    id: 'Welcome',
    defaultMessage: 'Welcome',
  },
  myCourses: {
    id: 'My Courses',
    defaultMessage: 'My Courses',
  },
  emptyList: {
    id: 'Empty list',
    defaultMessage: 'Empty list',
  },
  signUpForCourses: {
    id: 'Sign up for courses',
    defaultMessage: 'Sign up for courses',
  },
  noCoursesText: {
    id: 'noCoursesText',
    defaultMessage: "You don't have any courses yet.",
  },
  noCoursesLinkText: {
    id: 'noCoursesLinkText',
    defaultMessage: "Let's get started with course enrollment!",
  },
});

const ViewMyCourses = (props) => {
  const intl = useIntl();
  const token = useSelector((state) => state.userSession.token);
  const items = useSelector((state) => state.search.items);
  const currentLang = useSelector((state) => state.intl.locale);
  const dispatch = useDispatch();
  const [courses, setCourses] = useState([]);

  // fills `items` with content.
  useEffect(() => {
    if (token) {
      dispatch(
        searchContent(
          `/students/${encodeURIComponent(jwtDecode(token).sub).replace(
            '%',
            '-',
          )}`,
          {
            portal_type: ['Enrolment'],
            fullobjects: true,
            metadata_fields: '_all',
          },
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const courseItems = [];
    items.forEach((item) => {
      if (item['@type'] === 'Enrolment') {
        courseItems.push(item);
      }
    });
    setCourses(courseItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <div className="MyCourses-view">
      {token ? (
        <div className="Mycourses">
          <div className="mycm-content">
            <Container className="container-courses">
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column>
                    <div className="course-cards-list-template">
                      <Grid stackable>
                        {courses.length === 0 ? ( // Check if the courses array is empty
                          <div className="no-course-container">
                            <p>
                              {intl.formatMessage(messages.noCoursesText)}{' '}
                              {currentLang === 'en' ? (
                                <Link to="/en/courses">
                                  {intl.formatMessage(
                                    messages.noCoursesLinkText,
                                  )}
                                </Link>
                              ) : (
                                <Link to="/fi/courses">
                                  {intl.formatMessage(
                                    messages.noCoursesLinkText,
                                  )}
                                </Link>
                              )}
                            </p>
                          </div>
                        ) : (
                          courses.map((item, index) => (
                            <Grid.Column
                              stretched
                              tablet={6}
                              computer={4}
                              widescreen={3}
                              key={item['UID']}
                            >
                              <Card
                                fluid
                                as={Link}
                                to={flattenToAppURL(item?.course?.['@id'])}
                              >
                                <div className="course-cards-header">
                                  <div
                                    className="course-cards-image"
                                    style={{
                                      backgroundImage: `url(${
                                        flattenToAppURL(
                                          item.image?.scales.large.download ||
                                            '',
                                        ) || PortalDefaultCardBg
                                      })`,
                                    }}
                                  />
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 1440 320"
                                  >
                                    <path
                                      fill="#F6F7F7"
                                      fillOpacity="1"
                                      d="M0,224L80,234.7C160,245,320,267,480,266.7C640,267,800,245,960,213.3C1120,181,1280,139,1360,117.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
                                    ></path>
                                  </svg>
                                </div>
                                <Card.Content>
                                  <div className="course-cards-title">
                                    {item?.course?.title}
                                  </div>
                                </Card.Content>
                                <Card.Content extra>
                                  <Grid>
                                    <Grid.Row columns={2}>
                                      <Grid.Column className="courses-card-meta"></Grid.Column>
                                    </Grid.Row>
                                  </Grid>
                                </Card.Content>
                              </Card>
                            </Grid.Column>
                          ))
                        )}
                      </Grid>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </div>
        </div>
      ) : (
        <Container>
          <div className="not-logged-in-dashboard">
            <h4> {intl.formatMessage(messages.loginfirst)} </h4>
          </div>
        </Container>
      )}
    </div>
  );
};

export default ViewMyCourses;
