import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Card, Container, Grid, Step } from 'semantic-ui-react';
import { Api, Helmet } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import jwtDecode from 'jwt-decode';
import {
  PortalSignInIcon,
  PortalStarIcon,
  VoltoCartIcon,
  VoltoContentListing,
} from '@package/icons';
import { TASK_CHECKOUT_CONFIRMATION_MODULE } from '@package/constants';
import { completeTask, useTask } from 'volto-epics-addon/helpers';
import { BarLoader } from 'react-spinners';
import PortalDefaultCardBg from './default_card_bg.svg';
import { Link } from 'react-router-dom';
import { defaultTitle } from '@package/helpers';

const messages = defineMessages({
  title: {
    id: 'Module registration',
    defaultMessage: 'Module registration',
  },
  thankyou: {
    id: 'checkoutThankYou',
    defaultMessage: 'Thank you for your purchase',
  },
  newModuleTitle: {
    id: 'checkoutNewModuleTitle',
    defaultMessage: 'Start your studies',
  },
  newModuleDesc1: {
    id: 'checkoutNewModuleDesc1',
    defaultMessage:
      'The module has been added to your modules. You can access the modules information by clicking the name of the module on the',
  },
  newModuleDesc1Link: {
    id: 'checkoutNewModuleDesc1Link',
    defaultMessage: 'Dashboard.',
  },
  newModuleDashboardLink: {
    id: 'checkoutNewModuleDashboardLink',
    defaultMessage: 'Back to dashboard',
  },
});

const dateRegex =
  /\((?:19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])\s\u2013\s(?:19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])\)$/;
// eslint-disable-next-line no-useless-escape
const nameRegex = /^[A-Za-zа-яА-ЯÀ-ú0-9\-\s]+\:\s/;

const CheckoutConfirmationModulePage = () => {
  const intl = useIntl();
  const { token } = useSelector((state) => state.userSession);
  const currentLang = useSelector((state) => state.intl.locale);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [registeredUsername, setRegisteredUsername] = useState('');
  const [workflowState, setWorkflowState] = useState('');
  const task = useTask(TASK_CHECKOUT_CONFIRMATION_MODULE);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (!!task) {
      setLoading(false);
    }
  }, [task, setLoading]);

  useEffect(() => {
    if (!token || !task) {
      return;
    }

    const api = new Api();

    let username;
    try {
      username = jwtDecode(token).sub;
    } catch (e) {}

    (async () => {
      if (username) {
        const path = `/students/${username.replace('@', '-40')}`;
        const thisModule = task.variables.module.jsonld_id;
        try {
          const response = await api.get('/@search', {
            params: {
              portal_type: 'Application',
              fullobjects: 1,
              path,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          for (const item of response['items']) {
            const enrolledModule = item?.module?.['@id'];

            function getShort(path) {
              try {
                const pathArray = path.split('/');
                return pathArray[pathArray.length - 1];
              } catch {
                return '';
              }
            }

            if (
              getShort(thisModule) &&
              getShort(enrolledModule) &&
              getShort(thisModule) === getShort(enrolledModule)
            ) {
              setRegisteredUsername(username.split('@')[0]);
              setWorkflowState(item.review_state);
            }
          }
          if (workflowState !== 'accepted') {
            setTimeout(() => {
              setCounter(counter + 1);
            }, 3000);
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
      setLoading(false);

      // Complete on view
      if (task?.completed === false && completed === false) {
        setCompleted(true);
        completeTask(TASK_CHECKOUT_CONFIRMATION_MODULE, {}, false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, task, counter]);

  // get content path by splitting at the language root folder
  const moduleId = task?.variables?.module?.jsonld_id || '/';
  const match = moduleId.match(/(\/[a-z]{2}\/)(.*)/);
  const moduleUrl = match ? match[0] : '/';

  return (
    <div id="page-signup">
      <Helmet title={defaultTitle(intl.formatMessage(messages.title))} />
      <Container>
        <div>
          <Step.Group className="signup-steps" fluid size="mini" unstackable>
            <Step completed>
              <Icon name={PortalSignInIcon} />
            </Step>
            <Step completed>
              <Icon name={VoltoContentListing} />
            </Step>
            <Step completed>
              <Icon name={VoltoCartIcon} />
            </Step>
            <Step active>
              <Icon name={PortalStarIcon} />
            </Step>
          </Step.Group>
        </div>
        <div>
          <h2
            style={{
              fontFamily: 'Aleo',
              fontSize: '2.7em',
              textAlign: 'center',
              marginTop: '2em',
              marginBottom: '1.5em',
              fontWeight: 'bold',
            }}
          >
            {intl.formatMessage(messages.thankyou)}
          </h2>
          <div className="page-signup-content">
            <Grid stretched stackable>
              <Grid.Row verticalAlign="middle" columns="equal">
                <Grid.Column width={7}>
                  <h2>{intl.formatMessage(messages.newModuleTitle)}</h2>
                  <p>
                    {intl.formatMessage(messages.newModuleDesc1)}{' '}
                    <a href={`/${currentLang}`}>
                      {intl.formatMessage(messages.newModuleDesc1Link)}
                    </a>
                  </p>
                  <p>
                    <a href={`/${currentLang}`}>
                      {intl.formatMessage(messages.newModuleDashboardLink)}
                    </a>
                  </p>
                </Grid.Column>
                <Grid.Column width={5}>
                  {registeredUsername ? (
                    <div className="module-cards-list-template">
                      <Card
                        className={
                          workflowState !== 'accepted' ? 'disabled' : ''
                        }
                        fluid
                        as={Link}
                        to={moduleUrl}
                      >
                        {workflowState !== 'accepted' && (
                          <div className="checkout-loader-wrapper">
                            <div className="checkout-loader-content">
                              <p>Wait for registration to complete.</p>
                              <div className="barloader-wrapper">
                                <BarLoader width={200} />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="module-cards-header">
                          <div
                            className="module-cards-image"
                            style={{
                              backgroundImage: `url(${PortalDefaultCardBg})`,
                            }}
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1440 320"
                          >
                            <path
                              fill="#F6F7F7"
                              fillOpacity="1"
                              d="M0,224L80,234.7C160,245,320,267,480,266.7C640,267,800,245,960,213.3C1120,181,1280,139,1360,117.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
                            ></path>
                          </svg>
                        </div>
                        <Card.Content>
                          <div className="module-cards-title">
                            {task?.variables?.module?.title
                              .replace(nameRegex, '')
                              .replace(dateRegex, '')}
                          </div>
                        </Card.Content>
                        <Card.Content extra>
                          <Grid>
                            <Grid.Row columns={2}>
                              <Grid.Column className="module-cards-meta"></Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Card.Content>
                      </Card>
                    </div>
                  ) : (
                    <div className="barloader-wrapper">
                      <BarLoader width={200} />
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CheckoutConfirmationModulePage;
