import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Form, Grid } from 'semantic-ui-react';

const EmailConfirmContainer = ({ email, loading, onConfirm, onCancel }) => {
  return (
    <Container>
      <div className="EmailConfirmContainer" style={{ padding: '3em 0' }}>
        <h3>
          <FormattedMessage
            id="Is the email correct?"
            defaultMessage="Is the email correct?"
          />
        </h3>
        <p className="email">{email}</p>
        <Form loading={loading} className="confirm-email-form">
          <Grid cloumns="equal" stackable>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Button fluid onClick={onCancel}>
                  <FormattedMessage id="No" defaultMessage="No" />
                </Form.Button>
              </Grid.Column>
              <Grid.Column>
                <Form.Button fluid onClick={onConfirm}>
                  <FormattedMessage id="Yes" defaultMessage="Yes" />
                </Form.Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    </Container>
  );
};

export default EmailConfirmContainer;
