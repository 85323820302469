import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Container, Form, Grid } from 'semantic-ui-react';
import StepsNavigation from '@package/components/theme/Identification/StepsNavigation';
import { generate } from 'generate-password';
import PasswordInput from '@package/components/theme/Password/PasswordInput';

const generateSpecial = ({ length }) => {
  const characters = "_,.;:(){}+?=#%&/*'";
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const generatePassword = () => {
  return (
    generate({
      length: 12,
      numbers: false,
      symbols: false,
      lowercase: true,
      excludeSimilarCharacters: true,
      strict: true,
    }) +
    generate({
      length: 5,
      numbers: true,
      symbols: false,
      lowercase: false,
      excludeSimilarCharacters: true,
      strict: true,
    }) +
    generateSpecial({
      length: 3,
    })
  );
};

const messages = defineMessages({
  confirmPass: {
    id: 'Confirm Password',
    defaultMessage: 'Confirm Password',
  },
  genPass: {
    id: 'Generate Password',
    defaultMessage: 'Generate Password',
  },
  passwordDoesNotMatch: {
    id: 'passwordDoesNotMatch',
    defaultMessage: 'Password does not match',
  },
  password: {
    id: 'Password',
    defaultMessage: 'Password',
  },
  helpTitle: {
    id: 'passwordStepHelpTitle',
    defaultMessage: 'Create a password that is 8-30 characters long',
  },
  helpContent: {
    id: 'passwordStepHelpContent',
    defaultMessage:
      'If the password is 8-13 characters long, password must contain characters at least: two capital letters, two small letters, two numbers and two special characters. If the password is 14-30 characters long, password must contain characters at least: one capital letter, one small letter, one number and one special character. Choose a complicated password that is impossible to guess. Do not use the same password in any other service.',
  },
  orWord: {
    id: 'Or',
    defaultMessage: 'Or',
  },
});

const Password = ({ title, formData, setFormData, onNextStep, onPrevStep }) => {
  const [errors, setErrors] = useState({});
  const [hasErrors, setHasErrors] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [generatedPassword, setGeneratedPassword] = useState(0);
  const intl = useIntl();

  useEffect(() => {
    setErrors({
      confirmedPassword: !formData.confirmedPassword
        ? false
        : formData.password !== formData.confirmedPassword
        ? intl.formatMessage(messages.passwordDoesNotMatch)
        : false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    setHasErrors(
      Object.keys(errors).reduce(
        (acc, cur) => acc || !!errors[cur] || !formData[cur],
        false,
      ) || !isValidPassword,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, isValidPassword]);

  const onPasswordValidationChange = (isValid) => {
    setIsValidPassword(isValid);
  };

  return (
    <div className="reg-step-container">
      <Container>
        <h2>
          {title ? (
            title
          ) : (
            <FormattedMessage id="Password" defaultMessage="Password" />
          )}
        </h2>
        <div className="description-container">
          <span className="description-container-header">
            {intl.formatMessage(messages.helpTitle)}
          </span>
          <span className="description-container-body">
            {intl.formatMessage(messages.helpContent)}
          </span>
        </div>
        <Form id="reg-form" aria-label="Please enter your password">
          <Grid
            stackable
            stretched
            style={{
              flexDirection: 'row-reverse',
            }}
          >
            <Grid.Column width={5} verticalAlign="middle">
              {/* If this were button, it would stole form submit keybindings */}
              <a
                tabIndex={0}
                href="#generate-password"
                className="gen-password-button ui button"
                placeholder={intl.formatMessage(messages.genPass)}
                onClick={(e) => {
                  e.preventDefault();
                  setFormData({
                    ...formData,
                    password: generatePassword(),
                  });
                  setIsValidPassword(true);
                  setGeneratedPassword(generatedPassword + 1);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    setFormData({
                      ...formData,
                      password: generatePassword(),
                    });
                    setIsValidPassword(true);
                    setGeneratedPassword(generatedPassword + 1);
                  }
                }}
              >
                {intl.formatMessage(messages.genPass)}
              </a>
            </Grid.Column>
            <Grid.Column width={1} verticalAlign="middle" textAlign="center">
              <span
                style={{
                  fontWeight: '700',
                }}
              >
                {intl.formatMessage(messages.orWord)}
              </span>
            </Grid.Column>
            <Grid.Column width={6} className="password-fields">
              <Form.Input
                fluid
                key={`${generatedPassword}`}
                control={PasswordInput}
                formData={formData}
                label={intl.formatMessage(messages.password)}
                placeholder="Password"
                type="password"
                show={!!generatedPassword}
                value={formData?.password ?? ''}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    password: (e.target.value || '').substr(0, 30),
                  })
                }
                onPasswordValidationChange={onPasswordValidationChange}
              />
              <Form.Input
                fluid
                label={intl.formatMessage(messages.confirmPass)}
                placeholder={intl.formatMessage(messages.confirmPass)}
                type="password"
                value={formData?.confirmedPassword ?? ''}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    confirmedPassword: e.target.value,
                  })
                }
                error={errors.confirmedPassword || false}
              />
            </Grid.Column>
          </Grid>
        </Form>
        <StepsNavigation
          nextStep={!hasErrors}
          onNextStep={onNextStep}
          onPrevStep={onPrevStep}
        />
      </Container>
    </div>
  );
};

export default Password;
