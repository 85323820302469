import React from 'react';

export const EditMyCourses = (props) => {
  return (
    <div className="MyCourses-edit">
      <div className="placeholder">
        <p> Placeholder for Mycourses, shown only on edit mode </p>
      </div>
    </div>
  );
};

export default EditMyCourses;
