/* eslint-disable */
// https://gitlab.kopla.jyu.fi/idm/userappjs/-/blob/master/uavalidators.js
/**

Validator library (customValidators) is a simple JavaScript object with
validation functions.

Each validation function is attached to the object by its public name.

Each validation can be used in User App adding "onchange" JavaScript event
for field with syntax::

   onchange    validate(form, field, VALIDATOR_PUBLIC_NAME, ...)

For example::

   onchange    validate(form, field, "alpha")

will add "alpha" custom validation for the field. The custom validation
is done after the built-in User App validation. It's recommended to use
built-in validation e.g. for minimum and maximum length validations.

Each validation function will receive the value as its first argument
and any number of additional arguments.

You can also pass a value from another field by using syntax::

    onchange    validate(form, field, "complexValidator",
                         form.getField('otherField'))

When validation passes, validation function should return nothing.

If validation fails, validation function must return a new JavaScript object
with localized validation error messages for each supported locale (see
examples below).

The library bundles extended regular expression library http://xregexp.com/

**/
import XRegExp from './xregexp';

/** customPatterns is a global container for custom re-usable regular
    expression patterns */
export const customPatterns = {};

/** Example: */
customPatterns['unicodeWord'] = XRegExp('^[\\p{L}\\s]+$');
// See also: https://github.com/slevithan/xregexp/blob/master/README.md#unicode

/** customNormalizers is a global container for validator specific normalizers
    which are called with validator arguments to normalize the validated value
    before passing it to validator. Normalizers are not required, but they
    must return a string, which is update to form at first and then passed
    to the corresponding validator for validation */
export const customNormalizers = {};

/** Example: */
customNormalizers['alpha'] = function (value) {
  if (value) {
    return value.trim();
  } else {
    return value;
  }
};

/** customValidators is a global container for named validators as they
    are described in the beginning of this file */
export const customValidators = {};

/** Example: */
customValidators['alpha'] = function (value) {
  var patterns = customPatterns;
  if (value.length && !patterns.unicodeWord.test(value)) {
    return {
      fi: "'" + value + "' ei saa sisältää erikoismerkkejä.",
      en: "'" + value + "' cannot contain special characters.",
    };
  }
};

/* Validators by Teppo Vuori, University of Jyväskylä, 15.10.2013 */

/**

Asko Soukka *arveli* että field-objekti on globaali siinä kontekstissa missä
näitä funktioita käytetään. Jos ei ole, funktioita joudutaan muuttamaan.

Kuinka pitkiä virheilmoitukset voivat olla? Onko tarkkaa ylärajaa? Ei.

Voidaanko kenttien pakollisuus (minimipituus) varmistaa näissä funktioissa, vai
onko se käyttöliittymän asia?

= Kutsutaanko tarkistusfunktiota formia palvelimelle lähetettäessä jos kenttään
  ei ole syötetty mitään?

  * [2014-04-14] UserAppin APIn mukaan kutsutaan. -Asko

= Laukeaako kentän onChange-määritys jos formilla on onSubmit (selainten /
  käyttisten välillä on eroja)? Ohjeen mukaan minimi- ja maksimipituudet pitäisi
  asettaa formin määrityksissä.

  * [2014-04-14] UserAppin laukaisee onChangen kertaalleen kaikille kentille
    lomakkeen avauksen yhteydessä; onChangen laukaisu rekisteröi validaattorin
    osaksi lomakkeen doValidate-metodia. Tämän jälkeen validaattoreita
    kutsutaan UserAppin oman koodin kautta lomakkeen lähetyksen yhteydessä
    lomakkeen sisäisen validoinnin (esim. pituustarkistus) ellei UserAppin
    lomakkeenlähetystä ole ylikirjoitettu omalla koodilla. -Asko

Eräät tarkistukset ovat mahdollisia vain jos tarkistusfunktiolle voidaan
välittää enemmän kuin yksi kenttä.

- Henkilöllisyyden tarkistajan nimen pakollisuus on ehdollinen => pitäisi
  tietää mitä ao. valintalistasta on valittu ja missä muodossa ohjelma sen
  näkee.

- Sähköpostiosoitteella voi olla kaksi tarkenninta, mutta vain toinen on
  pakollinen.

03.04.2014 Päätettiin poistaa 1. tarkenninkenttä. Päättivät Miska Sulander ja
Jussi Talaskivi. Toimisiko tämä:

Tarkentimen 1 kohdalla välitetään 1. parametrina 1. tarkennin ja 2. parametrina
sana 'First'.

- Kentän pituus on rajattu 3 merkkiin, joten ohjelma tunnistaa 2. parametrin
  ohjaustiedoksi, ei tarkentimeksi.

- Ohjelma tarkistaa 1. tarkentimen muodon (ei sallita yksittäistä numeroa) ja
  sallii tyhjän kentän. Tarkentimen 2 kohdalla välitetään 1. parametrina 2.
  tarkennin ja 2. parametrina 1. tarkennin.

- Ohjelma tarkistaa 2. tarkentimen muodon ja edellyttää että vähintään yksi
  tarkennin on annettu.

  // Initials must be made up of:
  // Letter a-z or digit 0-9
  // Letter and (letter or digit)
  // Letter.letter or
  // Letter-letter

  Jos molemmat tarkentimet on annettu, sallitaan vain yksi merkki kumpaankin.
  1. tarkennin ei saa olla numero.
*/

// ----------------------------------------------------------------------------
// Re-usable regular expressions for checking text fields.
(function () {
  var patterns = customPatterns;

  // A to Z without digits
  var patAZ = 'a-zA-Z';

  // A to Z with digits
  var patAZD = 'a-zA-Z0-9';

  // Special characters allowed in names
  var patNameSpecial = " '-";

  // Special characters allowed in addresses
  var patAddrSpecial = " '-.,:;/&()";

  // Printable ASCII without < and >
  var patLimtedASCII = ' -;=?-~';

  // Latin-1 Supplement special characters & euro symbol and whitespace.
  var patL1S = '\\u00A0-\\u00BF\\u00D7\\u00F7\\u20AC\\s';

  // Latin-1 Supplement letters.
  var patL1L = '\\u00C0-\\u00D6\\u00D8-\\u00F6\\u00F8-\\u00FF';

  // Latin Extended-A minus one deprecated letter.
  var patLEA = '\\u0100-\\u0148\\u0150-\\u017F';

  // For names allow A-Z, Latin-1 Supplement letters, Latin Extended-A and
  // from ASCII special characters ' - and space
  patterns.namePatt = new RegExp(
    '([^' + patAZ + patL1L + patLEA + patNameSpecial + '])',
  );

  // For Finnish street addresses allow A-Z, digits, Latin-1 Supplement
  // letters and some special characters from ASCII.
  patterns.streetPatt = new RegExp(
    '([^' + patAZD + patL1L + patAddrSpecial + '])',
  );

  // For Finnish cities etc. allow A-Z, Latin-1 Supplement letters and
  // some special characters from ASCII.
  patterns.cityPatt = new RegExp(
    '([^' + patAZ + patL1L + patAddrSpecial + '])',
  );

  // For international addresses allow A-Z, digits, Latin-1 Supplement
  // letters, Latin Extended-A and some special characters from ASCII.
  patterns.foreignPatt = new RegExp(
    '([^' + patAZD + patL1L + patLEA + patAddrSpecial + '])',
  );

  // For free form text fields allow ASCII (without < and >),
  // Latin-1 Supplement and Latin Extended-A.
  patterns.freePatt = new RegExp(
    '([^' + patLimtedASCII + patL1S + patL1L + patLEA + '])',
  );
})();

// ----------------------------------------------------------------------------
// Validate character set in a text field. Allowed characters are:
// p_type = name      => A-Z, Latin-1 Supplement letters, Latin Extended-A special characters , ' and space
// p_type = validator => Like 'name', but not always required
// p_type = street    => A-Z, digits, Latin-1 Supplement letters and some special characters
// p_type = city      => A-Z, and Latin-1 Supplement letters and some special characters
// p_type = foreign   => a-Z, digits, Latin-1 Supplement letters, Latin Extended-A and some special characters
// p_type = free      => ASCII (without < and >), Latin-1 Supplement and Latin Extended-A
// 'Name' is for first and last names.
// 'Street' and 'city' are for Finnish addresses.
// 'Foreign' is for foreign addresses.
// 'Free' is for free form text fields.
// p_identified is only required if p_type = validator
// p_identified = 0      => p_text must be empty
// p_identified = 1 or 2 => p_text must not be empty
customNormalizers['validText'] = function (p_text, p_type) {
  var text = p_text,
    type = p_type.toLowerCase();

  // Remove extra whitespace.
  if (type != 'free') {
    text = text.replace(/(^\s+|\s+$)/g, '').replace(/\s+/g, ' ');
  }

  return text;
};
customValidators['validText'] = function (p_text, p_type, p_identified) {
  var text = p_text,
    type = p_type.toLowerCase(),
    patterns = customPatterns;

  // Ensure that p_identified is an integer
  try {
    p_identified = parseInt(p_identified, 10);
  } catch (e) {
    p_identified = 0;
  }
  if (!p_identified) {
    p_identified = 0;
  }

  // Check field type.
  if (
    type != 'name' &&
    type != 'validator' &&
    type != 'street' &&
    type != 'city' &&
    type != 'foreign' &&
    type != 'free'
  )
    return {
      fi: 'Virhe formin määrittelyssä: Luvaton kenttätyyppi ' + type,
      en: 'Error in form definition: Invalid field type ' + type,
    };

  // Check number of parameters.
  if (type == 'validator' && arguments.length < 3)
    return {
      fi: 'Virhe formin määrittelyssä: Pakollinen parametri puuttuu',
      en: 'Error in form definition: Required parameter is missing',
    };

  // Empty field is not allowed for names and Finnish addresses.
  // Validator is required if customer has been validated.
  if (
    (type == 'name' ||
      (type == 'validator' && p_identified != 0) ||
      type == 'street' ||
      type == 'city') &&
    text == ''
  )
    return {
      fi: 'Kenttä on pakollinen',
      en: 'Field must not be empty',
    };

  // Logically there can be no validator if there is no validation.
  if (type == 'validator' && p_identified == 0 && text != '')
    return {
      fi: 'Vahvistaja on annettu vaikka tunnistautumista ei ole tehty',
      en: 'No validator allowed when there is no identification',
    };

  // Check field. Validator is just a special case of name.
  if (patterns[(type == 'validator' ? 'name' : type) + 'Patt'].test(text))
    return {
      fi: 'Luvaton merkki ' + RegExp.$1,
      en: 'Illegal character ' + RegExp.$1,
    };
};

// ----------------------------------------------------------------------------
// Validate Finnish postal code. Empty field is not allowed.
customNormalizers['validFinPostalCode'] = function (p_code) {
  // Remove whitespace.
  return p_code.replace(/\s+/g, '');
};
customValidators['validFinPostalCode'] = function (p_code) {
  var code = p_code;
  // Check.
  if (!/^\d{5}$/.test(code))
    return {
      fi: 'Kentässä on oltava viisi numeroa; ei kirjaimia, erikoismerkkejä tai välilyöntejä',
      en: 'Field must have five digits; no letters, spaces or special characters',
    };
};

// ----------------------------------------------------------------------------
// Validate international postal code.
customNormalizers['validIntPostalCode'] = function (p_code) {
  // Remove any unnecessary whitespace.
  return p_code.replace(/(^\s+|\s+$)/g, '').replace(/\s+/g, ' ');
};
customValidators['validIntPostalCode'] = function (p_code) {
  var code = p_code;

  // Empty field is allowed.
  if (code == '') return;

  // Check. 3-10 digits and letters allowed.
  var tmp1 = code.replace(/[^\dA-Z]/gi, '').length;
  if (tmp1 < 3 || tmp1 > 10 || !/^[\dA-Z -]{3,10}$/i.test(code))
    return {
      fi: 'Kentässä on oltava 3-10 merkkiä valikoimasta 0-9, A-Z, a-z, väliviiva, välilyönti',
      en: 'Field must have 3-10 characters limited to 0-9, A-Z, a-z, dash, space',
    };
};

// ----------------------------------------------------------------------------
// Validate telephone number.
customNormalizers['validPhoneNumber'] = function (p_phonenumber) {
  var phone = p_phonenumber.replace(/(^\s+|\s+$)/g, '').replace(/\s+/g, ' ');
  // Remove all whitespace.
  return phone.replace(/\s+/g, '');
};
customValidators['validIntPhoneNumber'] = function (p_phonenumber) {
  var phone = p_phonenumber;

  // Empty field is allowed.
  if (phone == '') return;

  // Check international or Finnish domestic format.
  if (!/^(\+\d{8,})$/.test(phone))
    return {
      fi: 'Kansainvälinen muoto: +358401234567',
      en: 'International format: +358401234567',
    };
};
customValidators['validPhoneNumber'] = function (p_phonenumber) {
  var phone = p_phonenumber;

  // Empty field is allowed.
  if (phone == '') return;

  // Check international or Finnish domestic format.
  if (!/^(\+\d{8,}|0\d{7,})$/.test(phone))
    return {
      fi: 'Kansainvälinen muoto: +358401234567, kotimainen muoto: 0401234567',
      en: 'International format: +358401234567, Finnish domestic format: 0401234567',
    };
};

// -----------------------------------------------------------------------------
// Validate Finnish personal identification code (HeTu).
customNormalizers['validHeTu'] = function (p_hetu) {
  // Convert any lowercase letters to uppercase and remove whitespace.
  return p_hetu.toUpperCase().replace(/\s+/g, '');
};
customValidators['validHeTu'] = function (p_hetu) {
  var months = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  var fi_names = [
    'Tammi',
    'Helmi',
    'Maalis',
    'Huhti',
    'Touko',
    'Kesä',
    'Heinä',
    'Elo',
    'Syys',
    'Loka',
    'Marras',
    'Joulu',
  ];
  var en_names = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  var checks = '0123456789ABCDEFHJKLMNPRSTUVWXY';
  var ind1,
    hetu = p_hetu,
    date,
    month,
    year,
    tmp1,
    ref_date,
    birthday;

  // Check length of hetu.
  if (hetu.length != 11) {
    if (/^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])\d{5}[\dA-Z]$/.test(hetu))
      return {
        fi: "Positiossa 7 on oltava '-' tai 'A'",
        en: "There must be either '-' or 'A' in position 7",
      };
    return {
      fi: 'Pituuden on oltava 11 merkkiä',
      en: 'Length must be 11 characters',
    };
  }

  // Check that numeric positions only have digits.
  if (!/\d{6}.\d{3}./.test(hetu))
    for (ind1 = 0; ind1 <= 9; ind1++)
      if (ind1 != 6 && !/\d/.test(hetu.charAt(ind1))) {
        ind1++;
        return {
          fi:
            'Väärä merkki positiossa ' +
            ind1 +
            ', jossa vain numerot ovat sallittuja',
          en:
            'Illegal character in position ' +
            ind1 +
            ' where only digits are allowed',
        };
      }
  // Check that the separator is one of those allowed.
  if ('-A'.indexOf(hetu.charAt(6)) == -1)
    return {
      fi: "Vain '-' ja 'A' ovat sallittuja välimerkkejä (positio 7)",
      en: "Illegal character in position 7; only '-' and 'A' are allowed",
    };

  // Is the check character possible.
  if (checks.indexOf((tmp1 = hetu.charAt(hetu.length - 1))) == -1)
    return {
      fi: "'" + tmp1 + "' ei ole luvallinen tarkiste (viimeinen positio)",
      en: "'" + tmp1 + "' is not a legal check character (last position)",
    };

  // Extract date, month and year.
  date = parseInt(hetu.substr(0, 2), 10);
  month = parseInt(hetu.substr(2, 2), 10);
  year = parseInt(
    '1920'.substr('-A'.indexOf(hetu.charAt(6)) * 2, 2) + hetu.substr(4, 2),
    10,
  );

  // Find if the year was a leap year.
  if (year % 4 == 0 && year != 1900) months[1] = 29;

  // Discard formally incorrect dates.
  if (month < 1 || month > 12)
    return {
      fi: 'Kuukauden (positiot 3..4) on oltava välillä 01..12',
      en: 'Month (positions 3..4) must be between 01..12',
    };
  if (date < 1 || date > months[month - 1]) {
    if (month != 2) {
      return {
        fi:
          fi_names[month - 1] +
          'kuun päivän (positiot 1..2) on oltava välillä 01..' +
          months[month - 1],
        en:
          'Date (positions 1..2) in ' +
          en_names[month - 1] +
          ' must be between 01..' +
          months[month - 1],
      };
    } else {
      return {
        fi:
          'Vuoden ' +
          year +
          ' helmikuun päivän (positiot 1..2) on oltava välillä 01..' +
          months[1],
        en:
          'Date (positions 1..2) in February ' +
          year +
          ' must be between 01..' +
          months[1],
      };
    }
  }
  birthday = new Date(year, month - 1, date);

  // Discard dates over one hundred years old.
  ref_date = new Date();
  ref_date.setYear(ref_date.getFullYear() - 100);
  ref_date.setHours(0, 0, 0, 0);
  if (birthday < ref_date)
    return {
      fi:
        'Henkilö on yli satavuotias (' + date + '.' + month + '.' + year + ')',
      en:
        'The person is over one hundred years old (' +
        date +
        ' ' +
        en_names[month - 1] +
        ' ' +
        year +
        ')',
    };

  // Discard future dates and dates during the past six years.
  ref_date = new Date();
  ref_date.setYear(ref_date.getFullYear() - 6);
  ref_date.setHours(0, 0, 0, 0);
  if (birthday > ref_date)
    return {
      fi:
        'Henkilö on liian nuori tai ei ole vielä syntynyt (' +
        date +
        '.' +
        month +
        '.' +
        year +
        ')',
      en:
        'The person is too young or not yet born (' +
        date +
        ' ' +
        en_names[month - 1] +
        ' ' +
        year +
        ')',
    };

  // Discard too small individual numbers.
  if (parseInt(hetu.substr(7, 3), 10) < 2)
    return {
      fi: 'Yksilönumero (positiot 8..10) ei voi olla pienempi kuin 002',
      en: "Individual number (positions 8..10) can't be less than 002",
    };

  // Compare given and computed check characters.
  if (
    checks.charAt(parseInt(hetu.substr(0, 6) + hetu.substr(7, 3), 10) % 31) !=
    hetu.charAt(10)
  )
    return {
      fi: 'Annettu tarkiste (viimeienen positio) poikkeaa lasketusta',
      en: "Given and computed check characters (last position) don't match",
    };
};

// -----------------------------------------------------------------------------
// Validate date.
// p_flag = BD => Check for sensible birthday (8-100 years ago).
// p_flag = NC => No check for date range.
// p_flag = C2 => Year limited to 1900-2099 (default).
// Other options may be added later.
customNormalizers['validDate'] = function (p_date, p_flag) {
  // Zero pad to two digits.
  function zPad(p1) {
    return (p1 < 10 ? '0' : '') + p1;
  }

  var dd, mm, yy, date;

  // Remove whitespace.
  date = p_date.replace(/\s+/g, '');

  if (/^(\d\d?)\.(\d\d?)\.(\d{4})$/.test(date)) {
    // Extract date, month and year.
    dd = parseInt(RegExp.$1, 10);
    mm = parseInt(RegExp.$2, 10) - 1;
    yy = parseInt(RegExp.$3, 10);

    // Zero pad date and update form field only if needed.
    date = zPad(dd) + '.' + zPad(mm + 1) + '.' + yy;
  }
  return date;
};
customValidators['validDate'] = function (p_date, p_flag) {
  // Zero pad to two digits.
  function zPad(p1) {
    return (p1 < 10 ? '0' : '') + p1;
  }

  var dd, mm, yy, date, ref_date;

  // Check for flag.
  if (arguments.length == 1) p_flag = 'C2';
  p_flag = p_flag.toUpperCase();

  // Check flag content.
  if (p_flag != 'BD' && p_flag != 'NC' && p_flag != 'C2')
    return {
      fi: 'Virhe formin määrittelyssä: Luvaton tarkistustyyppi ' + p_flag,
      en: 'Error in form definition: Invalid check type ' + p_flag,
    };

  // Remove whitespace.
  date = p_date.replace(/\s+/g, '');

  // Empty field is allowed.
  if (date == '') return;

  // Check general format.
  if (!/^(\d\d?)\.(\d\d?)\.(\d{4})$/.test(date))
    return {
      fi: 'Päivämäärän muodon on oltava pp.kk.vvvv',
      en: 'Date format must be dd.mm.yyyy',
    };

  // Extract date, month and year.
  dd = parseInt(RegExp.$1, 10);
  mm = parseInt(RegExp.$2, 10) - 1;
  yy = parseInt(RegExp.$3, 10);
  date = new Date(yy, mm, dd);

  // Check for correct date using JS's Date object.
  if (dd != date.getDate() || mm != date.getMonth() || yy != date.getFullYear())
    return {
      fi: 'Päivä tai kuukausi on virheellinen',
      en: 'Date or month is incorrect',
    };

  // Zero pad date and update form field only if needed.
  ref_date = zPad(dd) + '.' + zPad(mm + 1) + '.' + yy;

  // No date range limit.
  if (p_flag == 'NC') return;

  // Sanity check for birthday.
  if (p_flag == 'BD') {
    // Discard future dates and dates during the past eight years.
    ref_date = new Date();
    ref_date.setYear(ref_date.getFullYear() - 8);
    ref_date.setHours(0, 0, 0, 0);

    if (date > ref_date) {
      return {
        fi: 'Henkilö on liian nuori tai ei ole vielä syntynyt',
        en: 'The person is too young or not yet born',
      };
    }

    // Discard dates over one hundred years old.
    ref_date.setYear(ref_date.getFullYear() - 92);

    if (date < ref_date) {
      return {
        fi: 'Henkilö on yli satavuotias',
        en: 'The person is over one hundred years old',
      };
    }
  }

  // 200 year period is allowed.
  if (p_flag == 'C2' && (yy < 1900 || yy > 2099))
    return {
      fi: 'Vuoden on oltava 1900- tai 2000-luvulla',
      en: 'Only 1900s and 2000s allowed',
    };
};

// -----------------------------------------------------------------------------
// Validate email address.
customNormalizers['validEmailAddress'] = function (p_addr) {
  var addr, left, right;

  // Remove whitespace and update field if required.
  addr = p_addr.replace(/\s+/g, '');

  // Extract parts.
  /^(.*)@(.*)$/.test(addr);
  left = RegExp.$1;
  right = RegExp.$2;

  // Remove final dot if present and update field.
  if (/\.$/.test(right)) {
    right = right.replace(/\.$/, '');
    addr = left + '@' + right;
  }

  return addr;
};
customValidators['validEmailAddress'] = function (p_addr) {
  var addr = p_addr,
    left,
    right;

  var err = {
    fi: 'Osoitteen muoto on virheellinen',
    en: 'Format of address is incorrect',
  };

  // Empty field is allowed.
  if (addr == '') return;

  // Allow only a limited character set.
  // Allow exactly one @.
  // Don't allow two consequtive dots or dashes.
  if (
    /[^@a-z\d.-]/i.test(addr) ||
    addr.replace(/[^@]/g, '').length != 1 ||
    /[.-]{2}/.test(addr)
  ) {
    return err;
  }

  // Extract parts.
  /^(.*)@(.*)$/.test(addr);
  left = RegExp.$1;
  right = RegExp.$2;

  // Left side.
  // At least one character.
  // No dot or dash as the first or last character.
  if (left.length == 0 || /^[.-]|[.-]$/.test(left)) {
    return err;
  }

  // Right side.
  // Remove final dot if present and update field.
  if (/\.$/.test(right)) {
    right = right.replace(/\.$/, '');
  }

  // At least four characters.
  // First charatcter must be letter or number and two last characters must be letters.
  // Must have at least one dot.
  if (
    right.length < 4 ||
    !/^[a-z\d].+[a-z]{2}$/i.test(right) ||
    !/\./.test(right)
  ) {
    return err;
  }
};

// -----------------------------------------------------------------------------
// Validate email initials.
/** customValidators["validEmailInitials"] = function(p_emailinitials) {

    var initials = p_emailinitials.toLowerCase().replace(/\s+/g, "");

// Update field only if needed.
    if (initials != p_emailinitials) field.setValues(initials);

// ** Tämä ei käy näin! Kahdesta kentästä vain toinen on pakollinen.

// Initials must be made up of:
// Letter a-z or digit 0-9 (DIGIT SHOULD NOT BE ALLOWED FOR FIRST FIELD!)
// Letter and (letter or digit)
// Letter.letter or
// Letter-letter
    if (!/^([a-z]|\d|[a-z]([a-z]|\d)|[a-z][\.-][a-z])$/.test(initials))
    return {
        "fi": "Tarkentimen muoto on virheellinen",
        "en": "Format of initials is incorrect"
    };
}; */

/** Replaced.
// -----------------------------------------------------------------------------
// Validate email initials.
// There are two initials fields. Each is tested separately.
// When the first field is checked, the second parameter must be "First".
// - In this case the second field will not be checked.
// When the second field is checked, the second parameter must be the first field.
// - In this case the first field's length will be checked, but format will not.
customValidators["validEmailInitials"] = function(p_emailinitials, p_tof) {

    var init = p_emailinitials.toLowerCase().replace(/\s+/g, "");

// Update field only if needed.
    if (init != p_emailinitials) field.setValues(init);

// The first field can be empty.
// When checking the second field, both must not be empty.
    if (init == "")
    if (p_tof)
        return;
        else
        return {
        "fi": "Vähintään yksi tarkennin on pakollinen",
            "en": "At least one initial must be given"
        };

// Initials must be made up of:
// Letter a-z or digit 0-9 (lone digit is allowed only in the second field)
// Letter and (letter or digit)
// Letter.letter or
// Letter-letter
    if (p_tof == "First" && !/^([a-z]|[a-z]([a-z]|\d)|[a-z][\.-][a-z])$/.test(init) ||
    !/^([a-z]|\d|[a-z]([a-z]|\d)|[a-z][\.-][a-z])$/.test(init))
    return {
        "fi": "Tarkentimen muoto on virheellinen",
        "en": "Format of initials is incorrect"
    };

// Max. two characters when borh fields are used.
    if (p_tof != "First" && (p_tof.length > 1 || init.length > 1 && p_tof != ""))
    return {
        "fi": "Vain yksi merkki / kenttä kun käytetään molempia tarkentimia",
        "en": "Only one character / field when both initials fields are used."
    };
} */

// -----------------------------------------------------------------------------
// Validate email initials.
customNormalizers['validEmailInitials'] = function (p_emailinitials) {
  // Remove any whitespace and convert to lowercase.
  return p_emailinitials.replace(/\s+/g, '').toLowerCase();
};
customValidators['validEmailInitials'] = function (p_emailinitials) {
  var init = p_emailinitials;
  // The field is required.
  if (init == '')
    return {
      fi: 'Kenttä on pakollinen',
      en: 'The field must not be empty',
    };
  // Initials must be made up of:
  // Letter a-z or digit 0-9
  // Letter and (letter or digit)
  // Letter.letter or
  // Letter-letter
  if (!/^([a-z\d]|[a-z][a-z\d]|[a-z][\.-][a-z])$/.test(init))
    return {
      fi: 'Tarkentimen muoto on virheellinen',
      en: 'Format of initials is incorrect',
    };
};
