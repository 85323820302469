import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Segment,
  Step,
  Table,
} from 'semantic-ui-react';
import { Helmet } from '@plone/volto/helpers';
import { defineMessages, useIntl } from 'react-intl';
import { Icon, Toast } from '@plone/volto/components';
import {
  PortalBadgeIcon,
  PortalContinueIcon,
  PortalCourseIcon,
  PortalSignInIcon,
  PortalStarIcon,
  VoltoCartIcon,
  VoltoContentListing,
} from '@package/icons';
import { TASK_CHECKOUT, TASK_PROCEED_TO_PAY } from '@package/constants';
import { completeTask, useTask } from 'volto-epics-addon/helpers';
import { defaultTitle } from '@package/helpers';
import { toast } from 'react-toastify';

const messages = defineMessages({
  title: {
    id: 'Registration',
    defaultMessage: 'Registration',
  },
  yourorder: {
    id: 'Your order',
    defaultMessage: 'Your order:',
  },
  checkout: {
    id: 'Checkout',
    defaultMessage: 'Checkout',
  },
  continue: {
    id: 'Continue',
    defaultMessage: 'Continue',
  },
  voucherText: {
    id: 'voucherText',
    defaultMessage:
      'If you have a voucher or a promotion code, please enter it now and press Redeem',
  },
  enterPromotionCode: {
    id: 'enterPromotionCode',
    defaultMessage: 'Enter promotion code here',
  },
  redeem: {
    id: 'Redeem',
    defaultMessage: 'Redeem',
  },
  paymentCancellationTermsCheckboxLabel: {
    id: 'paymentCancellationTermsCheckboxLabel',
    defaultMessage: 'Accept Terms of Cancellation',
  },
  paymentCancellationTermsNotAccepted: {
    id: 'paymentCancellationTermsNotAccepted',
    defaultMessage: 'You must accept Terms of Cancellation to continue',
  },
  paymentCancellationTermsTitle: {
    id: 'paymentCancellationTermsTitle',
    defaultMessage: 'Terms of Cancellation',
  },
  paymentCancellationTermsHeading1: {
    id: 'paymentCancellationTermsHeading1',
    defaultMessage:
      'Cancellation terms concerning registration for studies at the JYU Open University',
  },
  paymentCancellationTermsText1: {
    id: 'paymentCancellationTermsText1',
    defaultMessage:
      'Registration for Open university studies is binding. A payment obligation is created when a student enrols for studies or accepts a study place. Study fees are not returned unless the Open University has to cancel the studies, for example, because of low number of students and is unable to offer alternative teaching.<NEWLINE>If there is a specific application and admission process for the studies, the student commits to the studies when accepting the study place. Study fees are not returned nor cancelled unless the Open University has to cancel the studies, for example, because of low number of students and is unable to offer alternative teaching.',
  },
  paymentCancellationTermsHeading2: {
    id: 'paymentCancellationTermsHeading2',
    defaultMessage:
      'Cancellation terms concerning registration for separate studies at JYU',
  },
  paymentCancellationTermsText2: {
    id: 'paymentCancellationTermsText2',
    defaultMessage:
      'Registration for non-degree studies is binding. A payment obligation is created when a student enrols for studies or accepts a study place. Study fees are not returned unless the University of Jyväskylä has to cancel the studies, for example, because of low number of students and is unable to offer alternative teaching.<NEWLINE>If there is a specific application and admission process for the studies, the student commits to the studies when accepting the study place. Study fees are not returned nor cancelled unless the University of Jyväskylä has to cancel the studies, for example, because of low number of students and is unable to offer alternative teaching.',
  },
  paymentCancellationTermsHeading3: {
    id: 'paymentCancellationTermsHeading3',
    defaultMessage:
      'Identification and confirmation of registration through the Sisu study information system',
  },
  paymentCancellationTermsText3: {
    id: 'paymentCancellationTermsText3',
    defaultMessage:
      'After paying the study fee, the student needs to confirm their registration for the studies. The confirmation is done by electronic identification either by a JYU user ID or through the Suomi.fi service. The confirmation must be done within 9 days from the study fee payment. Unconfirmed registration does not entitle to a refund of the study fee.',
  },
  paymentCancellationTermsHeading4: {
    id: 'paymentCancellationTermsHeading4',
    defaultMessage: 'Online payment',
  },
  paymentCancellationTermsText4: {
    id: 'paymentCancellationTermsText4',
    defaultMessage:
      'Online payment concerns registrations made through the Sisu study information system and the Ilpo registration portal.<NEWLINE>The study fee is paid online upon registration. Payment is made as part of the registration process.<NEWLINE>Study fees are receivables under public law and are distrainable without separate measures (Act on the Enforcement of Taxes and Public Payments 706/2007).',
  },
  paymentCancellationTermsHeading5: {
    id: 'paymentCancellationTermsHeading5',
    defaultMessage: 'Address for rectification requests',
  },
  paymentCancellationTermsText5: {
    id: 'paymentCancellationTermsText5',
    defaultMessage:
      'A person liable for payment who considers that an error has occurred in the determination of the payment ordered for public service can request a rectification for it from the University of Jyväskylä within six months from issuing the payment. The rectification request is submitted to the following address: JYU Open University, P.O. Box 35, 40014 University of Jyväskylä.<NEWLINE>An appeal against a decision on a request for rectification can be lodged with the Administrative Court of Hämeenlinna. The appeal period is 30 days from the notification of the decision. The decision of the administrative court is not subject to appeal.<NEWLINE>Act on Criteria for Charges Payable to the State (150/1992), section 11b<NEWLINE>Universities Act (558/2009), sections 8 and 83a<NEWLINE>Government Decree on Fees Collected by Universities (1082/2009)<NEWLINE>(Valtioneuvoston asetus yliopistojen toiminnassa perittävistä maksuista annetun valtioneuvoston asetuksen 2 §:n muuttamisesta (1436/2014))',
  },
});

const CheckoutPage = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [voucherCode, setVoucherCode] = useState('');
  const [productTitle, setProductTitle] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productCredits, setProductCredits] = useState('');
  const task = useTask(TASK_CHECKOUT, TASK_PROCEED_TO_PAY);

  useEffect(() => {
    if (task?.variables?.course?.title) {
      setProductTitle(task.variables.course.title);
    } else if (task?.variables?.module?.title) {
      setProductTitle(task.variables.module.title);
    }

    if (task?.variables?.course?.price) {
      setProductPrice(task.variables.course.price);
    } else if (task?.variables?.module?.price) {
      setProductPrice(task.variables.module.price);
    }

    if (task?.variables?.course?.credits) {
      setProductCredits(task.variables.course.credits);
    }

    if (task) {
      setLoading(false);
    }
    if (
      task?.taskDefinitionKey === TASK_PROCEED_TO_PAY &&
      task?.completed === false &&
      task?.variables?.paymentAddress
    ) {
      window.location = task.variables.paymentAddress;
    }
  }, [task]);

  return (
    <div id="page-signup">
      <Helmet title={defaultTitle(intl.formatMessage(messages.title))} />
      <Container>
        <div>
          <Step.Group className="signup-steps" fluid size="mini" unstackable>
            <Step completed>
              <Icon name={PortalSignInIcon} />
            </Step>
            <Step completed>
              <Icon name={VoltoContentListing} />
            </Step>
            <Step active>
              <Icon name={VoltoCartIcon} />
            </Step>
            <Step>
              <Icon name={PortalStarIcon} />
            </Step>
          </Step.Group>
        </div>
        <div>
          <h2
            style={{
              fontFamily: 'Aleo',
              fontSize: '2.7em',
              textAlign: 'center',
              marginTop: '2em',
              marginBottom: '1.5em',
              fontWeight: 'bold',
            }}
          >
            {intl.formatMessage(messages.checkout)}
          </h2>
          <div className="page-signup-content">
            <Grid
              stretched
              stackable
              textAlign="center"
              style={{
                marginTop: '1em',
                backgroundColor: '#f6f7f7',
                borderRadius: '8px',
                padding: '1em 0',
              }}
            >
              <Grid.Row verticalAlign="middle" columns="equal">
                <h2>
                  {intl.formatMessage(messages.paymentCancellationTermsTitle)}
                </h2>
                <Form
                  id="checkout-terms-form"
                  aria-label="Please check terms of cancellation"
                >
                  <Grid stackable stretched>
                    <Grid.Column width={12}>
                      <div className="term-description">
                        <h3>
                          {intl.formatMessage(
                            messages.paymentCancellationTermsHeading1,
                          )}
                        </h3>
                        <div>
                          {intl
                            .formatMessage(
                              messages.paymentCancellationTermsText1,
                            )
                            .split('<NEWLINE>')
                            .map((part, idx) => (
                              <p key={idx}>{part}</p>
                            ))}
                        </div>
                        <h3>
                          {intl.formatMessage(
                            messages.paymentCancellationTermsHeading2,
                          )}
                        </h3>
                        <div>
                          {intl
                            .formatMessage(
                              messages.paymentCancellationTermsText2,
                            )
                            .split('<NEWLINE>')
                            .map((part, idx) => (
                              <p key={idx}>{part}</p>
                            ))}
                        </div>
                        <h3>
                          {intl.formatMessage(
                            messages.paymentCancellationTermsHeading3,
                          )}
                        </h3>
                        <div>
                          {intl
                            .formatMessage(
                              messages.paymentCancellationTermsText3,
                            )
                            .split('<NEWLINE>')
                            .map((part, idx) => (
                              <p key={idx}>{part}</p>
                            ))}
                        </div>
                        <h3>
                          {intl.formatMessage(
                            messages.paymentCancellationTermsHeading4,
                          )}
                        </h3>
                        <div>
                          {intl
                            .formatMessage(
                              messages.paymentCancellationTermsText4,
                            )
                            .split('<NEWLINE>')
                            .map((part, idx) => (
                              <p key={idx}>{part}</p>
                            ))}
                        </div>
                        <h3>
                          {intl.formatMessage(
                            messages.paymentCancellationTermsHeading5,
                          )}
                        </h3>
                        <div>
                          {intl
                            .formatMessage(
                              messages.paymentCancellationTermsText5,
                            )
                            .split('<NEWLINE>')
                            .map((part, idx) => (
                              <p key={idx}>{part}</p>
                            ))}
                        </div>
                      </div>
                      <div className="checkbox-wrapper">
                        <Form.Checkbox
                          className="term-checkbox"
                          label={intl.formatMessage(
                            messages.paymentCancellationTermsCheckboxLabel,
                          )}
                          checked={acceptTerms || false}
                          onChange={(e, data) => {
                            setAcceptTerms(data.checked);
                          }}
                        />
                      </div>
                    </Grid.Column>
                  </Grid>
                </Form>
              </Grid.Row>
            </Grid>
            <Divider />

            <Grid
              stretched
              stackable
              textAlign="center"
              style={{
                marginTop: '1em',
                backgroundColor: '#f6f7f7',
                borderRadius: '8px',
                padding: '1em 0',
              }}
            >
              <Grid.Row verticalAlign="middle" columns="equal">
                <Grid.Column width={1} textAlign="center">
                  <Icon name={PortalBadgeIcon} />
                </Grid.Column>
                <Grid.Column width={7}>
                  {intl.formatMessage(messages.voucherText)}
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Form loading={loading}>
                    <Form.Input
                      fluid
                      type="text"
                      onChange={(e) => {
                        setVoucherCode(e.target.value);
                      }}
                      placeholder={intl.formatMessage(
                        messages.enterPromotionCode,
                      )}
                    />
                    <Form.Button
                      className="promotion-button"
                      content={intl.formatMessage(messages.redeem)}
                      disabled={!voucherCode}
                      onClick={() => {
                        setLoading(true);
                        completeTask(TASK_CHECKOUT, {
                          voucherCode,
                        });
                      }}
                    />
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider />

            <div className="content-header">
              <h3>{intl.formatMessage(messages.yourorder)}</h3>
            </div>
            <Segment basic loading={loading}>
              <Table className="order-table">
                <Table.Body>
                  <Table.Row style={{ boxShadow: 'none !important' }}>
                    <Table.Cell className="product-title-cell" width={8}>
                      <div className="title-wrapper">
                        <Icon name={PortalCourseIcon} size="36px" />
                        <span>{productTitle ?? 'n/a'}</span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="product-price-cell">
                      <div>
                        <span>{productPrice ?? 'n/a'} €</span>
                        <span
                          style={{
                            fontSize: '18px',
                            color: '#6C7979',
                          }}
                        >
                          {productCredits ? productCredits.toUpperCase() : ''}
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="button-cell">
                      <Button
                        icon
                        labelPosition="right"
                        onClick={async (e) => {
                          if (!acceptTerms) {
                            toast.error(
                              <Toast
                                error
                                content={intl.formatMessage(
                                  messages.paymentCancellationTermsNotAccepted,
                                )}
                              />,
                            );
                            return;
                          }
                          setLoading(true);
                          await completeTask(TASK_CHECKOUT, {
                            voucherCode: null,
                          });
                        }}
                      >
                        {intl.formatMessage(messages.continue)}
                        <Icon
                          className="center middle"
                          name={PortalContinueIcon}
                          size="1em"
                          fill="white"
                        />
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CheckoutPage;
