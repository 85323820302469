/**
 * Routes.
 * @module routes
 */

import { App } from '@plone/volto/components';
import AdminLogin from '@package/components/theme/AdminLogin/AdminLogin';
import { defaultRoutes } from '@plone/volto/routes';
import config from '@plone/volto/registry';
import { EPICS_TASK_VIEWS } from 'volto-epics-addon/constants';
import EmailSignInPage from '@package/components/theme/Login/EmailSignInPage';
import EmailSignUpPage from '@package/components/theme/Login/EmailSignUpPage';
import {
  TASK_APPLICATION_VERIFY_CONTACT_INFORMATION,
  TASK_ACCOUNT_AUTHENTICATION_FORM,
  TASK_ACCOUNT_AUTHENTICATION_SIGNICAT_PROMPT,
  TASK_ACCOUNT_AUTHENTICATION_IDENTIFICATION_METHOD,
  TASK_CHECKOUT,
  TASK_CHECKOUT_CONFIRMATION_COURSE,
  TASK_CHECKOUT_CONFIRMATION_MODULE,
  TASK_EMAIL_LOGIN,
  TASK_PASSWORD_CHANGE_FORM,
  TASK_PASSWORD_CHANGE_SIGNICAT_PROMPT,
  TASK_PASSWORD_CHANGE_IDENTIFICATION_METHOD,
  TASK_PROCEED_TO_PAY,
  TASK_IDENTIFICATION_METHOD,
  TASK_REGISTRATION_FORM,
  TASK_SIGNICAT_CAPTURE,
  TASK_SIGNICAT_PROMPT,
  TASK_SUOMIFI_REDIRECT,
  TASK_VERIFY_EMAIL,
  TASK_VERIFY_EMAIL_PROMPT,
} from '@package/constants';
import EmailVerificationPage from '@package/components/theme/EmailVerification/EmailVerificationPage';
import CheckoutPage from '@package/components/theme/Checkout/CheckoutPage';
import RegistrationSignicatPage from '@package/components/theme/Registration/RegistrationSignicatPage';
import SuomifiRedirectPage from '@package/components/theme/Identification/SuomifiRedirectPage';
import RegistrationFormPage from '@package/components/theme/Registration/RegistrationFormPage';
import PasswordChangeSignicatPage from '@package/components/theme/Password/PasswordChangeSignicatPage';
import SignicatRedirectPage from '@package/components/theme/Identification/SignicatRedirectPage';
import PasswordChangeFormPage from '@package/components/theme/Password/PasswordChangeFormPage';
import CheckoutRedirectPage from '@package/components/theme/Checkout/CheckoutRedirectPage';
import CheckoutConfirmationCoursePage from '@package/components/theme/Checkout/CheckoutConfirmationCoursePage';
import CheckoutConfirmationModulePage from '@package/components/theme/Checkout/CheckoutConfirmationModulePage';
import AuthenticationSignicatPage from '@package/components/theme/Authentication/AuthenticationSignicatPage';
import AuthenticationFormPage from '@package/components/theme/Authentication/AuthenticationFormPage';
import VerifyContactInformationPage from '@package/components/theme/Application/VerifyContactInformationPage';
import {
  RegistrationIdentificationMethodPage,
  AuthenticationIdentificationMethodPage,
  PasswordIdentificationMethodPage,
} from '@package/components/theme/Identification/IdentificationMethodPage';

/**
 * Routes array.
 * @array
 * @returns {array} Routes.
 */

const routes = [
  {
    path: '/',
    component: App, // Change this if you want a different component
    routes: [
      {
        path: '/admin-login',
        component: AdminLogin,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_EMAIL_LOGIN],
        component: EmailSignInPage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_VERIFY_EMAIL_PROMPT],
        component: EmailSignUpPage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_VERIFY_EMAIL],
        component: EmailVerificationPage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_PROCEED_TO_PAY],
        component: CheckoutRedirectPage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_CHECKOUT_CONFIRMATION_COURSE],
        component: CheckoutConfirmationCoursePage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_CHECKOUT_CONFIRMATION_MODULE],
        component: CheckoutConfirmationModulePage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_CHECKOUT],
        component: CheckoutPage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_IDENTIFICATION_METHOD],
        component: RegistrationIdentificationMethodPage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_SIGNICAT_PROMPT],
        component: RegistrationSignicatPage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_SIGNICAT_CAPTURE],
        component: SignicatRedirectPage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_SUOMIFI_REDIRECT],
        component: SuomifiRedirectPage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_REGISTRATION_FORM],
        component: RegistrationFormPage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_PASSWORD_CHANGE_IDENTIFICATION_METHOD],
        component: PasswordIdentificationMethodPage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_PASSWORD_CHANGE_SIGNICAT_PROMPT],
        component: PasswordChangeSignicatPage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_PASSWORD_CHANGE_FORM],
        component: PasswordChangeFormPage,
      },
      {
        path: EPICS_TASK_VIEWS[
          TASK_ACCOUNT_AUTHENTICATION_IDENTIFICATION_METHOD
        ],
        component: AuthenticationIdentificationMethodPage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_ACCOUNT_AUTHENTICATION_SIGNICAT_PROMPT],
        component: AuthenticationSignicatPage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_ACCOUNT_AUTHENTICATION_FORM],
        component: AuthenticationFormPage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_APPLICATION_VERIFY_CONTACT_INFORMATION],
        component: VerifyContactInformationPage,
      },
      ...(config.addonRoutes || []),
      ...defaultRoutes,
    ],
  },
];

// // Here we are could change existing routes
// const valueToChange = routes[0].routes.findIndex(
//   (obj) => obj.path === '/register',
// );
// routes[0].routes[valueToChange] = {
//   path: '/en/register',
//   component: SignicatPage,
// };

export default routes;
