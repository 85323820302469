/**
 * Module view component.
 * @module components/theme/View/ModuleView
 */

import React, { useState, useEffect } from 'react';
import { flattenToAppURL } from '@plone/volto/helpers';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Button, Container, Grid } from 'semantic-ui-react';
import { map } from 'lodash';
import jwtDecode from 'jwt-decode';
import config from '@plone/volto/registry';
import { Api } from '@plone/volto/helpers';
import {
  getBaseUrl,
  getBlocksFieldname,
  getBlocksLayoutFieldname,
} from '@plone/volto/helpers';
import { EPIC_MODULE_APPLICATION } from '@package/constants';
import { startEpic } from 'volto-epics-addon/helpers';

const messages = defineMessages({
  priceTagFree: {
    id: 'modulePriceTagFree',
    defaultMessage: 'Free of charge',
  },
  priceTagPay: {
    id: 'modulePriceTagPay',
    defaultMessage: 'Pay',
  },
  moduleHighlightTitle: {
    id: 'moduleHightlightTitle',
    defaultMessage: 'Module',
  },
  moduleFinalApplicationDate: {
    id: 'moduleFinalApplicationDate',
    defaultMessage: 'Final application date:',
  },
  moduleApplicationInstructionsTitle: {
    id: 'moduleApplicationInstructionsTitle',
    defaultMessage: 'Module Application',
  },
  moduleApplicationInstructionsDescription: {
    id: 'moduleApplicationInstructionsDescription',
    defaultMessage:
      'To apply for Modules, complete the application form. Below are some guidelines to assist you:',
  },
  moduleApplicationInstructionsListPart1: {
    id: 'moduleApplicationInstructionsListPart1',
    defaultMessage: 'Start the application by clicking on "Apply".',
  },
  moduleApplicationInstructionsListPart2: {
    id: 'moduleApplicationInstructionsListPart2',
    defaultMessage:
      'Familiarize yourself with the form and gather the required texts and attachments in advance.',
  },
  moduleApplicationInstructionsListPart3: {
    id: 'moduleApplicationInstructionsListPart3',
    defaultMessage:
      'Complete the form meticulously, ensuring you answer every question.',
  },
  moduleApplicationInstructionsListPart4: {
    id: 'moduleApplicationInstructionsListPart4',
    defaultMessage: 'Clearly label all attachment files.',
  },
  moduleApplicationInstructionsListPart5: {
    id: 'moduleApplicationInstructionsListPart5',
    defaultMessage:
      'When your application is ready, remember to click the "Save as finished" button so that your application will be processed.',
  },
  moduleApplicationInstructionsFinal: {
    id: 'moduleApplicationInstructionsFinal',
    defaultMessage:
      'Your application will be processed after the application period ends, and you will receive notification of the selection result at the email address you provided.',
  },
  moduleEnrolmentInstructionsTitle: {
    id: 'moduleEnrolmentInstructionsTitle',
    defaultMessage: 'Enrolling in Sisu',
  },
  moduleEnrolmentInstructionsListPart1: {
    id: 'moduleEnrolmentInstructionsListPart1',
    defaultMessage: 'Visit the Sisu login page and sign in using your account.',
  },
  moduleEnrolmentInstructionsListPart2: {
    id: 'moduleEnrolmentInstructionsListPart2',
    defaultMessage:
      'Click "Search", enter the course name or code, then press "Search".',
  },
  moduleEnrolmentInstructionsListPart3: {
    id: 'moduleEnrolmentInstructionsListPart3',
    defaultMessage: 'Choose the desired course from the displayed results.',
  },
  moduleEnrolmentInstructionsListPart4: {
    id: 'moduleEnrolmentInstructionsListPart4',
    defaultMessage:
      'Hit "Enrol", review the details, and, if necessary, select a schedule and group.',
  },
  moduleEnrolmentInstructionsListPart5: {
    id: 'moduleEnrolmentInstructionsListPart5',
    defaultMessage: 'Press "Verify enrolment" and follow the prompted steps.',
  },
  registration: {
    id: 'registration',
    defaultMessage: 'Online registration instructions',
  },
  registrationDescriptionPart1: {
    id: 'registrationDescriptionPart1',
    defaultMessage:
      'Start the process by registering and creating a JYU user ID. Registration requires electronic identification (online banking identifiers or mobile ID). If you do not have Finnish online banking identifiers, you can use your passport or EU ID card for the identification process. Please note that you cannot use a Finnish passport or ID card for identification.',
  },
  registrationDescriptionPart2: {
    id: 'registrationDescriptionPart2',
    defaultMessage:
      'If you already have a JYU user ID, start the registration process by signing in with your email address and password.',
  },
});

/**
 * Component to display the default view.
 * @function DefaultView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const DefaultView = ({ content }) => {
  const { token } = useSelector((state) => state.userSession);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(!!token);
  const [workflowState, setWorkflowState] = useState('');
  const [applicationPath, setApplicationPath] = useState('');
  const [sisuEnrolmentCompleted, setSisuEnrolmentCompleted] = useState(true);
  const history = useHistory();

  const location = useLocation();
  const intl = useIntl();

  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);

  const today = new Date().toISOString().split('T')[0];

  const applicationOpen =
    content.application_start_date &&
    content.application_end_date &&
    today >= content.application_start_date &&
    today <= content.application_end_date;

  const priceTag =
    `${content.price}` === '0'
      ? intl.formatMessage(messages.priceTagFree)
      : intl.formatMessage(messages.priceTagPay);

  useEffect(() => {
    let isMounted = true;

    const api = new Api();
    let username;
    try {
      username = jwtDecode(token).sub;
    } catch (e) {}

    (async () => {
      if (username) {
        try {
          const response = await api.get('/@relations', {
            params: {
              target: content['UID'],
              query_source: `/students/${username.replace('@', '-40')}`,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          for (const item of response['relations']?.['module']?.['items'] ||
            []) {
            if (isMounted) {
              setApplicationPath(item.source['@id']);
              setWorkflowState(item.source.review_state);
            }
            const workflow = await api.get(
              new URL(item['source']['@id']).pathname + '/@workflow',
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            );
            if (
              workflow?.['chain']?.['sisu_enrolment_workflow']?.['state']?.[
                'id'
              ] === 'in_progress'
            ) {
              setSisuEnrolmentCompleted(false);
            }
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
      if (isMounted) {
        setLoading(false);
      }
    })();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div id="module-view">
      <Container tabIndex={0} className="module-container">
        <div>
          <h1>{content.title}</h1>

          <div className="module-highlights">
            <div className="highlight">{priceTag}</div>
            <div className="highlight">
              {moment(content.module_start_date).format('DD.MM.YYYY')} {' – '}
              {moment(content.module_end_date).format('DD.MM.YYYY')}
            </div>
            <div className="highlight">
              {intl.formatMessage(messages.moduleHighlightTitle)}
            </div>
            {content.organisation_name ? (
              <div className="highlight">{content.organisation_name}</div>
            ) : (
              ''
            )}
          </div>
        </div>

        <Grid stackable relaxed>
          <Grid.Row>
            <Grid.Column width="12">
              <div>
                <p>{content.description}</p>
                {content.text && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content.text.data,
                    }}
                  />
                )}
                {map(
                  content[blocksLayoutFieldname].items.filter(
                    (block) =>
                      content[blocksFieldname][block]['@type'] !== 'title',
                  ),
                  (block) => {
                    const Block =
                      config.blocks.blocksConfig[
                        content[blocksFieldname]?.[block]?.['@type']
                      ]?.['view'] || null;
                    return Block !== null ? (
                      <Block
                        key={block}
                        id={block}
                        properties={content}
                        data={content[blocksFieldname][block]}
                        path={getBaseUrl(location?.pathname || '')}
                      />
                    ) : (
                      <div key={block}>
                        {intl.formatMessage(messages.unknownBlock, {
                          block: content[blocksFieldname]?.[block]?.['@type'],
                        })}
                      </div>
                    );
                  },
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              width="12"
              style={{ alignItems: 'center', overflow: 'hidden' }}
            >
              <div className="sides-container">
                <div className="left-side">
                  {['accepted', 'selected_final'].includes(workflowState) ? (
                    <div className="enrolment-badge">
                      <svg
                        width="70"
                        height="70"
                        viewBox="0 0 70 70"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M67.0832 35.0005L59.9665 26.8922L60.9582 16.1589L50.429 13.7672L44.9165 4.49219L34.9998 8.75052L25.0832 4.49219L19.5707 13.7672L9.0415 16.1297L10.0332 26.863L2.9165 35.0005L10.0332 43.1089L9.0415 53.8714L19.5707 56.263L25.0832 65.538L34.9998 61.2505L44.9165 65.5089L50.429 56.2339L60.9582 53.8422L59.9665 43.1089L67.0832 35.0005ZM29.1665 49.5839L17.4998 37.9172L21.6123 33.8047L29.1665 41.3297L48.3873 22.1089L52.4998 26.2505L29.1665 49.5839Z"
                          fill="#C29A5B"
                        />
                      </svg>{' '}
                      <p>
                        {!sisuEnrolmentCompleted ? (
                          <FormattedMessage
                            id="SisuApplicationEnrolmentInProgress"
                            defaultMessage="Your studyrights are being processed"
                          />
                        ) : (
                          <FormattedMessage
                            id="module_view_accepted"
                            defaultMessage="You are in the module"
                          />
                        )}
                      </p>
                    </div>
                  ) : (
                    <div className="info">
                      <div className="price">
                        <p>{content.price} €</p>
                      </div>

                      <div className="registration">
                        <div className="Icon calendar-end"></div>

                        <div className="registration-info-container">
                          <p className="reigstration-info">
                            {intl.formatMessage(
                              messages.moduleFinalApplicationDate,
                            )}{' '}
                          </p>
                          <p>
                            {moment(content.application_end_date).format(
                              'DD.MM.YYYY',
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="right-side">
                  {workflowState === '' ? (
                    <div className="module-application-button-container">
                      <Button
                        className="ui button"
                        disabled={!applicationOpen}
                        onClick={async () => {
                          setLoading(true);
                          await startEpic(EPIC_MODULE_APPLICATION, {
                            contentUid: content.UID,
                          });
                        }}
                      >
                        <FormattedMessage
                          id="moduleApplicationButtonApplyText"
                          defaultMessage="Apply"
                        />
                      </Button>
                    </div>
                  ) : (
                    <>
                      {['draft', 'finished'].includes(workflowState) && (
                        <div className="module-application-button-container">
                          <Button
                            className="ui button"
                            onClick={async () => {
                              setLoading(true);
                              history.push(flattenToAppURL(applicationPath));
                            }}
                          >
                            <FormattedMessage
                              id="moduleApplicationButtonEditText"
                              defaultMessage="Edit your application"
                            />
                          </Button>
                        </div>
                      )}
                      {![
                        'draft',
                        'finished',
                        'accepted',
                        'selected_final',
                      ].includes(workflowState) && (
                        <div className="module-application-button-container">
                          <Button
                            className="ui button"
                            onClick={async () => {
                              setLoading(true);
                              history.push(flattenToAppURL(applicationPath));
                            }}
                          >
                            <FormattedMessage
                              id="moduleApplicationButtonViewText"
                              defaultMessage="See your application"
                            />
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Grid.Column>
            <Grid.Column
              width="6"
              style={{ alignItems: 'center', overflow: 'hidden' }}
            ></Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <div
        style={{
          backgroundColor: '#F6F7F7',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '1.2rem',
          marginTop: 'auto',
        }}
      >
        <div className="ui container information-footer">
          {workflowState === 'accepted' && (
            <div className="row enrolment-instructions">
              <div className="image-field"></div>
              <div className="text-field">
                <h2>
                  {intl.formatMessage(
                    messages.moduleEnrolmentInstructionsTitle,
                  )}
                </h2>
                <ol>
                  <li>
                    {intl.formatMessage(
                      messages.moduleEnrolmentInstructionsListPart1,
                    )}
                  </li>
                  <li>
                    {intl.formatMessage(
                      messages.moduleEnrolmentInstructionsListPart2,
                    )}
                  </li>
                  <li>
                    {intl.formatMessage(
                      messages.moduleEnrolmentInstructionsListPart3,
                    )}
                  </li>
                  <li>
                    {intl.formatMessage(
                      messages.moduleEnrolmentInstructionsListPart4,
                    )}
                  </li>
                  <li>
                    {intl.formatMessage(
                      messages.moduleEnrolmentInstructionsListPart5,
                    )}
                  </li>
                </ol>
              </div>
            </div>
          )}
          {!token && (
            <div className="row registration-instructions">
              <div className="image-field"></div>
              <div className="text-field">
                <h2>{intl.formatMessage(messages.registration)}</h2>
                <p>
                  {intl.formatMessage(messages.registrationDescriptionPart1)}
                </p>
                <p>
                  {intl.formatMessage(messages.registrationDescriptionPart2)}
                </p>
              </div>
            </div>
          )}
          {!workflowState && (
            <div className="row application-instructions">
              <div className="image-field"></div>
              <div className="text-field">
                <h2>
                  {intl.formatMessage(
                    messages.moduleApplicationInstructionsTitle,
                  )}
                </h2>
                <p>
                  {intl.formatMessage(
                    messages.moduleApplicationInstructionsDescription,
                  )}
                </p>
                <ol>
                  <li>
                    {intl.formatMessage(
                      messages.moduleApplicationInstructionsListPart1,
                    )}
                  </li>
                  <li>
                    {intl.formatMessage(
                      messages.moduleApplicationInstructionsListPart2,
                    )}
                  </li>
                  <li>
                    {intl.formatMessage(
                      messages.moduleApplicationInstructionsListPart3,
                    )}
                  </li>
                  <li>
                    {intl.formatMessage(
                      messages.moduleApplicationInstructionsListPart4,
                    )}
                  </li>
                  <li>
                    {intl.formatMessage(
                      messages.moduleApplicationInstructionsListPart5,
                    )}
                  </li>
                </ol>
                <p>
                  {intl.formatMessage(
                    messages.moduleApplicationInstructionsFinal,
                  )}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
DefaultView.propTypes = {
  /**
   * Content of the object
   */
  content: PropTypes.shape({
    /**
     * Title of the object
     */
    title: PropTypes.string,
    /**
     * Description of the object
     */
    description: PropTypes.string,
    /**
     * Text of the object
     */
    text: PropTypes.shape({
      /**
       * Data of the text of the object
       */
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default DefaultView;
