/*
 * Customizes InlineForm so that the fieldsets are not hidden.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Segment, Message } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import AnimateHeight from 'react-animate-height';
import { keys, map, isEqual } from 'lodash';

import { Field } from '@plone/volto/components';
import { applySchemaDefaults } from '@plone/volto/helpers';

const messages = defineMessages({
  editValues: {
    id: 'Edit values',
    defaultMessage: 'Edit values',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  thereWereSomeErrors: {
    id: 'There were some errors',
    defaultMessage: 'There were some errors',
  },
});

const CustomApplicationForm = (props) => {
  const {
    readOnly,
    block,
    description,
    error, // Such as {message: "It's not good"}
    errors = {},
    formData,
    onChangeFormData,
    onChangeField,
    schema,
    title,
    icon,
    headerActions,
    footer,
    focusIndex,
    intl,
  } = props;
  const _ = intl.formatMessage;
  const defaultFieldset = schema.fieldsets.find((o) => o.id === 'default');
  const other = schema.fieldsets.filter((o) => o.id !== 'default');

  React.useEffect(() => {
    // Will set field values from schema, by matching the default values

    const objectSchema = typeof schema === 'function' ? schema(props) : schema;

    const initialData = applySchemaDefaults({
      data: formData,
      schema: objectSchema,
      intl,
    });

    if (onChangeFormData) {
      onChangeFormData(initialData);
    } else {
      Object.keys(initialData).forEach((k) => {
        if (!isEqual(initialData[k], formData?.[k])) {
          onChangeField(k, initialData[k]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ui form">
      {title && (
        <header className="header pulled">
          {icon}
          <h2>{title || _(messages.editValues)}</h2>
          {headerActions}
        </header>
      )}
      {description && (
        <Segment secondary className="attached">
          {description}
        </Segment>
      )}
      {keys(errors).length > 0 && (
        <Message
          icon="warning"
          negative
          attached
          header={_(messages.error)}
          content={_(messages.thereWereSomeErrors)}
        />
      )}
      {error && (
        <Message
          icon="warning"
          negative
          attached
          header={_(messages.error)}
          content={error.message}
        />
      )}

      {!!defaultFieldset && (
        <div id={`blockform-fieldset-${defaultFieldset.id}`}>
          {!!defaultFieldset.description && (
            <p className="help">
              {defaultFieldset.description && (
                <>{defaultFieldset.description}</>
              )}
            </p>
          )}
          <Segment className="form attached">
            {map(defaultFieldset.fields, (field, index) => (
              <Field
                {...schema.properties[field]}
                isDisabled={!!readOnly}
                id={field}
                fieldSet={defaultFieldset.title.toLowerCase()}
                focus={index === focusIndex}
                value={formData[field]}
                required={schema.required.indexOf(field) !== -1}
                onChange={(id, value) => {
                  onChangeField(id, value);
                }}
                key={field}
                error={errors[field]}
                block={block}
              />
            ))}
          </Segment>
        </div>
      )}

      {other.map((fieldset, index) => (
        <Accordion fluid styled className="form" key={fieldset.id}>
          <div key={fieldset.id} id={`blockform-fieldset-${fieldset.id}`}>
            <Accordion.Title active={true} index={index}>
              {fieldset.title && <>{fieldset.title}</>}
            </Accordion.Title>
            <Accordion.Content active={true}>
              <AnimateHeight animateOpacity duration={500} height={'auto'}>
                {!!fieldset.description && (
                  <p className="help">
                    {fieldset.description && <>{fieldset.description}</>}
                  </p>
                )}
                <Segment className="attached">
                  {map(fieldset.fields, (field) => (
                    <Field
                      {...schema.properties[field]}
                      isDisabled={!!readOnly}
                      id={field}
                      value={formData[field]}
                      required={schema.required.indexOf(field) !== -1}
                      onChange={(id, value) => {
                        onChangeField(id, value);
                      }}
                      key={field}
                      error={errors[field]}
                      block={block}
                    />
                  ))}
                </Segment>
              </AnimateHeight>
            </Accordion.Content>
          </div>
        </Accordion>
      ))}
      {footer}
    </div>
  );
};

CustomApplicationForm.defaultProps = {
  block: null,
  description: null,
  formData: null,
  onChangeField: null,
  error: null,
  errors: {},
  schema: {},
  focusIndex: null,
};

CustomApplicationForm.propTypes = {
  block: PropTypes.string,
  description: PropTypes.string,
  schema: PropTypes.shape({
    fieldsets: PropTypes.arrayOf(
      PropTypes.shape({
        fields: PropTypes.arrayOf(PropTypes.string),
        id: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
    properties: PropTypes.objectOf(PropTypes.any),
    definitions: PropTypes.objectOf(PropTypes.any),
    required: PropTypes.arrayOf(PropTypes.string),
  }),
  formData: PropTypes.objectOf(PropTypes.any),
  pathname: PropTypes.string,
  onChangeField: PropTypes.func,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  focusIndex: PropTypes.number,
};

export default injectIntl(CustomApplicationForm, { forwardRef: true });
