import React, { useState, useEffect } from 'react';
import BlockDataForm from '@plone/volto/components/manage/Form/BlockDataForm';
import { connect } from 'react-redux';
import { Button, Container } from 'semantic-ui-react';
import { createContent, getSchema } from '@plone/volto/actions';
import { withRouter } from 'react-router';

const ApplicationAddForm = (props) => {
  const [applicationData, setApplicationData] = useState(null);
  const [applicationSchema, setApplicationSchema] = useState(null);

  useEffect(() => {
    props.getSchema('Application');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.schema) {
      const applicationDataJson =
        props.schema.properties?.application_data?.default;
      const applicationSchemaJson =
        props.schema.properties?.application_schema?.default;
      if (applicationDataJson) {
        setApplicationData(JSON.parse(applicationDataJson));
      }
      if (applicationSchemaJson) {
        setApplicationSchema(JSON.parse(applicationSchemaJson));
      }
    }
  }, [props.schema]);

  const onChange = (id, value) => {
    const newApplicationData = {
      ...applicationData,
      [id]: value,
    };
    setApplicationData(newApplicationData);
  };

  const onSubmit = () => {
    const newApplicationDataJson = JSON.stringify(applicationData);
    const parentUrl = props.location.pathname.replace('/add', '');
    props
      .createContent(parentUrl, {
        '@type': 'Application',
        title: 'Application',
        application_data: newApplicationDataJson,
      })
      .then((newContent) => {
        if (newContent?.id) {
          const contentUrl = [parentUrl, newContent.id].join('/');
          props.history.push(contentUrl);
        } else {
          props.history.push(parentUrl);
        }
      });
  };

  return (
    applicationSchema != null &&
    applicationData != null && (
      <Container id="application-add-view">
        <BlockDataForm
          schema={applicationSchema}
          onChangeField={onChange}
          onChangeBlock={() => {}}
          formData={applicationData}
        />
        <div className="buttons-container">
          <Button onClick={onSubmit}>Submit</Button>
        </div>
      </Container>
    )
  );
};

const mapStateToProps = (state) => ({
  schema: state.schema.schema,
});

const mapDispatchToProps = (dispatch) => ({
  createContent: (url, data) => dispatch(createContent(url, data)),
  getSchema: (contentType) => dispatch(getSchema(contentType)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApplicationAddForm),
);
