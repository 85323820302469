import React from 'react';
import { Button } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import { FormattedMessage } from 'react-intl';
import { VoltoCircleLeftIcon, VoltoCircleRightIcon } from '@package/icons';

const StepsNavigation = ({ nextStep, onNextStep, prevStep, onPrevStep }) => {
  return (
    <div className="reg-navigation">
      {onPrevStep && (
        <Button
          basic
          className="prev-arrow"
          onClick={onPrevStep}
          disabled={prevStep !== undefined && !prevStep}
        >
          <Icon name={VoltoCircleLeftIcon} />
          <p>
            <FormattedMessage id="Back" defaultMessage="Back" />
          </p>
        </Button>
      )}
      {onNextStep && (
        <Button
          basic
          className="forward-arrow"
          onClick={onNextStep}
          disabled={nextStep !== undefined && !nextStep}
        >
          <Icon name={VoltoCircleRightIcon} />
          <p>
            <FormattedMessage id="Continue" defaultMessage="Continue" />
          </p>
        </Button>
      )}
    </div>
  );
};

export default StepsNavigation;
