/**
 * View body caption block.
 * @module components/manage/Blocks/BodyCaption/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Header, Image } from 'semantic-ui-react';
import config from '@plone/volto/registry';
import { Icon } from '@plone/volto/components';
import circleRightSVG from '@plone/volto/icons/circle-right.svg';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import bgCurvesSVG from './bg_caption_curves.svg';

const View = ({ data }) => {
  const apiPath = `${config.settings.apiPath}`;

  return (
    <div
      style={{
        backgroundImage: `url(${
          data.url && isInternalURL(data.url)
            ? flattenToAppURL(data.url) + '/@@images/image'
            : data.url
        })`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        height: `${data.contentHeight}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '3em',
      }}
      className="full-width"
      alt={data.alt || ''}
    >
      <Image
        alt="curves"
        aria-hidden="true"
        src={bgCurvesSVG}
        style={{
          width: '100%',
          position: 'absolute',
          bottom: '-45px',
        }}
      />
      <div style={{ width: '80%', zIndex: '1' }}>
        <div style={{ textAlign: `${data.alignContent}` }}>
          <Header
            as="h1"
            style={{
              fontFamily: 'Aleo',
              display: 'block',
              fontSize: `${data.fontSize}em`,
              fontWeight: '700',
              color: `${data.headerColor}`,
              wordBreak: 'break-word',
              marginBottom: '0.4em',
            }}
            className="body-caption-header"
          >
            {data.header}
            <Header.Subheader
              style={{
                fontSize: `${data.fontSize / 2}rem`,
                display: 'block',
                color: `${data.subHeaderColor}`,
                padding: '0.8em 0',
              }}
            >
              {data.subHeader}
            </Header.Subheader>
          </Header>
          <div
            style={{
              display: 'inline-block',
              fontSize: `${data.fontSize / 2.5}rem`,
              color: `${data.subHeaderColor}`,
            }}
          >
            {(() => {
              if (data.linkCaption && data.href) {
                if (
                  (data.href.startsWith('http') ||
                    data.href.startsWith('https')) &&
                  !data.href.includes(apiPath)
                ) {
                  return (
                    <div>
                      <a
                        className="bodyHeadingLink"
                        target={data.openLinkInNewTab ? '_blank' : null}
                        href={data.href}
                        style={{
                          color: `${data.linkColor}`,
                          display: 'flex',
                          alignItems: 'center',
                          textTransform: 'uppercase',
                          padding: '0.5em 1em',
                          fontSize: '1.3rem',
                          borderRadius: '10px',
                          border: `3px solid ${data.linkColor}`,
                        }}
                      >
                        {data.linkCaption}
                        <div
                          style={{
                            marginLeft: '15px',
                          }}
                        >
                          <Icon name={circleRightSVG} color={data.linkColor} />
                        </div>
                      </a>
                    </div>
                  );
                } else {
                  return (
                    <div>
                      <Link
                        className="bodyHeadingLink"
                        to={data.href.replace(apiPath, '')}
                        target={data.openLinkInNewTab ? '_blank' : null}
                        style={{
                          color: `${data.linkColor}`,
                          display: 'flex',
                          alignItems: 'center',
                          textTransform: 'uppercase',
                          padding: '0.5em 1em',
                          fontSize: '1.3rem',
                          borderRadius: '10px',
                          border: `3px solid ${data.linkColor}`,
                        }}
                      >
                        {data.linkCaption}
                        <div
                          style={{
                            marginLeft: '15px',
                          }}
                        >
                          <Icon name={circleRightSVG} color={data.linkColor} />
                        </div>
                      </Link>
                    </div>
                  );
                }
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
