import sisuWidgetConstructor from './manage/Widgets/SisuWidget';

import {
  sisuGetCourseUnitIdQuery,
  sisuSearchCourseUnitIdQuery,
  sisuGetCourseUnitRealisationIdQuery,
  sisuSearchCourseUnitRealisationIdQuery,
  sisuGetAssessmentItemIdQuery,
  sisuSearchAssessmentItemIdQuery,
  sisuGetCompletionMethodIdQuery,
  sisuSearchCompletionMethodIdQuery,
  sisuGetEducationIdQuery,
  sisuSearchEducationIdQuery,
  sisuGetModuleIdQuery,
  sisuSearchModuleIdQuery,
  sisuGetStudySubgroupIdQuery,
  sisuSearchStudySubgroupIdQuery,
} from '@package/helpers/sisu';

import RadioButtonWidget from './manage/Widgets/RadioButtonWidget';

import CustomApplicationModalForm from './manage/Form/CustomApplicationModalForm';

import ApplicationSchemaWidget from './manage/Widgets/ApplicationSchemaWidget';

const SisuCourseUnitWidget = sisuWidgetConstructor(
  sisuGetCourseUnitIdQuery,
  sisuSearchCourseUnitIdQuery,
  {},
  false,
);
export { SisuCourseUnitWidget };

const SisuCourseUnitRealisationWidget = sisuWidgetConstructor(
  sisuGetCourseUnitRealisationIdQuery,
  sisuSearchCourseUnitRealisationIdQuery,
  { sisu_course_unit_id: 'ILPOCourseUnit' },
  false,
);
export { SisuCourseUnitRealisationWidget };

const SisuAssessmentItemWidget = sisuWidgetConstructor(
  sisuGetAssessmentItemIdQuery,
  sisuSearchAssessmentItemIdQuery,
  { sisu_course_unit_realisation_id: 'ILPOCourseUnitRealisation' },
  false,
);
export { SisuAssessmentItemWidget };

const SisuCompletionMethodWidget = sisuWidgetConstructor(
  sisuGetCompletionMethodIdQuery,
  sisuSearchCompletionMethodIdQuery,
  {
    sisu_course_unit_id: 'ILPOCourseUnit',
    sisu_assessment_item_id: 'ILPOAssessmentItem',
  },
  false,
);
export { SisuCompletionMethodWidget };

const SisuEducationWidget = sisuWidgetConstructor(
  sisuGetEducationIdQuery,
  sisuSearchEducationIdQuery,
  {},
  false,
);
export { SisuEducationWidget };

const SisuStudySubgroupWidget = sisuWidgetConstructor(
  sisuGetStudySubgroupIdQuery,
  sisuSearchStudySubgroupIdQuery,
  { sisu_course_unit_realisation_id: 'ILPOCourseUnitRealisation' },
  true,
);
export { SisuStudySubgroupWidget };

const SisuModuleWidget = sisuWidgetConstructor(
  sisuGetModuleIdQuery,
  sisuSearchModuleIdQuery,
  {},
  false,
);
export { SisuModuleWidget };
export { RadioButtonWidget };
export { CustomApplicationModalForm };
export { ApplicationSchemaWidget };
