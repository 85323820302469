/**
 * Module view component.
 * @module components/theme/View/ApplicationView
 */

import React, { useState, useEffect } from 'react';
import { concat, filter, map, last } from 'lodash';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';
import { getWorkflow, transitionWorkflow } from '@plone/volto/actions';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { startEpic } from 'volto-epics-addon/helpers';
import { EPIC_MODULE_REGISTRATION } from '@package/constants';
import { flattenToAppURL } from '@plone/volto/helpers';
import { defineMessages, useIntl } from 'react-intl';
import { Api } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

const messages = defineMessages({
  applicationViewDraftInfo: {
    id: 'applicationViewDraftInfo',
    defaultMessage:
      'Please edit your application draft and finish it before the deadline.',
  },
  applicationViewDraftSubtitle: {
    id: 'applicationViewDraftSubtitle',
    defaultMessage: 'Your application is still a draft!',
  },
  applicationViewFinishedInfo: {
    id: 'applicationViewFinishedInfo',
    defaultMessage:
      'Thank you for your application. Your application is now ready and waiting for the deadline.',
  },
  applicationViewFinishedEditInfo: {
    id: 'applicationViewFinishedEditInfo',
    defaultMessage:
      'You may still edit and save changes to your application until the deadline.',
  },
  applicationViewFinishedSubtitle: {
    id: 'applicationViewFinishedSubtitle',
    defaultMessage: 'Your application has been saved!',
  },
  applicationViewSentInfo: {
    id: 'applicationViewSentInfo',
    defaultMessage:
      'Your application has been sent and is waiting for the decision.',
  },
  applicationViewSentSelectionInfo: {
    id: 'applicationViewFinishedSelectionInfo',
    defaultMessage:
      'You will be contacted via email after the decision has been made.',
  },
  applicationViewSentSubtitle: {
    id: 'applicationViewSentSubtitle',
    defaultMessage: 'Your application has been sent!',
  },
  applicationViewExpiredInfo: {
    id: 'applicationViewExpiredInfo',
    defaultMessage:
      'Unfortunately, your application was not finished in time before the deadline.',
  },
  applicationViewExpiredSubtitle: {
    id: 'applicationViewExpiredSubtitle',
    defaultMessage: 'Your application has expired.',
  },
  applicationViewSelectedInfo: {
    id: 'applicationViewSelectedInfo',
    defaultMessage: 'You still need to accept or decline.',
  },
  applicationViewSelectedSubtitle: {
    id: 'applicationViewSelectedSubtitle',
    defaultMessage: 'You have been selected to the Module!',
  },
  applicationViewNotSelectedInfo: {
    id: 'applicationViewNotSelectedInfo',
    defaultMessage: 'You will be informed of the decision via email.',
  },
  applicationViewNotSelectedRejectionReason: {
    id: 'applicationViewNotSelectedRejectionReason',
    defaultMessage: 'Rejection reason',
  },
  applicationViewNotSelectedSubtitle: {
    id: 'applicationViewNotSelectedSubtitle',
    defaultMessage: 'Unfortunately, you have not been selected to the Module.',
  },
  applicationViewAcceptedInfo: {
    id: 'applicationViewAcceptedInfo',
    defaultMessage: 'By clicking the button below, you can see details.',
  },
  applicationViewAcceptedSubtitle: {
    id: 'applicationViewAcceptedSubtitle',
    defaultMessage: 'You are now in the Module!',
  },
  applicationViewNotAcceptedInfo: {
    id: 'applicationViewNotAcceptedInfo',
    defaultMessage: 'You were selected to the Module but did not accept.',
  },
  applicationViewNotAcceptedSubtitle: {
    id: 'applicationViewNotAcceptedSubtitle',
    defaultMessage: 'You are not in the Module.',
  },
  applicationViewOnWaitlistInfo: {
    id: 'applicationViewOnWaitlistInfo',
    defaultMessage: 'You are on a waitlist and will be informed if selected.',
  },
  applicationViewOnWaitlistSubtitle: {
    id: 'applicationViewOnWaitlistSubtitle',
    defaultMessage: 'You are on a waitlist!',
  },
  acceptAndProceed: {
    id: 'acceptAndProceed',
    defaultMessage: 'I accept and will proceed to pay',
  },
  decline: {
    id: 'decline',
    defaultMessage: 'I decline',
  },
  goToTheModule: {
    id: 'goToTheModule',
    defaultMessage: 'Go to the module',
  },
  editApplicationDraft: {
    id: 'editApplicationDraft',
    defaultMessage: 'Edit application draft',
  },
  editApplication: {
    id: 'editApplication',
    defaultMessage: 'Edit application',
  },
  viewApplication: {
    id: 'viewApplication',
    defaultMessage: 'View application',
  },
  backToDashboard: {
    id: 'backToDashboard',
    defaultMessage: 'Back to dashboard',
  },
  additionalInfoUrlText: {
    id: 'additionalInfoUrlText',
    defaultMessage: 'You may click here for more information about the Module.',
  },
});

const ApplicationView = (props) => {
  const { token } = useSelector((state) => state.userSession);
  const [title, setTitle] = useState('');
  const [workflowState, setWorkflowState] = useState('');
  const [additionalInfoUrl, setAdditionalInfoUrl] = useState('');

  const intl = useIntl();

  useEffect(() => {
    // Ensure up to date workflow information
    props.getWorkflow(props.location.pathname);

    // Get url for additional information
    (async () => {
      const path = props.content.module['@id'].replace(
        config.settings.publicURL,
        '',
      );
      const api = new Api();

      const response = await api.get('/@search', {
        params: {
          portal_type: 'Module',
          fullobjects: 1,
          path,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      try {
        const url = response.items[0]?.additional_info_url;
        if (url) {
          setAdditionalInfoUrl(url);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    })();

    // Set title
    if (props?.content?.module?.title) {
      setTitle(props.content.module.title);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Set workflow state.
    if (props?.workflow?.currentState?.id) {
      setWorkflowState(props.workflow.currentState.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.workflow]);

  const onClick = () => {
    const dashboardPath = `/${props.lang}`;
    props.history.push(dashboardPath);
  };

  const onClickEdit = () => {
    props.history.push(`${props.location.pathname}/edit`);
  };

  const onSelectedOnAccept = async () => {
    if (props?.content) {
      await startEpic(EPIC_MODULE_REGISTRATION, {
        contentUid: props.content.UID,
      });
    }
  };

  const onSelectedOnNotAccept = () => {
    // Transition to not accepted state.
    const transitionUrl = filter(
      map(concat(props.workflow.transitions), (item) => item['@id']),
      (x) => last(x.split('/')) === 'selected_to_not_accepted',
    );
    props.transitionWorkflow(transitionUrl, false).then(() => {
      window.location.reload();
    });
  };

  const onAcceptedToModule = () => {
    // Travel to the module!
    if (props?.content?.module) {
      const modulePath = flattenToAppURL(props.content.module['@id']);
      props.history.push(modulePath);
    }
  };

  const getContents = () => {
    var subtitle = '';
    var info = [''];
    if (workflowState === 'draft') {
      subtitle = intl.formatMessage(messages.applicationViewDraftSubtitle);
      info = [intl.formatMessage(messages.applicationViewDraftInfo)];
    } else if (workflowState === 'finished') {
      subtitle = intl.formatMessage(messages.applicationViewFinishedSubtitle);
      info = [
        intl.formatMessage(messages.applicationViewFinishedInfo),
        intl.formatMessage(messages.applicationViewFinishedEditInfo),
        intl.formatMessage(messages.applicationViewSentSelectionInfo),
      ];
    } else if (workflowState === 'sent') {
      subtitle = intl.formatMessage(messages.applicationViewSentSubtitle);
      info = [
        intl.formatMessage(messages.applicationViewSentInfo),
        intl.formatMessage(messages.applicationViewSentSelectionInfo),
      ];
    } else if (workflowState === 'expired') {
      subtitle = intl.formatMessage(messages.applicationViewExpiredSubtitle);
      info = [intl.formatMessage(messages.applicationViewExpiredInfo)];
    } else if (workflowState === 'selected_final') {
      subtitle = intl.formatMessage(messages.applicationViewSelectedSubtitle);
    } else if (workflowState === 'selected') {
      subtitle = intl.formatMessage(messages.applicationViewSelectedSubtitle);
      info = [intl.formatMessage(messages.applicationViewSelectedInfo)];
    } else if (workflowState === 'not_selected') {
      subtitle = intl.formatMessage(
        messages.applicationViewNotSelectedSubtitle,
      );
      info = [intl.formatMessage(messages.applicationViewNotSelectedInfo)];
    } else if (workflowState === 'accepted') {
      subtitle = intl.formatMessage(messages.applicationViewAcceptedSubtitle);
      info = [intl.formatMessage(messages.applicationViewAcceptedInfo)];
    } else if (workflowState === 'not_accepted') {
      subtitle = intl.formatMessage(
        messages.applicationViewNotAcceptedSubtitle,
      );
      info = [intl.formatMessage(messages.applicationViewNotAcceptedInfo)];
    } else if (workflowState === 'on_waitlist') {
      subtitle = intl.formatMessage(messages.applicationViewOnWaitlistSubtitle);
      info = [intl.formatMessage(messages.applicationViewOnWaitlistInfo)];
    } else {
      info = [''];
      subtitle = '';
    }
    if (workflowState === 'selected_final') {
      return (
        <div className="state-{workflowState}">
          <h3 className="subtitle"> {subtitle} </h3>
          <div className="info-container">
            {info.map((p) => (
              <p>{p}</p>
            ))}
            {additionalInfoUrl && (
              <div>
                <a
                  href={additionalInfoUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage(messages.additionalInfoUrlText)}
                </a>
              </div>
            )}
          </div>
        </div>
      );
    } else if (workflowState === 'selected') {
      return (
        <div className="state-selected">
          <h3 className="subtitle"> {subtitle} </h3>
          <div className="info-container">
            {info.map((p) => (
              <p>{p}</p>
            ))}
            {additionalInfoUrl && (
              <div>
                <a
                  href={additionalInfoUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage(messages.additionalInfoUrlText)}
                </a>
              </div>
            )}
          </div>
          <div className="state-buttons">
            <Button
              onClick={onSelectedOnAccept}
              role="button"
              aria-label="Accept"
              type="button"
            >
              {intl.formatMessage(messages.acceptAndProceed)}
            </Button>
            <Button
              onClick={onSelectedOnNotAccept}
              role="button"
              aria-label="Decline"
              type="button"
            >
              {intl.formatMessage(messages.decline)}
            </Button>
          </div>
        </div>
      );
    } else if (workflowState === 'accepted') {
      return (
        <div className="state-accepted">
          <h3 className="subtitle"> {subtitle} </h3>
          <div className="info-container">
            {info.map((p) => (
              <p>{p}</p>
            ))}
          </div>
          <div className="state-buttons">
            <Button
              onClick={onAcceptedToModule}
              role="button"
              aria-label="Decline"
              type="button"
            >
              {intl.formatMessage(messages.goToTheModule)}
            </Button>
          </div>
        </div>
      );
    } else if (workflowState === 'not_selected') {
      return (
        <div className="state-{workflowState}">
          <h3 className="subtitle"> {subtitle} </h3>
          <div className="info-container">
            {props.content.rejection_reason && (
              <p>
                <strong>
                  {intl.formatMessage(
                    messages.applicationViewNotSelectedRejectionReason,
                  )}
                  :
                </strong>{' '}
                {props.content.rejection_reason}
              </p>
            )}
            {info.map((p) => (
              <p>{p}</p>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div className="state-{workflowState}">
          <h3 className="subtitle"> {subtitle} </h3>
          <div className="info-container">
            {info.map((p) => (
              <p>{p}</p>
            ))}
          </div>
        </div>
      );
    }
  };

  return (
    <Container id="application-view">
      <h2>{title}</h2>
      <div className="main-container">{getContents()}</div>
      <div className="buttons-container">
        {workflowState === 'draft' && (
          <Button
            onClick={onClickEdit}
            role="button"
            aria-label="Edit"
            type="button"
          >
            {intl.formatMessage(messages.editApplicationDraft)}
          </Button>
        )}
        {workflowState === 'finished' && (
          <Button
            onClick={onClickEdit}
            role="button"
            aria-label="Edit"
            type="button"
          >
            {intl.formatMessage(messages.editApplication)}
          </Button>
        )}
        {!['draft', 'finished'].includes(workflowState) && (
          <Button
            onClick={onClickEdit}
            role="button"
            aria-label="Edit"
            type="button"
          >
            {intl.formatMessage(messages.viewApplication)}
          </Button>
        )}
        <Button
          onClick={onClick}
          role="button"
          aria-label="Decline"
          type="button"
        >
          {intl.formatMessage(messages.backToDashboard)}
        </Button>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  workflow: state.workflow,
  lang: state.intl.locale,
});

const mapDispatchToProps = (dispatch) => ({
  getWorkflow: (url) => dispatch(getWorkflow(url)),
  transitionWorkflow: (url, include_children) =>
    dispatch(transitionWorkflow(url, include_children)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApplicationView),
);
