import React, { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import {
  CheckboxWidget,
  Icon,
  TextareaWidget,
  TextWidget,
} from '@plone/volto/components';
import {
  Button,
  Dimmer,
  Form,
  Grid,
  Input,
  Loader,
  Message,
  Segment,
  Accordion,
} from 'semantic-ui-react';
import imageLeftSVG from '@plone/volto/icons/image-left.svg';
import imageRightSVG from '@plone/volto/icons/image-right.svg';
import imageFitSVG from '@plone/volto/icons/image-fit.svg';
import { readAsDataURL } from 'promise-file-reader';
import imageSVG from '@plone/volto/icons/image.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';
import uploadSVG from '@plone/volto/icons/upload.svg';
import aheadSVG from '@plone/volto/icons/ahead.svg';
import upSVG from '@plone/volto/icons/up-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';

import {
  flattenToAppURL,
  getBaseUrl,
  isInternalURL,
} from '@plone/volto/helpers';
import { SketchPicker } from 'react-color';

const messages = defineMessages({
  Image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  Origin: {
    id: 'Origin',
    defaultMessage: 'Origin',
  },
  AltText: {
    id: 'Alt text',
    defaultMessage: 'Alt text',
  },
  Align: {
    id: 'Alignment',
    defaultMessage: 'Alignment',
  },
  LinkTo: {
    id: 'Link to',
    defaultMessage: 'Link to',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Open in a new tab',
  },
  NoImageSelected: {
    id: 'No image selected',
    defaultMessage: 'No image selected',
  },
  externalURL: {
    id: 'External URL',
    defaultMessage: 'External URL',
  },
  size: {
    id: 'Size',
    defaultMessage: 'Size',
  },
  ImageBlockInputPlaceholder: {
    id: 'Browse the site, drop an image, or type an URL',
    defaultMessage: 'Browse the site, drop an image, or type an URL',
  },
  header: {
    id: 'Header',
    defaultMessage: 'Header',
  },
  subHeader: {
    id: 'Subheader',
    defaultMessage: 'Subheader',
  },
  left: {
    id: 'Left',
    defaultMessage: 'Left',
  },
  right: {
    id: 'Right',
    defaultMessage: 'Right',
  },
  center: {
    id: 'Center',
    defaultMessage: 'Center',
  },
  fontSize: {
    id: 'Font Size',
    defaultMessage: 'Font Size',
  },
  small: {
    id: 'Left',
    defaultMessage: 'Left',
  },
  medium: {
    id: 'Right',
    defaultMessage: 'Right',
  },
  large: {
    id: 'Center',
    defaultMessage: 'Center',
  },
  linkCaption: {
    id: 'Link Caption',
    defaultMessage: 'Link Caption',
  },
  contentHeight: {
    id: 'Content Height',
    defaultMessage: 'Content Height',
  },
});

const BodyCaptionSidebar = ({
  data,
  block,
  onChangeBlock,
  createContent,
  openObjectBrowser,
  intl,
  pathname,
}) => {
  const [url, setUrl] = useState('');
  const [uploading, setUploading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [contentHeight, setContentHeight] = useState(10);
  const [activeAccIndex, setActiveAccIndex] = useState(1);

  function handleAccClick(e, titleProps) {
    const { index } = titleProps;
    const newIndex = activeAccIndex === index ? -1 : index;

    setActiveAccIndex(newIndex);
  }

  const onUploadImage = ({ target }) => {
    const file = target.files[0];
    setUploading(true);

    readAsDataURL(file).then((data) => {
      const fields = data.match(/^data:(.*);(.*),(.*)$/);
      createContent(
        getBaseUrl(pathname),
        {
          '@type': 'Image',
          title: file.name,
          image: {
            data: fields[3],
            encoding: fields[2],
            'content-type': fields[1],
            filename: file.name,
          },
        },
        block,
      );
    });
  };

  const onChangeUrl = ({ target }) => {
    setUrl(target.value);
  };

  const onKeyDownVariantMenuForm = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.onSubmitUrl();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      // TODO: Do something on ESC key
    }
  };

  const onSubmitUrl = () => {
    onChangeBlock(block, {
      ...data,
      url: url,
    });
  };

  const placeholder =
    data.placeholder || intl.formatMessage(messages.ImageBlockInputPlaceholder);

  return (
    <div className="block">
      <Segment.Group raised>
        <header className="header pulled">
          <h2>
            <FormattedMessage
              id="Background Image"
              defaultMessage="Background Image"
            />
          </h2>
        </header>
        {!data.url && (
          <>
            <Segment className="sidebar-metadata-container" secondary>
              {!uploading ? (
                <Message style={{ margin: '0 auto' }}>
                  <FormattedMessage
                    id="No image selected"
                    defaultMessage="No image selected"
                  />
                  <Icon name={imageSVG} size="100px" color="#b8c6c8" />
                </Message>
              ) : (
                <Message style={{ width: '100%', height: '100px' }}>
                  <Dimmer active>
                    <Loader indeterminate>Uploading image</Loader>
                  </Dimmer>
                </Message>
              )}
            </Segment>
            <Segment>
              <div
                className="toolbar-inner"
                style={{ width: '320px', margin: '0 auto' }}
              >
                <Button.Group>
                  <Button
                    basic
                    icon
                    onClick={(e) => {
                      e.stopPropagation();
                      openObjectBrowser();
                    }}
                  >
                    <Icon name={navTreeSVG} size="24px" />
                  </Button>
                </Button.Group>
                <Button.Group>
                  <label className="ui button basic icon">
                    <Icon name={uploadSVG} size="24px" />
                    <input
                      type="file"
                      onChange={onUploadImage}
                      style={{ display: 'none' }}
                    />
                  </label>
                </Button.Group>
                <Input
                  onKeyDown={onKeyDownVariantMenuForm}
                  onChange={onChangeUrl}
                  placeholder={placeholder}
                  // value={this.state.url}
                  // Prevents propagation to the Dropzone and the opening
                  // of the upload browser dialog
                  onClick={(e) => e.stopPropagation()}
                />
                {url && (
                  <Button.Group>
                    <Button
                      basic
                      className="cancel"
                      onClick={(e) => {
                        e.stopPropagation();
                        setUrl('');
                      }}
                    >
                      <Icon name={clearSVG} size="30px" />
                    </Button>
                  </Button.Group>
                )}
                <Button.Group>
                  <Button
                    basic
                    primary
                    disabled={url === ''}
                    onClick={(e) => {
                      e.stopPropagation();
                      onSubmitUrl();
                    }}
                  >
                    <Icon name={aheadSVG} size="30px" />
                  </Button>
                </Button.Group>
              </div>
            </Segment>
          </>
        )}
        {data.url && (
          <>
            <Message style={{ margin: '0 auto' }}>
              {isInternalURL(data.url) && (
                <img
                  src={`${flattenToAppURL(data.url)}/@@images/image/mini`}
                  alt={data.alt}
                />
              )}
              {!isInternalURL(data.url) && (
                <img src={data.url} alt={data.alt} style={{ width: '50%' }} />
              )}
              {data.url.split('/').slice(-1)[0]}
              <span>Height: {data.contentHeight || 200}px</span>
            </Message>
            <Segment>
              <Form.Field inline id="field-content-height">
                <Grid>
                  <Grid.Row stretched>
                    <Grid.Column width="4">
                      <div className="wrapper">
                        <label htmlFor="select-content-height">
                          {intl.formatMessage(messages.contentHeight)}
                        </label>
                      </div>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input
                        min={200}
                        max={1500}
                        name="height"
                        onChange={(e, { value }) => {
                          setContentHeight(value);
                          onChangeBlock(block, {
                            ...data,
                            contentHeight: value,
                          });
                        }}
                        step={1}
                        type="range"
                        value={data.contentHeight || 200}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
              {isInternalURL(data.url) && (
                <TextWidget
                  id="Origin"
                  title={intl.formatMessage(messages.Origin)}
                  required={false}
                  value={data.url.split('/').slice(-1)[0]}
                  icon={data.url ? clearSVG : navTreeSVG}
                  iconAction={
                    data.url
                      ? () => {
                          setUrl('');
                          onChangeBlock(block, {
                            ...data,
                            url: '',
                          });
                        }
                      : () => openObjectBrowser()
                  }
                  onChange={() => {}}
                />
              )}
              {!isInternalURL(data.url) && (
                <TextWidget
                  id="external"
                  title={intl.formatMessage(messages.externalURL)}
                  required={false}
                  value={data.url}
                  icon={clearSVG}
                  iconAction={() => {
                    onChangeBlock(block, {
                      ...data,
                      url: '',
                    });
                  }}
                  onChange={() => {}}
                />
              )}
              <TextWidget
                id="alt"
                title={intl.formatMessage(messages.AltText)}
                required={false}
                value={data.alt}
                icon={data.alt ? clearSVG : null}
                iconAction={() =>
                  onChangeBlock(block, {
                    ...data,
                    alt: '',
                  })
                }
                onChange={(name, value) => {
                  onChangeBlock(block, {
                    ...data,
                    alt: value,
                  });
                }}
              />
            </Segment>
          </>
        )}
        <Accordion fluid styled className="form">
          <Accordion.Title
            active={activeAccIndex === 1}
            index={1}
            onClick={handleAccClick}
          >
            <FormattedMessage id="Text Content" defaultMessage="Text Content" />
            {activeAccIndex === 1 ? (
              <Icon name={upSVG} size="20px" />
            ) : (
              <Icon name={downSVG} size="20px" />
            )}
          </Accordion.Title>
          <Accordion.Content active={activeAccIndex === 1}>
            <Segment>
              <TextWidget
                id="header"
                title={intl.formatMessage(messages.header)}
                value={data.header}
                onChange={(name, value) => {
                  onChangeBlock(block, {
                    ...data,
                    header: value,
                  });
                }}
              />
              <SketchPicker
                color={data.headerColor}
                onChangeComplete={(color) => {
                  onChangeBlock(block, {
                    ...data,
                    headerColor: color.hex,
                  });
                }}
              />
              <TextareaWidget
                id="subheader"
                title={intl.formatMessage(messages.subHeader)}
                value={data.subHeader}
                onChange={(name, value) => {
                  onChangeBlock(block, {
                    ...data,
                    subHeader: value,
                  });
                }}
              />
              <SketchPicker
                color={data.subHeaderColor}
                onChangeComplete={(color) => {
                  onChangeBlock(block, {
                    ...data,
                    subHeaderColor: color.hex,
                  });
                }}
              />
              <Form.Field inline>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width="4">
                      <div className="wrapper">
                        <label htmlFor="field-align">
                          <FormattedMessage
                            id="Alignment"
                            defaultMessage="Alignment"
                          />
                        </label>
                      </div>
                    </Grid.Column>
                    <Grid.Column width="8" className="align-tools">
                      <div>
                        <Button.Group>
                          <Button
                            icon
                            basic
                            aria-label={intl.formatMessage(messages.left)}
                            onClick={() =>
                              onChangeBlock(block, {
                                ...data,
                                alignContent: 'left',
                              })
                            }
                            active={data.alignContent === 'left'}
                          >
                            <Icon name={imageLeftSVG} size="24px" />
                          </Button>
                        </Button.Group>
                        <Button.Group>
                          <Button
                            icon
                            basic
                            aria-label={intl.formatMessage(messages.center)}
                            onClick={() =>
                              onChangeBlock(block, {
                                ...data,
                                alignContent: 'center',
                              })
                            }
                            active={
                              data.alignContent === 'center' ||
                              !data.alignContent
                            }
                          >
                            <Icon name={imageFitSVG} size="24px" />
                          </Button>
                        </Button.Group>
                        <Button.Group>
                          <Button
                            icon
                            basic
                            aria-label={intl.formatMessage(messages.right)}
                            onClick={() =>
                              onChangeBlock(block, {
                                ...data,
                                alignContent: 'right',
                              })
                            }
                            active={data.alignContent === 'right'}
                          >
                            <Icon name={imageRightSVG} size="24px" />
                          </Button>
                        </Button.Group>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
              <Form.Field inline>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width="4">
                      <div className="wrapper">
                        <label htmlFor="field-align">
                          <FormattedMessage
                            id="Font Size"
                            defaultMessage="Font Size"
                          />
                        </label>
                      </div>
                    </Grid.Column>
                    <Grid.Column width="8" className="align-tools">
                      <div>
                        <Button.Group>
                          <Button
                            icon
                            basic
                            aria-label={intl.formatMessage(messages.small)}
                            onClick={() =>
                              onChangeBlock(block, {
                                ...data,
                                fontSize: 2.5,
                              })
                            }
                            active={data.fontSize === 2.5 || !data.fontSize}
                          >
                            <div className="image-sizes-text">S</div>
                          </Button>
                        </Button.Group>
                        <Button.Group>
                          <Button
                            icon
                            basic
                            aria-label={intl.formatMessage(messages.medium)}
                            onClick={() =>
                              onChangeBlock(block, {
                                ...data,
                                fontSize: 3.5,
                              })
                            }
                            active={data.fontSize === 3.5}
                          >
                            <div className="image-sizes-text">M</div>
                          </Button>
                        </Button.Group>
                        <Button.Group>
                          <Button
                            icon
                            basic
                            aria-label={intl.formatMessage(messages.large)}
                            onClick={() =>
                              onChangeBlock(block, {
                                ...data,
                                fontSize: 5,
                              })
                            }
                            active={data.fontSize === 5}
                          >
                            <div className="image-sizes-text">L</div>
                          </Button>
                        </Button.Group>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
            </Segment>
          </Accordion.Content>
          <Accordion.Title
            active={activeAccIndex === 2}
            index={2}
            onClick={handleAccClick}
          >
            <FormattedMessage id="Link" defaultMessage="Link" />
            {activeAccIndex === 2 ? (
              <Icon name={upSVG} size="20px" />
            ) : (
              <Icon name={downSVG} size="20px" />
            )}
          </Accordion.Title>
          <Accordion.Content active={activeAccIndex === 2}>
            <Segment>
              <TextWidget
                id="link-caption"
                title={intl.formatMessage(messages.linkCaption)}
                required={false}
                value={data.linkCaption}
                onChange={(name, value) => {
                  onChangeBlock(block, {
                    ...data,
                    linkCaption: value,
                  });
                }}
              />
              <TextWidget
                id="link"
                title={intl.formatMessage(messages.LinkTo)}
                required={false}
                value={data.href}
                icon={data.href ? clearSVG : navTreeSVG}
                iconAction={
                  data.href
                    ? () => {
                        onChangeBlock(block, {
                          ...data,
                          href: '',
                        });
                      }
                    : () =>
                        openObjectBrowser({
                          onSelectItem: (url) => {
                            onChangeBlock(block, {
                              ...data,
                              href: url,
                            });
                          },
                        })
                }
                onChange={(name, value) => {
                  onChangeBlock(block, {
                    ...data,
                    href: value,
                  });
                }}
              />
              <CheckboxWidget
                id="openLinkInNewTab"
                title={intl.formatMessage(messages.openLinkInNewTab)}
                value={data.openLinkInNewTab ? data.openLinkInNewTab : false}
                onChange={(name, value) => {
                  onChangeBlock(block, {
                    ...data,
                    openLinkInNewTab: value,
                  });
                }}
              />
              <SketchPicker
                color={data.linkColor}
                onChangeComplete={(color) => {
                  onChangeBlock(block, {
                    ...data,
                    linkColor: color.hex,
                  });
                }}
              />
            </Segment>
          </Accordion.Content>
        </Accordion>
      </Segment.Group>
    </div>
  );
};

export default BodyCaptionSidebar;
