import React from 'react';

export const EditMyModules = (props) => {
  return (
    <div className="MyModules-edit">
      <div className="placeholder">
        <p> Placeholder for Mymodules, shown only on edit mode </p>
      </div>
    </div>
  );
};

export default EditMyModules;
