import {
  EPICS_DEFINITIONS as EPICS,
  EPICS_TASK_VIEWS as TASKS,
  EPICS_TOAST_PROPS as TOAST,
} from 'volto-epics-addon/constants';

export const EPIC_SIGN_IN = 'portal-sign-in';
export const EPIC_COURSE_REGISTRATION = 'portal-course-registration';
export const EPIC_MODULE_REGISTRATION = 'portal-module-registration';
export const EPIC_MODULE_APPLICATION = 'portal-module-application';
export const EPIC_PORTAL_COURSE_SISU_UPDATE = 'portal-course-sisu-update';
EPICS[EPIC_SIGN_IN] = EPIC_SIGN_IN;
EPICS[EPIC_COURSE_REGISTRATION] = EPIC_COURSE_REGISTRATION;
EPICS[EPIC_MODULE_REGISTRATION] = EPIC_MODULE_REGISTRATION;
EPICS[EPIC_MODULE_APPLICATION] = EPIC_MODULE_APPLICATION;
EPICS[EPIC_PORTAL_COURSE_SISU_UPDATE] = EPIC_PORTAL_COURSE_SISU_UPDATE;

export const TASK_EMAIL_LOGIN = 'email-login';
TASKS[TASK_EMAIL_LOGIN] = '/email-login';
TOAST[TASK_EMAIL_LOGIN] = {
  title: 'Login incomplete',
  showCancel: true,
};

export const TASK_LOGIN = 'login';
TASKS[TASK_LOGIN] = '/login';
TOAST[TASK_LOGIN] = {
  title: 'Login incomplete',
  showCancel: true,
};

export const TASK_VERIFY_EMAIL_PROMPT = 'verify-email-prompt';
TASKS[TASK_VERIFY_EMAIL_PROMPT] = '/verify-email-prompt';
TOAST[TASK_VERIFY_EMAIL_PROMPT] = {
  title: 'Email verification incomplete',
  showCancel: true,
};

export const TASK_VERIFY_EMAIL = 'verify-email';
TASKS[TASK_VERIFY_EMAIL] = '/verify-email';
TOAST[TASK_VERIFY_EMAIL] = {
  title: 'Email verification incomplete',
  showCancel: true,
};

export const TASK_IDENTIFICATION_METHOD = 'identification-method';
TASKS[TASK_IDENTIFICATION_METHOD] = '/register/start';
TOAST[TASK_IDENTIFICATION_METHOD] = {
  title: 'Identification incomplete',
  showCancel: true,
};

export const TASK_SUOMIFI_REDIRECT = 'suomifi-redirect';
TASKS[TASK_SUOMIFI_REDIRECT] = '/register/suomifi/redirect';
TOAST[TASK_SUOMIFI_REDIRECT] = {
  title: 'Identification incomplete',
  showCancel: true,
};

export const TASK_SIGNICAT_PROMPT = 'signicat-prompt';
TASKS[TASK_SIGNICAT_PROMPT] = '/register/signicat/start';
TOAST[TASK_SIGNICAT_PROMPT] = {
  title: 'Identification incomplete',
  showCancel: true,
};

export const TASK_SIGNICAT_CAPTURE = 'signicat-capture';
TASKS[TASK_SIGNICAT_CAPTURE] = '/register/signicat/redirect';
TOAST[TASK_SIGNICAT_CAPTURE] = {
  title: 'Identification incomplete',
  showCancel: true,
};

export const TASK_REGISTRATION_FORM = 'registration-form';
TASKS[TASK_REGISTRATION_FORM] = '/register';
TOAST[TASK_REGISTRATION_FORM] = {
  title: 'Registration incomplete',
  showCancel: true,
};

export const TASK_CHECKOUT = 'checkout';
TASKS[TASK_CHECKOUT] = '/checkout';
TOAST[TASK_CHECKOUT] = {
  title: 'Checkout incomplete',
  showCancel: true,
};

export const TASK_PROCEED_TO_PAY = 'proceed-to-pay';
TASKS[TASK_PROCEED_TO_PAY] = '/checkout/redirect';
TOAST[TASK_PROCEED_TO_PAY] = {
  title: 'Checkout incomplete',
  showCancel: false,
};

export const TASK_CHECKOUT_CONFIRMATION_COURSE = 'checkout-confirmation-course';
TASKS[TASK_CHECKOUT_CONFIRMATION_COURSE] = '/checkout/finish-course';
TOAST[TASK_CHECKOUT_CONFIRMATION_COURSE] = {
  title: 'Checkout incomplete',
  showCancel: false,
};

export const TASK_CHECKOUT_CONFIRMATION_MODULE = 'checkout-confirmation-module';
TASKS[TASK_CHECKOUT_CONFIRMATION_MODULE] = '/checkout/finish-module';
TOAST[TASK_CHECKOUT_CONFIRMATION_MODULE] = {
  title: 'Checkout incomplete',
  showCancel: false,
};

export const TASK_PASSWORD_CHANGE_IDENTIFICATION_METHOD =
  'password-change-identification-method';
TASKS[TASK_PASSWORD_CHANGE_IDENTIFICATION_METHOD] = '/password-change/start';
TOAST[TASK_PASSWORD_CHANGE_IDENTIFICATION_METHOD] = {
  title: 'Password change incomplete',
  showCancel: true,
};

export const TASK_PASSWORD_CHANGE_SIGNICAT_PROMPT =
  'password-change-signicat-prompt';
TASKS[TASK_PASSWORD_CHANGE_SIGNICAT_PROMPT] = '/password-change/signicat/start';
TOAST[TASK_PASSWORD_CHANGE_SIGNICAT_PROMPT] = {
  title: 'Password change incomplete',
  showCancel: true,
};

export const TASK_PASSWORD_CHANGE_FORM = 'password-change-form';
TASKS[TASK_PASSWORD_CHANGE_FORM] = '/password-change';
TOAST[TASK_PASSWORD_CHANGE_FORM] = {
  title: 'Password change incomplete',
  showCancel: true,
};

export const TASK_ACCOUNT_AUTHENTICATION_IDENTIFICATION_METHOD =
  'account-authenticate-identification-method';
TASKS[TASK_ACCOUNT_AUTHENTICATION_IDENTIFICATION_METHOD] =
  '/verify-account/start';
TOAST[TASK_ACCOUNT_AUTHENTICATION_IDENTIFICATION_METHOD] = {
  title: 'Account verification incomplete',
  showCancel: true,
};

export const TASK_ACCOUNT_AUTHENTICATION_SIGNICAT_PROMPT =
  'account-authenticate-signicat-prompt';
TASKS[TASK_ACCOUNT_AUTHENTICATION_SIGNICAT_PROMPT] =
  '/verify-account/signicat/start';
TOAST[TASK_ACCOUNT_AUTHENTICATION_SIGNICAT_PROMPT] = {
  title: 'Account verification incomplete',
  showCancel: true,
};

export const TASK_ACCOUNT_AUTHENTICATION_FORM = 'account-authentication-form';
TASKS[TASK_ACCOUNT_AUTHENTICATION_FORM] = '/verify-account';
TOAST[TASK_ACCOUNT_AUTHENTICATION_FORM] = {
  title: 'Account verification incomplete',
  showCancel: true,
};

export const TASK_APPLICATION_VERIFY_CONTACT_INFORMATION =
  'application-verify-contact-information';
TASKS[TASK_APPLICATION_VERIFY_CONTACT_INFORMATION] =
  '/verify-contact-information';
TOAST[TASK_APPLICATION_VERIFY_CONTACT_INFORMATION] = {
  title: 'Contact information verification incomplete',
  showCancel: true,
};
