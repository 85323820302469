import React, { useEffect, useState } from 'react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { searchContent } from '@plone/volto/actions';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { Link } from 'react-router-dom';
import { Container, Grid, Card } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import PortalDefaultCardBg from './default_card_bg.svg';

const messages = defineMessages({
  actionNeeded: {
    id: 'actionNeeded',
    defaultMessage: 'Action needed!',
  },
  loginfirst: {
    id: 'loginfirst',
    defaultMessage: 'You must log in first to see your studies.',
  },
  noModulesText: {
    id: 'noModulesText',
    defaultMessage: "You don't have any modules yet. ",
  },
  noModulesLinkText: {
    id: 'noModulesLinkText',
    defaultMessage: "Let's get started with module enrollment!",
  },
});

const ViewMyModules = (props) => {
  const intl = useIntl();
  const token = useSelector((state) => state.userSession.token);
  const items = useSelector((state) => state.search.items);
  const currentLang = useSelector((state) => state.intl.locale);
  const dispatch = useDispatch();
  const [modules, setModules] = useState([]);

  // fills `items` with content.
  useEffect(() => {
    if (token) {
      dispatch(
        searchContent(
          `/students/${encodeURIComponent(jwtDecode(token).sub).replace(
            '%',
            '-',
          )}`,
          {
            portal_type: ['Application'],
            fullobjects: true,
            metadata_fields: '_all',
          },
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const moduleItems = [];
    items.forEach((item) => {
      if (item['@type'] === 'Application') {
        moduleItems.push(item);
      }
    });
    setModules(moduleItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const renderState = (state) => {
    if (state === 'draft') {
      return (
        <p className="draft">{intl.formatMessage(messages.actionNeeded)}</p>
      );
    } else if (state === 'selected') {
      return (
        <p className="selected">{intl.formatMessage(messages.actionNeeded)}</p>
      );
    }
    return null;
  };

  return (
    <div className="mymodules-view">
      {token ? (
        <div className="mymodules">
          <div className="mycm-content">
            <Container className="container-modules">
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column>
                    <div className="module-cards-list-template">
                      <Grid stackable>
                        {modules.length === 0 ? ( // Check if the modules array is empty
                          <div className="no-module-container">
                            <p>
                              {intl.formatMessage(messages.noModulesText)}{' '}
                              {currentLang === 'en' ? (
                                <Link to="/en/modules">
                                  {intl.formatMessage(
                                    messages.noModulesLinkText,
                                  )}
                                </Link>
                              ) : (
                                <Link to="/fi/modules">
                                  {intl.formatMessage(
                                    messages.noModulesLinkText,
                                  )}
                                </Link>
                              )}
                            </p>
                          </div>
                        ) : (
                          modules.map((item, index) => (
                            <Grid.Column
                              stretched
                              tablet={6}
                              computer={4}
                              widescreen={3}
                              key={item['UID']}
                            >
                              <Card
                                fluid
                                as={Link}
                                to={item?.['@id']}
                                className={'state-' + item?.['review_state']}
                              >
                                <div className="module-cards-header">
                                  <div
                                    className="module-cards-image"
                                    style={{
                                      backgroundImage: `url(${
                                        flattenToAppURL(
                                          item.image?.scales.large.download ||
                                            '',
                                        ) || PortalDefaultCardBg
                                      })`,
                                    }}
                                  >
                                    {renderState(item?.['review_state'])}
                                  </div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 1440 320"
                                  >
                                    <path
                                      fill="#F6F7F7"
                                      fillOpacity="1"
                                      d="M0,224L80,234.7C160,245,320,267,480,266.7C640,267,800,245,960,213.3C1120,181,1280,139,1360,117.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
                                    ></path>
                                  </svg>
                                </div>
                                <Card.Content>
                                  <div className="module-cards-title">
                                    {item?.module?.title}
                                  </div>
                                </Card.Content>
                                <Card.Content extra>
                                  <Grid>
                                    <Grid.Row columns={2}>
                                      <Grid.Column className="module-cards-meta"></Grid.Column>
                                    </Grid.Row>
                                  </Grid>
                                </Card.Content>
                              </Card>
                            </Grid.Column>
                          ))
                        )}
                      </Grid>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </div>
        </div>
      ) : (
        <Container>
          <div className="not-logged-in-dashboard">
            <h4> {intl.formatMessage(messages.loginfirst)} </h4>
          </div>
        </Container>
      )}
    </div>
  );
};

export default ViewMyModules;
