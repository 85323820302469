import React, { useEffect } from 'react';
import { asyncConnect, hasApiExpander } from '@plone/volto/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getContent, listActions, lockContent } from '@plone/volto/actions';
import DefaultEdit from '@package/components/manage/Edit/DefaultEdit';
import config from '@plone/volto/registry';
import { getBaseUrl } from '@plone/volto/helpers';

const CustomEdit = (props) => {
  const content = useSelector((state) => state.content.data);
  const contentTypesEdits = config.settings.contentTypesEdits;
  const dispatch = useDispatch();

  // As the page might only load through the router,
  // ensure that we get content that matches the current path
  useEffect(() => {
    if (props?.location?.pathname) {
      dispatch(getContent(getBaseUrl(props.location.pathname)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname]);

  // Check we have defined a custom Edit view for this content type
  if (content?.['@type']) {
    if (Object.keys(contentTypesEdits).includes(content['@type'])) {
      const Component = contentTypesEdits[content['@type']];
      return <Component {...props} />;
    }
    // Otherwise, carry on as normal.
    return <DefaultEdit {...props} />;
  }
  return null;
};

const asyncItems = [
  {
    key: 'actions',
    promise: async ({ location, store: { dispatch } }) => {
      // Do not trigger the actions action if the expander is present
      if (!hasApiExpander('actions', getBaseUrl(location.pathname))) {
        return await dispatch(listActions(getBaseUrl(location.pathname)));
      }
    },
  },
  {
    key: 'content',
    promise: async ({ location, store: { dispatch } }) => {
      const content = await dispatch(getContent(getBaseUrl(location.pathname)));
      if (content?.lock !== undefined) {
        await dispatch(lockContent(getBaseUrl(location.pathname)));
      }
      return content;
    },
  },
];

// Wrap with asyncItems again here as they do not get called otherwise.
export default asyncConnect(asyncItems)(CustomEdit);
