import { toast } from 'react-toastify';
import { defineMessages, useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { Helmet } from '@plone/volto/helpers';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { Button, Container, Grid, Segment } from 'semantic-ui-react';
import RegistrationStepsContainer from '@package/components/theme/Registration/StepsContainer';
import AuthenticationStepsContainer from '@package/components/theme/Authentication/AuthenticationStepsContainer';
import PasswordStepsContainer from '@package/components/theme/Password/PasswordChangeStepsContainer';
import { completeTask, useTask } from 'volto-epics-addon/helpers';
import {
  TASK_IDENTIFICATION_METHOD,
  TASK_PASSWORD_CHANGE_IDENTIFICATION_METHOD,
  TASK_ACCOUNT_AUTHENTICATION_IDENTIFICATION_METHOD,
} from '@package/constants';
import { EPICS_PATH_CONTINUE } from 'volto-epics-addon/constants';
import ContinuationToast from 'volto-epics-addon/components/theme/Epics/ContinuationToast';
import { defaultTitle } from '@package/helpers';

import './IdentificationMethodPage.less';

const messages = defineMessages({
  register: {
    id: 'Sign up',
    defaultMessage: 'Sign up',
  },
  suomifiHeader: {
    id: 'identifcation-method-suomifi-header',
    defaultMessage: 'I have Finnish online banking identifiers.',
  },
  signicatHeader: {
    id: 'identifcation-method-signicat-header',
    defaultMessage:
      'I am an international student and I do not have Finnish online banking identifiers.',
  },
  emailVerified: {
    id: 'emailVerified',
    defaultMessage: 'Email verified',
  },
  chooseIdentificationMethod: {
    id: 'chooseIdentificationMethod',
    defaultMessage: 'Choose identification method',
  },
  identityVerification: {
    id: 'Identity verification',
    defaultMesage: 'Identity verification',
  },
  identityMethodHelp1: {
    id: 'identityMethodHelp1',
    defaultMessage:
      'The registration process continues with identification. Select the method you would like to use for your e-identification. If you have Finnish online banking identifiers, select option “I have Finnish online banking identifiers.',
  },
  identityMethodHelp2: {
    id: 'identityMethodHelp2',
    defaultMessage:
      'If you do not have Finnish online banking identifiers, select option “I am an international student and I do not have Finnish online banking identifiers” and use your passport or EU ID card for the identification. Please note that you cannot use a Finnish passport or ID card for identification purposes.',
  },
  identityMethodHelp3: {
    id: 'identityMethodHelp3',
    defaultMessage:
      'If you are a Finnish citizen, and you do not have Finnish online banking identifiers, contact Student and Academic Services through HelpJYU',
  },
});

const IdentificationMethodPage = ({ StepsContainer, taskKey }) => {
  const [loading, setLoading] = useState(true);
  const task = useTask(taskKey);

  const intl = useIntl();
  const history = useHistory();

  useEffect(() => {
    if (!!task) {
      setLoading(false);
    }

    if (task?.completed === true) {
      history.push(EPICS_PATH_CONTINUE);
    }

    if (task?.taskDefinitionKey === taskKey && !task?.variables?.errorMessage) {
      toast.success(
        <ContinuationToast
          success
          title={intl.formatMessage(messages.emailVerified)}
          content={task?.variables?.email}
        />,
        {
          autoClose: true,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  const onSignicatSubmit = async () => {
    setLoading(true);
    try {
      await completeTask(taskKey, {
        identificationMethod: 'signicat',
      });
    } catch (e) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const onSuomifiSubmit = async () => {
    setLoading(true);
    try {
      await completeTask(taskKey, {
        identificationMethod: 'suomifi',
      });
    } catch (e) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return (
    <div id="page-register">
      <Helmet title={defaultTitle(intl.formatMessage(messages.register))} />
      <Grid className="grid-container" stackable stretched>
        <Grid.Column width={3} className="steps-container-wrapper top">
          <StepsContainer step={1} subStep={1} />
        </Grid.Column>
        <Grid.Column width={9} className="steps-content-wrapper">
          <Segment basic loading={loading}>
            <div className="reg-step-container">
              <Container>
                <h2>{intl.formatMessage(messages.identityVerification)}</h2>
                <div className="description-container">
                  <h3 className="description-container-title">
                    {intl.formatMessage(messages.chooseIdentificationMethod)}
                  </h3>
                  <p className="description-container-part1">
                    {intl.formatMessage(messages.identityMethodHelp1)}
                  </p>
                  <p className="description-container-part2">
                    {intl.formatMessage(messages.identityMethodHelp2)}
                  </p>
                  <p className="description-container-part3">
                    {intl.formatMessage(messages.identityMethodHelp3)}
                    {' ('}
                    <a href="https://help.jyu.fi">help.jyu.fi</a>
                    {').'}
                  </p>
                  <div style={{ marginTop: '3em' }} />
                </div>
                <div className="identification-method-cards">
                  <div className="suomifi-card method-card card-button">
                    <Button
                      basic
                      className="suomifi-card-button card-button"
                      onClick={onSuomifiSubmit}
                    >
                      {intl.formatMessage(messages.suomifiHeader)}
                    </Button>
                  </div>
                  <div className="signicat-card method-card card-button">
                    <Button
                      basic
                      className="signicat-card-button card-button"
                      onClick={onSignicatSubmit}
                    >
                      {intl.formatMessage(messages.signicatHeader)}
                    </Button>
                  </div>
                </div>
              </Container>
            </div>
          </Segment>
        </Grid.Column>
        <Grid.Column width={3} className="steps-container-wrapper bottom">
          <StepsContainer step={1} subStep={1} />
        </Grid.Column>
      </Grid>
    </div>
  );
};

function RegistrationProps(state) {
  return {
    StepsContainer: RegistrationStepsContainer,
    taskKey: TASK_IDENTIFICATION_METHOD,
  };
}
function AuthenticationProps(state) {
  return {
    StepsContainer: AuthenticationStepsContainer,
    taskKey: TASK_ACCOUNT_AUTHENTICATION_IDENTIFICATION_METHOD,
  };
}
function PasswordProps(state) {
  return {
    StepsContainer: PasswordStepsContainer,
    taskKey: TASK_PASSWORD_CHANGE_IDENTIFICATION_METHOD,
  };
}

const RegistrationIdentificationMethodPage = connect(RegistrationProps)(
  IdentificationMethodPage,
);
const AuthenticationIdentificationMethodPage = connect(AuthenticationProps)(
  IdentificationMethodPage,
);
const PasswordIdentificationMethodPage = connect(PasswordProps)(
  IdentificationMethodPage,
);

export {
  RegistrationIdentificationMethodPage,
  AuthenticationIdentificationMethodPage,
  PasswordIdentificationMethodPage,
};
