import React from 'react';
import PropTypes from 'prop-types';

const EnrolmentListingTemplate = ({ items, isEditMode }) => {
  var titles = [];
  items.forEach((elem) => {
    if (!titles.includes(elem.title)) {
      titles.push(elem.title);
    }
  });

  var newItems = [];
  titles.forEach((title) => {
    var contents = [];
    items.forEach((elem) => {
      if (elem.title === title) {
        contents.push({
          studentPrincipalName: elem['@id'].split('/')[2].replace('-40', '@'),
          created: elem.created,
        });
      }
    });
    var newElem = {
      name: title,
      contents: contents,
    };
    newItems.push(newElem);
  });

  return (
    <div className="enrolments-listing-template">
      {newItems.map((item, index) => (
        <>
          <div className="enrolment-header" id={`enrolment-header-${index}`}>
            <h4> {item['name']} </h4>
          </div>
          <div className="enrolment-body">
            <ul>
              {item['contents'].map((enrol_item, enrol_index) => (
                <li>
                  {' '}
                  <b>{`${enrol_item['studentPrincipalName']}`}</b>{' '}
                  {`(${enrol_item['created']})`}{' '}
                </li>
              ))}
            </ul>
          </div>
        </>
      ))}
    </div>
  );
};

EnrolmentListingTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default EnrolmentListingTemplate;
