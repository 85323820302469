/**
 * Edit BodyCaption block.
 * @module components/manage/Blocks/BodyCaption/Edit
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Header, Image } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import config from '@plone/volto/registry';
import { Icon, SidebarPortal } from '@plone/volto/components';
import { createContent } from '@plone/volto/actions';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import BodyCaptionSidebar from './BodyCaptionSidebar';
import circleRightSVG from '@plone/volto/icons/circle-right.svg';
import bgCurvesSVG from '@package/components/manage/Blocks/BodyCaption/bg_caption_curves.svg';

/**
 * Edit body caption block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    content: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    pathname: PropTypes.string.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    createContent: PropTypes.func.isRequired,
    openObjectBrowser: PropTypes.func.isRequired,
  };

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.request.loading && nextProps.request.loaded) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        url: nextProps.content['@id'],
        alt: nextProps.content.title,
      });
    }
  }

  render() {
    const { data } = this.props;

    // Initialize default values
    if (!data.contentHeight) {
      data.contentHeight = 200;
    }
    if (!data.alignContent) {
      data.alignContent = 'center';
    }
    if (!data.fontSize) {
      data.fontSize = 3;
    }

    const apiPath = `${config.settings.apiPath}`;

    return (
      <>
        <div
          style={{
            backgroundImage: `url(${
              data.url && isInternalURL(data.url)
                ? flattenToAppURL(data.url) + '/@@images/image'
                : data.url
            })`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: '50% 50%',
            height: `${data.contentHeight}px`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '3em',
          }}
          className="full-width"
          alt={data.alt || ''}
        >
          <Image
            alt="curves"
            src={bgCurvesSVG}
            style={{
              width: '100%',
              position: 'absolute',
              bottom: '-45px',
            }}
          />
          <div style={{ width: '70%', zIndex: '1' }}>
            <div style={{ textAlign: `${data.alignContent}` }}>
              <Header
                as="h1"
                style={{
                  fontFamily: 'Aleo',
                  display: 'block',
                  fontSize: `${data.fontSize}em`,
                  fontWeight: '700',
                  color: `${data.headerColor}`,
                  wordBreak: 'break-word',
                  marginBottom: '0.4em',
                }}
                className="body-caption-header"
              >
                {data.header}
                <Header.Subheader
                  style={{
                    fontSize: `${data.fontSize / 2}rem`,
                    display: 'block',
                    color: `${data.subHeaderColor}`,
                    padding: '0.8em 0',
                  }}
                >
                  {data.subHeader}
                </Header.Subheader>
              </Header>
              <div
                style={{
                  display: 'inline-block',
                  fontSize: `${data.fontSize / 2.5}rem`,
                }}
              >
                {(() => {
                  if (data.linkCaption && data.href) {
                    if (
                      (data.href.startsWith('http') ||
                        data.href.startsWith('https')) &&
                      !data.href.includes(apiPath)
                    ) {
                      return (
                        <div>
                          <a
                            target={data.openLinkInNewTab ? '_blank' : null}
                            href={data.href}
                            style={{
                              color: `${data.linkColor}`,
                              display: 'flex',
                              alignItems: 'center',
                              textTransform: 'uppercase',
                              padding: '0.5em 1em',
                              fontSize: '1.3rem',
                              borderRadius: '10px',
                              border: `3px solid ${data.linkColor}`,
                            }}
                          >
                            {data.linkCaption}
                            <div
                              style={{
                                marginLeft: '15px',
                              }}
                            >
                              <Icon
                                name={circleRightSVG}
                                color={data.linkColor}
                              />
                            </div>
                          </a>
                        </div>
                      );
                    } else {
                      return (
                        <div>
                          <Link
                            className="bodyHeadingLink"
                            to={data.href.replace(apiPath, '')}
                            target={data.openLinkInNewTab ? '_blank' : null}
                            style={{
                              color: `${data.linkColor}`,
                              display: 'flex',
                              alignItems: 'center',
                              textTransform: 'uppercase',
                              padding: '0.5em 1em',
                              fontSize: '1.3rem',
                              borderRadius: '10px',
                              border: `3px solid ${data.linkColor}`,
                            }}
                          >
                            {data.linkCaption}
                            <div
                              style={{
                                marginLeft: '15px',
                              }}
                            >
                              <Icon
                                name={circleRightSVG}
                                color={data.linkColor}
                              />
                            </div>
                          </Link>
                        </div>
                      );
                    }
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
        <SidebarPortal selected={this.props.selected}>
          <BodyCaptionSidebar {...this.props} />
        </SidebarPortal>
      </>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state, ownProps) => {
      return {
        request: state.content.subrequests[ownProps.block] || {},
        content: state.content.subrequests[ownProps.block]?.data || {},
      };
    },
    { createContent },
  ),
)(Edit);
