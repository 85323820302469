import React from 'react';
import { useTask } from 'volto-epics-addon/helpers';
import { EPICS_PATH_RAISE } from 'volto-epics-addon/constants';
import config from '@plone/volto/registry';
import { TASK_LOGIN } from '@package/constants';

const UsernameHiddenInput = () => {
  const task = useTask(TASK_LOGIN);
  if (task?.completed === false && task?.variables?.email?.length) {
    return (
      <>
        <span style={{ fontSize: '1.2rem' }}>{task.variables.email}</span>
        <input
          id="login"
          name="login"
          type="hidden"
          value={task.variables.email}
        />
      </>
    );
  } else if (task) {
    // Task has invalid data. Raise error page.
    // Set window.location to force hitting middleware routes
    window.location = `${config.settings.publicPath}${EPICS_PATH_RAISE}`;
    return null;
  } else {
    return null;
  }
};

export default UsernameHiddenInput;
