import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { FormFieldWrapper } from '@plone/volto/components';

const RadioButtonWidget = (props) => {
  const { id, value, onChange, isDisabled, choices } = props;

  return (
    <FormFieldWrapper {...props} columns={1}>
      <div className="wrapper radio-button-widget-container">
        <div className="radio-button-widget-choices">
          {choices.map(([choiceValue, choiceLabel], index) => (
            <Radio
              key={index}
              name={`field-${id}`}
              value={choiceValue}
              checked={value === choiceValue}
              disabled={isDisabled}
              onChange={(event, { value: newValue }) => {
                onChange(id, newValue);
              }}
              label={
                <label htmlFor={`field-${id}-${index}`}>{choiceLabel}</label>
              }
            />
          ))}
        </div>
      </div>
    </FormFieldWrapper>
  );
};

RadioButtonWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  wrapped: PropTypes.bool,
};

RadioButtonWidget.defaultProps = {
  description: null,
  required: false,
  error: [],
  value: null,
  onChange: null,
  onEdit: null,
  onDelete: null,
};

export default injectIntl(RadioButtonWidget);
