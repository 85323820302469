/**
 * Login container.
 * @module components/theme/Login/Login
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { EPICS_PATH_REDIRECT } from 'volto-epics-addon/constants';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Button, Form, Grid, Input, Segment } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import qs from 'query-string';

import { Icon, Toast } from '@plone/volto/components';
import { getNavigation, login } from '@plone/volto/actions';
import { toast } from 'react-toastify';

import config from '@plone/volto/registry';

import request from 'superagent';

import UsernameHiddenInput from '@package/components/theme/Login/UsernameHiddenInput';
import { PortalLoginPageLogo } from '@package/icons';
import { completeTask } from 'volto-epics-addon/helpers';
import { TASK_LOGIN } from '@package/constants';
import { defaultTitle } from '@package/helpers';
import { Helmet } from '@plone/volto/helpers';

const messages = defineMessages({
  login: {
    id: 'Log in',
    defaultMessage: 'Log in',
  },
  loginName: {
    id: 'Email address',
    defaultMessage: 'Email address',
  },
  Login: {
    id: 'Login',
    defaultMessage: 'Login',
  },
  password: {
    id: 'Password',
    defaultMessage: 'Password',
  },
  forgotPassword: {
    id: 'Forgot password',
    defaultMessage: 'Forgot password',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  loginFailed: {
    id: 'Login Failed',
    defaultMessage: 'Login Failed',
  },
  loginFailedContent: {
    id: 'Both email address and password are case sensitive, check that caps lock is not enabled.',
    defaultMessage:
      'Both email address and password are case sensitive, check that caps lock is not enabled.',
  },
  signIn: {
    id: 'Sign in',
    defaultMessage: 'Sign in',
  },
  welcome: {
    id: 'Welcome',
    defaultMessage: 'Welcome',
  },
});

/**
 * Login class.
 * @class Login
 * @extends Component
 */
class Login extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    login: PropTypes.func.isRequired,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
    loading: PropTypes.bool,
    token: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    returnUrl: PropTypes.string,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    error: null,
    loading: null,
    token: null,
    returnUrl: null,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.onLogin = this.onLogin.bind(this);
    this.state = { loading: false };
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.token) {
      // this.props.history.push(this.props.returnUrl || '/');
      if (toast.isActive('loginFailed')) {
        toast.dismiss('loginFailed');
      }
      (async () => {
        await completeTask(TASK_LOGIN, {});
      })();
    }
    if (nextProps.error) {
      if (!toast.isActive('loginFailed')) {
        toast.error(
          <Toast
            error
            title={this.props.intl.formatMessage(messages.loginFailed)}
            content={this.props.intl.formatMessage(messages.loginFailedContent)}
          />,
          { autoClose: false, toastId: 'loginFailed' },
        );
      }
    }
  }

  UNSAFE_componentWillMount() {
    if (config.settings.isMultilingual) {
      this.props.getNavigation(`/${this.props.lang}`, config.settings.navDepth);
    } else {
      this.props.getNavigation('/', config.settings.navDepth);
    }
  }

  componentWillUnmount() {
    if (config.settings.isMultilingual) {
      this.props.getNavigation(`/${this.props.lang}`, config.settings.navDepth);
    } else {
      this.props.getNavigation('/', config.settings.navDepth);
    }

    if (toast.isActive('loginFailed')) {
      toast.dismiss('loginFailed');
    }
  }

  /**
   * On login handler
   * @method onLogin
   * @param {Object} event Event object.
   * @returns {undefined}
   */
  onLogin(event) {
    event.preventDefault();
    this.props.login(
      document.getElementsByName('login')[0].value,
      document.getElementsByName('password')[0].value,
    );
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <div id="main-login-page">
        <Helmet
          title={defaultTitle(this.props.intl.formatMessage(messages.login))}
        />
        <div className="login-container">
          <div className="login-logo">
            <Icon name={PortalLoginPageLogo} size="80px" color="#012957" />
          </div>
          <div className="email-login-title">
            <h1>
              {this.props.intl.formatMessage(messages.welcome)}
              {'!'}
            </h1>
          </div>
          <Form method="post" onSubmit={this.onLogin}>
            <Segment.Group
              raised
              style={{
                border: 'none',
                background: 'none',
                display: 'flex',
                margin: '0 auto',
                maxWidth: '400px',
              }}
            >
              <div
                className="email-login-title"
                style={{ margin: '0 !important' }}
              >
                <h1>
                  <UsernameHiddenInput history={this.props.history} />
                </h1>
              </div>
              <Segment basic>
                <FormattedMessage
                  id="Please enter your password"
                  defaultMessage="Please enter your password"
                />
              </Segment>
              <Segment className="form">
                <Form.Field inline className="help" style={{ padding: '0' }}>
                  <Grid>
                    <Grid.Row stretched>
                      <Grid.Column>
                        {/* eslint-disable jsx-a11y/no-autofocus */}
                        <Input
                          type="password"
                          id="password"
                          name="password"
                          placeholder={this.props.intl.formatMessage(
                            messages.password,
                          )}
                          tabIndex={0}
                          autoFocus
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form.Field>
              </Segment>
              <Segment className="login-button-container" clearing>
                <Grid stretched>
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <Button
                        className="additional-button"
                        primary
                        type="button"
                        id="login-button-forgot"
                        aria-label={this.props.intl.formatMessage(
                          messages.forgotPassword,
                        )}
                        title={this.props.intl.formatMessage(
                          messages.forgotPassword,
                        )}
                        disabled={this.props.loading}
                        loading={this.state.loading}
                        style={{
                          margin: '0',
                          padding: '1.1em',
                        }}
                        onClick={() => {
                          this.setState({ loading: true });
                          (async () => {
                            await request.post(
                              `${config.settings.publicPath}/trigger-password-change`,
                            );
                            window.location = `${config.settings.publicPath}${EPICS_PATH_REDIRECT}`;
                          })();
                        }}
                      >
                        {this.props.intl.formatMessage(messages.forgotPassword)}
                      </Button>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <Button
                        primary
                        type="submit"
                        id="login-form-next"
                        aria-label={this.props.intl.formatMessage(
                          messages.login,
                        )}
                        title={this.props.intl.formatMessage(messages.login)}
                        disabled={this.state.loading}
                        loading={this.props.loading}
                        style={{
                          margin: '0',
                          border: '1px solid #012957',
                        }}
                      >
                        {this.props.intl.formatMessage(messages.signIn)}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Segment.Group>
          </Form>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  injectIntl,
  connect(
    (state, props) => ({
      lang: state.intl.locale,
      error: state.userSession.login.error,
      loading: state.userSession.login.loading,
      token: state.userSession.token,
      returnUrl:
        qs.parse(props.location.search).return_url ||
        props.location.pathname
          .replace(/\/login$/, '')
          .replace(/\/logout$/, '') ||
        '/',
    }),
    { login, getNavigation },
  ),
)(Login);
