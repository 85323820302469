import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { Helmet } from '@plone/volto/helpers';
import { useHistory } from 'react-router';
import { Container, Form, Grid, Image, Segment } from 'semantic-ui-react';
import { SignicatCameraImage, SignicatDocsImage } from '@package/images';
import StepsNavigation from '@package/components/theme/Identification/StepsNavigation';
import { completeTask, useTask } from 'volto-epics-addon/helpers';
import { TASK_ACCOUNT_AUTHENTICATION_SIGNICAT_PROMPT } from '@package/constants';
import { EPICS_PATH_CONTINUE } from 'volto-epics-addon/constants';
import AuthenticationStepsContainer from '@package/components/theme/Authentication/AuthenticationStepsContainer';
import { defaultTitle } from '@package/helpers';

const messages = defineMessages({
  title: {
    id: 'Identity verification',
    defaultMessage: 'Identity verification',
  },
});

const AuthenticationSignicatPage = () => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const task = useTask(TASK_ACCOUNT_AUTHENTICATION_SIGNICAT_PROMPT);

  const intl = useIntl();
  const history = useHistory();

  useEffect(() => {
    if (!!task) {
      setLoading(false);
    }

    if (task?.completed === true) {
      setLoading(true);
      history.push(EPICS_PATH_CONTINUE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  const onSubmit = async () => {
    setLoading(true);
    try {
      await completeTask(TASK_ACCOUNT_AUTHENTICATION_SIGNICAT_PROMPT, {});
    } catch (e) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return (
    <div id="page-register">
      <Helmet title={defaultTitle(intl.formatMessage(messages.title))} />
      <Grid className="grid-container" stackable stretched>
        <Grid.Column width={3} className="steps-container-wrapper top">
          <AuthenticationStepsContainer step={1} subStep={1} />
        </Grid.Column>
        <Grid.Column width={9} className="steps-content-wrapper">
          <Segment basic loading={loading}>
            <div className="reg-step-container">
              <Container>
                <h2>
                  <FormattedMessage
                    id="Identity verification"
                    defaultMessage="Identity verification"
                  />
                </h2>
                <div className="description-container">
                  <span className="description-container-body">
                    Your JYU user account requires authentication by electronic
                    identification. You will need a device with a camera and a
                    valid identity document ready to continue. It is possible to
                    start the identification on a computer and switch to a
                    mobile device on the fly.
                    <br />
                    <br />
                    You are only able to authenticate your account if your name,
                    date of birth and nationality on the the identity document
                    match the information on your previously registered user
                    account.
                  </span>
                  <p style={{ marginTop: '1em' }}>
                    If you have a Finnish bank ID, you are able to authenticate
                    your user account at{' '}
                    <a href="https://account.jyu.fi">OMA-service</a>.
                  </p>
                </div>
                <Grid
                  stackable
                  stretched
                  columns={2}
                  className="identification-grid"
                >
                  <Grid.Row>
                    <Grid.Column>
                      <div className="ident-desc">
                        <span>You will need a device with a camera</span>
                      </div>
                    </Grid.Column>
                    <Grid.Column>
                      <div className="ident-image">
                        <Image src={SignicatCameraImage} />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <div className="ident-desc">
                        <span>Have all the necessary documents ready</span>
                      </div>
                    </Grid.Column>
                    <Grid.Column>
                      <div className="ident-image">
                        <Image src={SignicatDocsImage} />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={12}>
                      <Form id="reg-form" loading={loading}>
                        <div className="term-description">
                          <h1>Terms of service</h1>
                          <p>
                            You are about to start the strong electronic
                            identification process.
                          </p>
                          <p>
                            The identification procedure will require a VALID
                            passport or a European identity card. Please make
                            sure that you have your{' '}
                            <b>passport/European identity card available</b> and
                            also a <b>good quality camera</b> (mobile phone is
                            recommended). You will need to show your passport
                            and live video of yourself, so that your
                            identification can be automatically verified.
                          </p>

                          <h2>Consent to processing personal data</h2>
                          <p>
                            In order for the processing of personal data to be
                            deemed lawful, the University of Jyväskylä must
                            identify a lawful basis for processing. These are
                            under Article 6 of the GDPR (consent), and an
                            additional condition for processing under Article
                            9(4) of the GDPR (explicit consent).
                          </p>

                          <p>
                            You have been asked to take part in biometric
                            identification.
                          </p>

                          <p>
                            You have read the privacy notice and obtained
                            sufficient information about the processing of your
                            personal data. You understand that participating in
                            the biometric identification is voluntary. You have
                            the right, at any time to withdraw your consent and
                            use the optional way (described in privacy notice)
                            for identification.
                          </p>
                          <p>
                            By accepting terms of service, you give your consent
                            to the processing of special categories of personal
                            data which is biometric data (facial recognition)
                            for unambiguous identification.
                          </p>
                          <p>
                            By accepting terms of service, you give your consent
                            to processing the other personal data needed for
                            identification (personal name, personal contact
                            information (address, e-mail, phone number, etc.),
                            national identity number / social security number.
                            As the solution uses optical verification of
                            identity documents, any data visually available on
                            the ID document will be part of the verification and
                            resulting evidence. This includes a selfie or video
                            sequence of the end user which is used to match with
                            the portrait in the document.
                          </p>
                          <p>
                            University of Jyväskylä will process consents, name,
                            date of birth, gender, nationality and document
                            number pursuant to Article 6(1)(e) or 6(1)(c) GDPR,
                            Processing is lawful because it is necessary for the
                            performance of a task carried out in the public
                            interest or in the exercise of official authority
                            vested in the controller or because of legal
                            obligation.
                          </p>

                          <h2>Manual identification process</h2>

                          <p>
                            We strongly recommend that you use the electronic
                            identification process for strong identification.
                            However, if you, for some specific reason, are not
                            able to use the electronic identification process,
                            strong identification can be done by using live
                            video and by directly communicating and presenting
                            the documents to the University staff. This is
                            however not recommended since this is much more
                            time-consuming and requires online meeting
                            arrangements, considering also time zones
                            differences.
                          </p>

                          <h3>International students</h3>
                          <p>
                            For additional information, please visit:
                            <br />
                            <a href="https://www.jyu.fi/en/study/new-student-handbook/remote-manual-identification">
                              More information on the manual identification
                              process
                            </a>
                          </p>

                          <h2>Contact and support information</h2>
                          <p>
                            If you have questions about or problems with the
                            identification process, please contact
                            openuniversity@jyu.fi (Open university students) or
                            contact us through the{' '}
                            <a href="https://help.jyu.fi/jp?id=sc_cat_item&sys_id=1a07a9a6c3c195509cedf1177d0131bf">
                              HelpJYU-portal
                            </a>{' '}
                            (other students).
                          </p>
                          <p>
                            <a href="https://www.jyu.fi/digipalvelut/fi/ohjeet/kayttajatunnukset/eid_international_citizens">
                              More information on electronic identification
                            </a>
                          </p>
                        </div>
                        <div className="checkbox-wrapper">
                          <Form.Checkbox
                            className="term-checkbox"
                            label="Yes, I give my consent to the processing of special categories of personal data which is biometric data (facial recognition) for unambiguous identification."
                            checked={formData.acceptTerms || false}
                            onChange={(e, data) => {
                              setFormData({
                                ...formData,
                                acceptTerms: data.checked,
                              });
                            }}
                          />
                          <Form.Checkbox
                            className="term-checkbox"
                            label="Yes, I give my consent to processing the other personal data needed for identification (personal name, personal contact information (address, e-mail, phone number, etc.), national identity number / social security
number. As the solution uses optical verification of identity documents, any data visually available on the ID document will be part of the verification and resulting evidence. This includes a selfie or video sequence of the end user which is used to match with the portrait in the document."
                            checked={formData.acceptMoreTerms || false}
                            onChange={(e, data) => {
                              setFormData({
                                ...formData,
                                acceptMoreTerms: data.checked,
                              });
                            }}
                          />
                        </div>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <StepsNavigation
                  nextStep={['acceptTerms', 'acceptMoreTerms'].reduce(
                    (acc, cur) => acc && !!formData[cur],
                    true,
                  )}
                  onNextStep={onSubmit}
                />
              </Container>
            </div>
          </Segment>
        </Grid.Column>
        <Grid.Column width={3} className="steps-container-wrapper bottom">
          <AuthenticationStepsContainer step={1} subStep={1} />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default AuthenticationSignicatPage;
