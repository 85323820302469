import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const ViewRedirectAuthenticated = (props) => {
  const token = useSelector((state) => state.userSession.token);
  const history = useHistory();
  const currentLang = useSelector((state) => state.intl.locale);

  useEffect(() => {
    if (token) {
      history.push(`/${currentLang}/dashboard`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="redirect-view"></div>;
};

export default ViewRedirectAuthenticated;
