import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Container, Form } from 'semantic-ui-react';
import { countryList, nationalityList } from '@package/helpers';
import StepsNavigation from '@package/components/theme/Identification/StepsNavigation';
import { customValidators } from '@package/helpers/uavalidators';
import ISO6391 from 'iso-639-1';

const messages = defineMessages({
  preferredName: {
    id: 'Preferred name',
    defaultMessage: 'Preferred name',
  },
  phoneNumber: {
    id: 'Phone Number',
    defaultMessage: 'Phone Number',
  },
  phoneNumberType: {
    id: 'phoneNumberType',
    defaultMessage: 'Example: +358 123 1234',
  },
  country: {
    id: 'Country',
    defaultMessage: 'Country',
  },
  street: {
    id: 'Street address',
    defaultMessage: 'Street address',
  },
  city: {
    id: 'City',
    defaultMessage: 'City',
  },
  zipCode: {
    id: 'Zip Code',
    defaultMessage: 'Zip Code',
  },
  gender: {
    id: 'Gender',
    defaultMessage: 'Gender',
  },
  language: {
    id: 'First language',
    defaultMessage: 'First language',
  },
  nationality: {
    id: 'Nationality',
    defaultMessage: 'Nationality',
  },
  helpTitle: {
    id: 'additionalInfoHelpTitle',
    defaultMessage: 'Please enter all additional information',
  },
  helpContent: {
    id: 'additionalInfoHelpContent',
    defaultMessage:
      'Fill in your contact information to continue creating you user account.',
  },
  errorPleaseSelectCountry: {
    id: 'errorPleaseSelectCountry',
    defaultMessage: 'Please select your country',
  },
  errorPleaseSelectLanguage: {
    id: 'errorPleaseSelectLanguage',
    defaultMessage: 'Please select your language',
  },
  errorPleaseSelectNationality: {
    id: 'errorPleaseSelectNationality',
    defaultMessage: 'Please select your nationality',
  },
});

const genderList = [
  {
    value: 'M',
    key: 'm',
    text: 'Male',
  },
  {
    value: 'F',
    key: 'f',
    text: 'Female',
  },
];

const AdditionalInfoStep = ({
  loading,
  finalResult,
  formData,
  setFormData,
  onPrevStep,
  onNextStep,
}) => {
  const intl = useIntl();
  const [errors, setErrors] = React.useState({});
  const [hasErrors, setHasErrors] = React.useState(false);
  const lang = useSelector((state) => state.intl.locale) === 'fi' ? 'fi' : 'en';

  useEffect(() => {
    var newErrors = {
      preferredName: false,
      phone: !formData?.phone
        ? false
        : customValidators.validIntPhoneNumber(formData.phone)?.[lang] ?? null,
      street: !formData?.street
        ? false
        : customValidators.validText(formData.street, 'foreign', 1)?.[lang] ??
          null,
      city: !formData?.city
        ? false
        : customValidators.validText(formData.city, 'foreign', 1)?.[lang] ??
          null,
      zipCode: !formData?.zipCode
        ? false
        : customValidators.validIntPostalCode(formData.zipCode)?.[lang] ?? null,
      country: !formData?.country
        ? intl.formatMessage(messages.errorPleaseSelectCountry)
        : false,
      language: !formData?.language
        ? intl.formatMessage(messages.errorPleaseSelectLanguage)
        : false,
      nationality: !formData?.nationality
        ? intl.formatMessage(messages.errorPleaseSelectNationality)
        : false,
    };
    // in Suomi.fi registration flow, gender is deduced from ssn
    if (formData?.identificationMethod === 'signicat') {
      newErrors = {
        ...newErrors,
        gender: !formData?.gender ? 'Please select your gender' : false,
      };
    }
    setErrors(newErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    setHasErrors(
      Object.keys(errors).reduce(
        (acc, cur) => acc || !!errors[cur] || !formData[cur],
        false,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <div className="reg-step-container">
      <Container>
        <h2>
          <FormattedMessage
            id="Additional Information"
            defaultMessage="Additional Information"
          />
        </h2>
        <div className="description-container">
          <h4 className="description-container-header">
            {intl.formatMessage(messages.helpTitle)}
          </h4>
          <p className="description-container-body">
            {intl.formatMessage(messages.helpContent)}
          </p>
        </div>
        <Form
          id="reg-form"
          loading={loading}
          aria-label="Please add additional information"
        >
          <Form.Group widths={2}>
            <Form.Dropdown
              fluid
              name="preferredName"
              search
              selection
              label={intl.formatMessage(messages.preferredName)}
              options={formData.firstName
                .split(/\s/)
                .filter((name, idx, options) => {
                  return options.indexOf(name) === idx;
                })
                .map((name) => {
                  name = name.replace(/^\s*|\s*$/g, '');
                  return {
                    value: name,
                    key: name,
                    text: name,
                  };
                })}
              value={formData.preferredName}
              onChange={(e, data) => {
                setFormData({
                  ...formData,
                  preferredName: data.value,
                });
              }}
              error={errors.preferredName || false}
            />
            <Form.Input
              fluid
              name="phone"
              label={intl.formatMessage(messages.phoneNumber)}
              placeholder={intl.formatMessage(messages.phoneNumberType)}
              value={formData.phone || ''}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  phone: e.target.value,
                });
              }}
              error={errors['phone'] || false}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Input
              fluid
              name="zipCode"
              label={intl.formatMessage(messages.zipCode)}
              value={formData.zipCode || ''}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  zipCode: e.target.value,
                });
              }}
              error={errors.zipCode || false}
            />
            <Form.Input
              fluid
              name="city"
              label={intl.formatMessage(messages.city)}
              value={formData.city || ''}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  city: e.target.value,
                });
              }}
              error={errors.city || false}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Input
              fluid
              name="street"
              label={intl.formatMessage(messages.street)}
              value={formData.street || ''}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  street: e.target.value,
                });
              }}
              error={errors.street || false}
            />
            <Form.Dropdown
              fluid
              name="country"
              search
              selection
              label={intl.formatMessage(messages.country)}
              placeholder={intl.formatMessage(messages.country)}
              options={countryList}
              value={formData.country || ''}
              onChange={(e, data) => {
                setFormData({
                  ...formData,
                  country: data.value,
                });
              }}
              error={errors.country || false}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Dropdown
              fluid
              name="nationality"
              search
              selection
              disabled={!!finalResult?.nationality}
              label={intl.formatMessage(messages.nationality)}
              placeholder={intl.formatMessage(messages.nationality)}
              options={nationalityList}
              value={formData.nationality || ' '}
              onChange={(e, data) => {
                setFormData({
                  ...formData,
                  nationality: data.value,
                });
              }}
              error={errors.nationality || false}
            />
            <Form.Dropdown
              fluid
              name="language"
              search
              selection
              label={intl.formatMessage(messages.language)}
              placeholder={intl.formatMessage(messages.language)}
              options={ISO6391.getAllCodes().map((code) => ({
                key: code,
                value: code,
                text: ISO6391.getName(code),
              }))}
              value={formData.language || ' '}
              onChange={(e, data) => {
                setFormData({
                  ...formData,
                  language: data.value,
                });
              }}
              error={errors.language || false}
            />
          </Form.Group>
          <Form.Group widths={2}>
            {formData?.identificationMethod === 'signicat' ? (
              <Form.Dropdown
                fluid
                name="gender"
                search
                selection
                disabled={!!finalResult?.gender}
                label={intl.formatMessage(messages.gender)}
                placeholder={intl.formatMessage(messages.gender)}
                options={genderList}
                value={formData.gender || ' '}
                onChange={(e, data) => {
                  setFormData({
                    ...formData,
                    gender: data.value,
                  });
                }}
                error={errors.gender || false}
              />
            ) : (
              ''
            )}
          </Form.Group>
        </Form>
        <StepsNavigation
          nextStep={!hasErrors}
          onPrevStep={onPrevStep}
          onNextStep={onNextStep}
        />
      </Container>
    </div>
  );
};

export default AdditionalInfoStep;
