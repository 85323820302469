import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Form, Grid } from 'semantic-ui-react';
import StepsNavigation from '@package/components/theme/Identification/StepsNavigation';

const TermsStep = ({ formData, setFormData, onNextStep, onPrevStep }) => {
  const currentLang = useSelector((state) => state.intl.locale);

  return (
    <div className="reg-step-container">
      {currentLang === 'en' ? (
        <Container>
          <h2>Terms & Conditions</h2>
          <Form id="reg-form" aria-label="Please check terms and conditions">
            <Grid stackable stretched>
              <Grid.Column width={12}>
                <div className="term-description">
                  <h2> University of Jyväskylä IT usage policy </h2>
                  <p>
                    <a
                      href="https://www.jyu.fi/digipalvelut/en/guides/manuals/data-security/general-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      The whole IT usage policy of the university
                    </a>
                  </p>
                  <p>
                    Policy is also in writing at the service desk of Digital
                    Services. The user license permits using the information
                    systems of the university for tasks relating to studying,
                    doing research, teaching or administration at the
                    university.
                  </p>
                  <h3>Username & Password</h3>
                  <p>
                    The user receives a personal user account upon the granting
                    of the user license. The user license is valid for a fixed
                    period only. All user accounts and related passwords are
                    personal and should never be handed over to other people.
                    The password should be changed regularly even if the system
                    does not force the password to be changed.
                  </p>
                  <h3>Limitations</h3>
                  <p>
                    Using the information systems for any commercial or
                    political use is forbidden. Users must take into account
                    that the law, such as criminal and copyright law, governs
                    the usage of IT. An offender may be subjected to claims for
                    damages. Any harm may not be caused to other users,
                    organizations or systems within the network.
                  </p>
                  <h3>Responsibility</h3>
                  <p>
                    Each user is responsible for any activity done on his/her
                    user account. The user is responsible for protecting his/her
                    files. Responsibility for the retention of research data and
                    other data is ultimately in the hands of the data owner.
                    Breaking the terms of the IT usage policy leads to the loss
                    of the user license (for a fixed period), the loss of the
                    study right (for a fixed period), or criminal liability.
                  </p>
                </div>
                <div className="checkbox-wrapper">
                  <Form.Checkbox
                    className="term-checkbox"
                    label="Accept terms & conditions"
                    checked={formData.acceptTerms || false}
                    onChange={(e, data) => {
                      setFormData({
                        ...formData,
                        acceptTerms: data.checked,
                      });
                    }}
                  />
                </div>
                <div style={{ margin: '1em 0 0 5px' }}>
                  <h2>Contact us</h2>
                  <p>
                    {' '}
                    If you have questions about or problems with the
                    identification process, please contact openuniversity@jyu.fi
                    (Open university students) or{' '}
                    <a href="https://help.jyu.fi/jp?id=sc_cat_item&sys_id=1a07a9a6c3c195509cedf1177d0131bf">
                      contact us through the HelpJYU-portal
                    </a>{' '}
                    (other students).{' '}
                  </p>
                </div>
                <div style={{ margin: '3em 0 0' }}>
                  <Form.Input
                    fluid
                    label="Confirm your new password one more time"
                    placeholder="Password"
                    type="password"
                    value={formData?.twiceConfirmedPassword ?? ''}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        twiceConfirmedPassword: e.target.value,
                      })
                    }
                    error={
                      formData?.twiceConfirmedPassword &&
                      formData.password !== formData.twiceConfirmedPassword
                        ? 'Password does not match'
                        : false
                    }
                  />
                </div>
              </Grid.Column>
            </Grid>
          </Form>
          <StepsNavigation
            nextStep={
              !!formData.acceptTerms &&
              formData?.password === formData?.twiceConfirmedPassword
            }
            onNextStep={onNextStep}
            onPrevStep={onPrevStep}
          />
        </Container>
      ) : (
        <Container>
          <h2>Käyttöehdot</h2>
          <Form id="reg-form" aria-label="Tarkasta käyttöehdot">
            <Grid stackable stretched>
              <Grid.Column width={12}>
                <div className="term-description">
                  <h2>
                    Jyväskylän yliopiston tietojärjestelmien käyttösäännöstö
                  </h2>
                  <p>
                    <a
                      href="https://www.jyu.fi/digipalvelut/fi/ohjeet/tietotekniikan-kayton-ja-yllapidon-saannot"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Jyväskylän yliopiston tietojärjestelmien käyttösäännöstö
                      kokonaisuudessaan
                    </a>
                  </p>
                  <p>
                    Yliopisto tarjoaa opiskelijoiden ja henkilökunnan käyttöön
                    monipuolisen valikoiman tietojärjestelmiä. Niiden käyttö on
                    oleellinen osa opiskelua ja tutkimustyötä. Yliopiston
                    tekemillä säännöillä määrätään, kuinka yliopiston
                    tietojärjestelmiä käytetään ja pidetään yllä.
                  </p>
                  <h3>Tietojärjestelmien käyttövaltuudet</h3>
                  <p>
                    Käyttövaltuus osoitetaan myöntämällä käyttäjätunnus tai
                    tuomalla palvelu saataville. Käyttövaltuudet ovat
                    määräaikaisia. Käyttövaltuus päättyy, kun esimerkiksi
                    henkilön opiskelu päättyy tai määräaikaiseksi myönnetty
                    käyttövaltuus vanhenee.
                  </p>
                  <h3>Käyttäjätunnukset ja salasana</h3>
                  <p>
                    Tietojärjestelmän käyttövaltuus todennetaan
                    käyttäjätunnuksella ja salasanalla. Käyttäjätunnukset ovat
                    henkilökohtaisia eikä käyttäjätunnusta saa luovuttaa toisen
                    henkilön käyttöön. Käyttäjä vastaa kaikesta
                    käyttäjätunnuksellaan tapahtuvasta käytöstä.
                    Käyttäjätunnukset tulee suojata vahvalla salasanalla ja
                    salasana kannattaa vaihtaa säännöllisin väliajoin.
                  </p>
                  <h3>Kielletty käyttö</h3>
                  <p>
                    Tietojärjestelmien tarjoamien palvelujen käyttö kaupalliseen
                    tai poliittiseen toimintaan on kielletty. Tekijänoikeudella
                    suojattuja ohjelmia ei saa käyttää tai kopioida
                    lisenssiehtojen vastaisesti. Tietotekniikkarikkomuksia ovat
                    yliopiston tietojärjestelmien käyttö niiden käytöstä
                    annettujen sääntöjen tai määräysten vastaisesti tai niiden
                    käyttö Suomen lakien vastaisesti.
                  </p>
                  <h3>Vastuu</h3>
                  <p>
                    Käyttäjä vastaa kaikesta hänen käyttövaltuuksillaan tai
                    laitteillaan tehdystä yliopiston tietojärjestelmien
                    käytöstä. Käyttäjän on suojattava käyttäjätunnuksiaan
                    luvattomalta käytöltä. Käyttövaltuutta voidaan rajoittaa,
                    jos on perusteltu epäily sen väärinkäytöstä tai sen käytöstä
                    johtuneesta tietoturvallisuuden vaarantumisesta.
                  </p>
                </div>
                <div className="checkbox-wrapper">
                  <Form.Checkbox
                    className="term-checkbox"
                    label="Hyväksyn käyttöehdot"
                    checked={formData.acceptTerms || false}
                    onChange={(e, data) => {
                      setFormData({
                        ...formData,
                        acceptTerms: data.checked,
                      });
                    }}
                  />
                </div>
                <div style={{ margin: '1em 0 0 5px' }}>
                  <h2>Ota yhteyttä</h2>
                  <p>
                    {' '}
                    Jos sinulla on kysymyksiä tai ongelmia
                    ilmoittautumisprosessiin liittyen,{' '}
                    <a href="https://help.jyu.fi/jp?id=sc_cat_item&sys_id=1a07a9a6c3c195509cedf1177d0131bf">
                      {' '}
                      ota yhteyttä opiskelun palveluihin HelpJYU-palvelun
                      kautta.{' '}
                    </a>
                  </p>
                </div>
                <div style={{ margin: '3em 0 0' }}>
                  <Form.Input
                    fluid
                    label="Vahvista uusi salasanasi vielä kerran"
                    placeholder="Salasana"
                    type="password"
                    value={formData?.twiceConfirmedPassword ?? ''}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        twiceConfirmedPassword: e.target.value,
                      })
                    }
                    error={
                      formData?.twiceConfirmedPassword &&
                      formData.password !== formData.twiceConfirmedPassword
                        ? 'Salasana ei vastaa aiemmin annettua'
                        : false
                    }
                  />
                </div>
              </Grid.Column>
            </Grid>
          </Form>
          <StepsNavigation
            nextStep={
              !!formData.acceptTerms &&
              formData?.password === formData?.twiceConfirmedPassword
            }
            onNextStep={onNextStep}
            onPrevStep={onPrevStep}
          />
        </Container>
      )}
    </div>
  );
};

export default TermsStep;
