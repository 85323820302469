import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Step } from 'semantic-ui-react';

const StepsContainer = ({ step, subStep }) => {
  return (
    <Step.Group id="register-steps" fluid vertical>
      <Step
        order="1"
        active={step === 1 && true}
        completed={step > 1}
        className="sub-container"
      >
        <Step.Content>
          <Step.Title>
            <FormattedMessage
              id="Identification"
              defaultMessage="Identification"
            />
          </Step.Title>
          <Step.Group fluid vertical className="sub-steps">
            <Step active={subStep === 1 && true}>
              <Step.Content>
                <Step.Title>
                  <FormattedMessage
                    id="Identification method"
                    defaultMessage="Identification method"
                  />
                </Step.Title>
              </Step.Content>
            </Step>
            <Step active={subStep === 2 && true}>
              <Step.Content>
                <Step.Title>
                  <FormattedMessage
                    id="Instructions & Initial Data"
                    defaultMessage="Instructions & Initial Data"
                  />
                </Step.Title>
              </Step.Content>
            </Step>
            <Step active={subStep === 3 && true}>
              <Step.Content>
                <Step.Title>
                  <FormattedMessage
                    id="Authentication"
                    defaultMessage="Authentication"
                  />
                </Step.Title>
              </Step.Content>
            </Step>
            <Step active={subStep === 4 && true}>
              <Step.Content>
                <Step.Title>
                  <FormattedMessage
                    id="Check Information"
                    defaultMessage="Check Information"
                  />
                </Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>
        </Step.Content>
      </Step>
      <Step order="2" active={step === 2 && true} completed={step > 2}>
        <Step.Content>
          <Step.Title>
            <FormattedMessage
              id="Additional Information"
              defaultMessage="Additional Information"
            />
          </Step.Title>
        </Step.Content>
      </Step>
      <Step order="3" active={step === 3 && true} completed={step > 3}>
        <Step.Content>
          <Step.Title>
            <FormattedMessage id="Password" defaultMessage="Password" />
          </Step.Title>
        </Step.Content>
      </Step>
      <Step order="4" active={step === 4 && true} completed={step > 4}>
        <Step.Content>
          <Step.Title>
            <FormattedMessage
              id="Terms & Conditions"
              defaultMessage="Terms & Conditions"
            />
          </Step.Title>
        </Step.Content>
      </Step>
    </Step.Group>
  );
};

export default StepsContainer;
