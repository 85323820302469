import request from 'superagent';
import { EPICS_PATH_SIGNICAT_CAPTURE } from './constants';
import config from '@plone/volto/registry';

export const getSignicatFinalResult = async (taskDefinitionKey) => {
  try {
    if (taskDefinitionKey) {
      const { body } = await request
        .get(
          `${config.settings.publicPath}${EPICS_PATH_SIGNICAT_CAPTURE}/${taskDefinitionKey}/finalResult`,
        )
        .set('Accept', 'application/json');
      return body;
    } else {
      const { body } = await request
        .get(
          `${config.settings.publicPath}${EPICS_PATH_SIGNICAT_CAPTURE}/finalResult`,
        )
        .set('Accept', 'application/json');
      return body;
    }
  } catch (e) {
    return {};
  }
};

export const getSignicatMatchResult = async () => {
  try {
    const { body } = await request
      .get(
        `${config.settings.publicPath}${EPICS_PATH_SIGNICAT_CAPTURE}/matchResult`,
      )
      .set('Accept', 'application/json');
    return body;
  } catch (e) {
    return {};
  }
};
