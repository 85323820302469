export const VoltoCancelIcon = require('@plone/volto/icons/clear.svg');
export const VoltoCartIcon = require('@plone/volto/icons/trolley.svg');
export const VoltoContentListing = require('@plone/volto/icons/content-listing.svg');
export const VoltoContinueIcon = require('@plone/volto/icons/ahead.svg');
export const VoltoPhotoIcon = require('@plone/volto/icons/camera.svg');
export const VoltoCircleRightIcon = require('@plone/volto/icons/circle-right.svg');
export const VoltoCircleLeftIcon = require('@plone/volto/icons/circle-left.svg');
export const VoltoBlockIcon = require('@plone/volto/icons/block.svg');
export const VoltoCircleDismissIcon = require('@plone/volto/icons/circle-dismiss.svg');
export const VoltoCheckIcon = require('@plone/volto/icons/check.svg');
export const VoltoConfigurationIcon = require('@plone/volto/icons/configuration.svg');
export const VoltoShowIcon = require('@plone/volto/icons/show.svg');
export const VoltoHideIcon = require('@plone/volto/icons/hide.svg');

export const PortalAccountOutlineIcon = require('./account-circle-outline.svg');
export const PortalAccountCircleIcon = require('./account_circle-24px.svg');
export const PortalAlertIcon = require('./alert-circle-outline.svg');
export const PortalBadgeIcon = require('./badge.svg');
export const PortalCartIcon = require('./shopping-cart.svg');
export const PortalContinueIcon = require('./arrow-right.svg');
export const PortalCourseIcon = require('./course.svg');
export const PortalSignInIcon = require('@package/icons/login2.svg');
export const PortalStarIcon = require('@plone/volto/icons/star.svg');
export const PortalHeaderLogo = require('./jyu_kv_logo.svg');
export const PortalFooterLogo = require('./jyu_logo.svg');
export const PortalLoginPageLogo = require('./jyu_logo3.svg');
export const PortalFacebookIcon = require('./facebook_icon.svg');
export const PortalInstagramIcon = require('./instagram_icon.svg');
export const PortalLinkedInIcon = require('./linkedIn_icon.svg');
export const PortalTwitterIcon = require('./twitter_icon.svg');
export const PortalYoutubeIcon = require('./youtube_icon.svg');
