/**
 * Logo component.
 * @module components/theme/Logo/Logo
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import config from '@plone/volto/registry';
import { Icon } from '@plone/volto/components';
import { PortalHeaderLogo } from '@package/icons';

const Logo = () => {
  const lang = useSelector((state) => state.intl.locale);

  const title = 'ILPO';

  return (
    <>
      <Link
        to={config.settings.isMultilingual ? `/${lang}` : '/'}
        title={title}
        id="lang-logo"
      >
        <div className="logo-logo">
          <Icon name={PortalHeaderLogo} />
        </div>
        <div className="logo-title">
          <span style={{ textAlign: 'center' }}>{title}</span>
        </div>
      </Link>
      <Link to={`/${lang}`} title={title} id="dash-logo">
        <div className="logo-logo">
          <Icon name={PortalHeaderLogo} />
        </div>
        <div className="logo-title">
          <span style={{ textAlign: 'center' }}>{title}</span>
        </div>
      </Link>
    </>
  );
};

export default Logo;
