import React from 'react';

export const EditDashboard = (props) => {
  return (
    <div className="dashboard-edit">
      <div className="placeholder">
        <p> Placeholder for the dashboard, shown only on edit mode </p>
      </div>
    </div>
  );
};

export default EditDashboard;
