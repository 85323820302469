import React, { useEffect } from 'react';
import { Button, Grid, Image, Segment } from 'semantic-ui-react';
import { completeTask, useTask } from 'volto-epics-addon/helpers';
import { Helmet } from '@plone/volto/helpers';
import { TASK_VERIFY_EMAIL } from '@package/constants';
import { useHistory } from 'react-router';
import { EmailVerificationImage } from '@package/images';
import { EPICS_PATH_CONTINUE } from 'volto-epics-addon/constants';
import { defaultTitle } from '@package/helpers';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'emailSentTitle',
    defaultMessage: 'We have sent you an email',
  },
  passwordDescPart1: {
    id: 'emailSentPasswordDesc1',
    defaultMessage: 'Please check the inbox of',
  },
  passwordDescPart2: {
    id: 'emailSentPasswordDesc2',
    defaultMessage: 'for a link to continue to reset your forgotten password.',
  },
  authenticationDescPart1: {
    id: 'emailSentAuthenticationDesc1',
    defaultMessage: 'Your user account needs to be verified to continue.',
  },
  authenticationDescPart2: {
    id: 'emailSentAuthenticationDesc2',
    defaultMessage: 'Please check the inbox of',
  },
  authenticationDescPart3: {
    id: 'emailSentAuthenticationDesc3',
    defaultMessage:
      'for a link to authenticate yourself and verify your user account.',
  },
  registrationDescPart1: {
    id: 'emailSentRegistrationDesc1',
    defaultMessage: 'Please check the inbox of',
  },
  registrationDescPart2: {
    id: 'emailSentRegistrationDesc2',
    defaultMessage: 'for a link to continue the registration.',
  },
  resendText: {
    id: 'emailSentResendText',
    defaultMessage: "If you don't get our mail, please retry with:",
  },
  resendButton: {
    id: 'emailSentResendButton',
    defaultMessage: 'Resend the mail',
  },
  mailBeingSent: {
    id: 'emailSentEmailBeingSent',
    defaultMessage: 'A new email is now being sent...',
  },
});

const EmailVerificationPage = () => {
  const history = useHistory();
  const [resent, setResent] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const task = useTask(TASK_VERIFY_EMAIL);

  const intl = useIntl();

  useEffect(() => {
    setLoading(!task);
    if (task?.completed === true) {
      history.push(EPICS_PATH_CONTINUE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  const onEmailResend = async () => {
    try {
      setResent(true);
      await completeTask(
        TASK_VERIFY_EMAIL,
        {
          token: '',
          resend: true,
        },
        false,
      );
      setTimeout(() => {
        setResent(false);
      }, 10 * 1000);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return (
    <div id="page-enter-email">
      <Helmet title={defaultTitle('Email verification')} />
      <Grid className="email-sent-grid-container" stackable stretched>
        <Grid.Column className="main-row-column">
          <Grid
            stretched
            stackable
            verticalAlign="middle"
            className="main-row-column-grid"
          >
            <Grid.Column width={4} className="image-container">
              <Image src={EmailVerificationImage} height="223px" />
            </Grid.Column>
            <Grid.Column
              width={8}
              textAlign="center"
              verticalAlign="middle"
              className="text-container"
            >
              <div className="content-wrapper">
                <h2>{intl.formatMessage(messages.title)}</h2>
                <Segment basic loading={loading}>
                  {task?.variables?.email ? (
                    task?.variables?.type === 'password' ? (
                      <p>
                        {intl.formatMessage(messages.passwordDescPart1)}{' '}
                        <b>{task.variables.email}</b>{' '}
                        {intl.formatMessage(messages.passwordDescPart2)}
                      </p>
                    ) : task?.variables?.type === 'authentication' ? (
                      <>
                        <p>
                          {intl.formatMessage(messages.authenticationDescPart1)}
                        </p>
                        <p style={{ marginTop: '1em' }}>
                          {intl.formatMessage(messages.authenticationDescPart2)}{' '}
                          <b>{task.variables.email}</b>{' '}
                          {intl.formatMessage(messages.authenticationDescPart3)}
                        </p>
                      </>
                    ) : (
                      <p>
                        {intl.formatMessage(messages.registrationDescPart1)}{' '}
                        <b>{task.variables.email}</b>{' '}
                        {intl.formatMessage(messages.registrationDescPart2)}
                      </p>
                    )
                  ) : null}
                  <p style={{ marginTop: '1em' }}>
                    {intl.formatMessage(messages.resendText)}
                  </p>
                  {resent ? (
                    <p
                      style={{
                        margin: '1em',
                        color: '#c29a5b',
                      }}
                    >
                      {intl.formatMessage(messages.mailBeingSent)}A new email is
                      now being sent…
                    </p>
                  ) : (
                    <Button
                      aria-label={intl.formatMessage(messages.resendButton)}
                      content={intl.formatMessage(messages.resendButton)}
                      onClick={onEmailResend}
                      disabled={resent}
                    />
                  )}
                </Segment>
              </div>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default EmailVerificationPage;
