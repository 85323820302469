/**
 * Register container.
 * @module components/theme/Register/Register
 */

import { defineMessages, useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { Helmet } from '@plone/volto/helpers';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router';
import { Grid, Segment } from 'semantic-ui-react';
import StepsContainer from '@package/components/theme/Registration/StepsContainer';
import { completeTask, useTask } from 'volto-epics-addon/helpers';
import { TASK_REGISTRATION_FORM } from '@package/constants';
import qs from 'query-string';
import PersonalInfoStep from '@package/components/theme/Identification/PersonalInfoStep';
import TermsStep from '@package/components/theme/Registration/TermsStep';
import { EPICS_PATH_CONTINUE } from 'volto-epics-addon/constants';
import {
  getSignicatFinalResult,
  getSignicatMatchResult,
} from 'ilpo-epics-extensions-addon/signicat';
import AdditionalInfoStep from '@package/components/theme/Registration/AdditionalInfoStep';
import PasswordStep from '@package/components/theme/Password/PasswordStep';
import ContinuationToast from 'volto-epics-addon/components/theme/Epics/ContinuationToast';
import { defaultTitle } from '@package/helpers';

const messages = defineMessages({
  register: {
    id: 'Sign up',
    defaultMessage: 'Sign up',
  },
  identityVerified: {
    id: 'identityVerified',
    defaultMessage: 'Identity verified',
  },
  // These are used by the continuation poll
  accountCreationWaiting: {
    id: 'accountCreationWaiting',
    defaultMessage:
      'We are preparing your user account. This may take a few minutes.',
  },
  accountCreationSuccess: {
    id: 'accountCreationSuccess',
    defaultMessage: 'New account created successfully.',
  },
  idmSSNExists: {
    id: 'idmSSNExists',
    defaultMessage:
      'We found out that there already exists an account matching your SSN, and for that reason, you can not register a new account. Please cancel the registration process, and log in with the personal email address you have used previously. If you do not remember the email address, you can look it up at https://account.jyu.fi or contact our support services at https://help.jyu.fi.',
  },
});

const capitalize = (s) => {
  return s
    .toLowerCase()
    .split(/\s/)
    .map((word) =>
      word.length > 1 ? word.charAt(0).toUpperCase() + word.slice(1) : word,
    )
    .join(' ');
};

const RegistrationFormPage = () => {
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();

  const [step, setStep] = useState(1); // No use case for other "cold" step
  const [subStep, setSubStep] = useState(4); // No use case for other "cold" step
  const [stepComponent, setStepComponent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [finalResult, setFinalResult] = useState({});
  const task = useTask(TASK_REGISTRATION_FORM);

  useEffect(() => {
    if (!!task) {
      setLoading(false);
    }
    if (task?.completed === true) {
      history.push(EPICS_PATH_CONTINUE);
    }
    if (task && !task?.variables?.errorMessage) {
      toast.success(
        <ContinuationToast
          success
          title={intl.formatMessage(messages.identityVerified)}
          content={task?.variables?.email}
        />,
        {
          autoClose: true,
        },
      );
    }

    if (task?.variables?.identificationMethod === 'signicat') {
      (async () => {
        const finalResult = await getSignicatFinalResult();
        const matchResult = await getSignicatMatchResult();
        setFormData({
          ...formData,
          ...task.variables,
          ...finalResult,
          firstName: matchResult?.firstName?.partialMatch
            ? matchResult.firstName.sourceValue
            : capitalize(finalResult.firstName),
          lastName: matchResult?.lastName?.partialMatch
            ? matchResult.lastName.sourceValue
            : capitalize(finalResult.lastName),
        });
        setFinalResult(finalResult);
      })();
    } else if (task?.variables?.identificationMethod === 'suomifi') {
      setFormData({
        ...formData,
        ...task.variables,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...(subStep > 1 && { subStep: subStep }),
        step: step,
      }),
      state: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    switch (step) {
      case 1:
        switch (subStep) {
          case 4:
            setStepComponent(
              <PersonalInfoStep
                loading={loading}
                formData={formData}
                setFormData={setFormData}
                task={task}
                onNextStep={() => {
                  setStep(step + 1);
                  setSubStep(0);
                }}
              />,
            );
            break;
          default:
            break;
        }
        break;
      case 2:
        setStepComponent(
          <AdditionalInfoStep
            loading={loading}
            formData={formData}
            finalResult={finalResult}
            setFormData={setFormData}
            onPrevStep={() => {
              setStep(step - 1);
              setSubStep(4);
            }}
            onNextStep={() => setStep(step + 1)}
          />,
        );
        break;
      case 3:
        setStepComponent(
          <PasswordStep
            loading={loading}
            formData={formData}
            setFormData={setFormData}
            onPrevStep={() => setStep(step - 1)}
            onNextStep={() => setStep(step + 1)}
          />,
        );
        break;
      case 4:
        setStepComponent(
          <TermsStep
            loading={loading}
            formData={formData}
            setFormData={setFormData}
            onPrevStep={() => setStep(step - 1)}
            onNextStep={onSubmit}
          />,
        );
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, formData]);

  const onSubmit = async () => {
    setLoading(true);

    var data = {
      nationality: formData.nationality,
      language: formData.language,
      preferredName: formData.preferredName,
      phone: formData.phone,
      street: formData.street,
      city: formData.city,
      zipCode: formData.zipCode,
      country: formData.country,
      password: formData.password,
      confirmedPassword: formData.confirmedPassword,
      acceptTerms: formData.acceptTerms,
    };
    if (formData.gender) {
      data = {
        ...data,
        gender: formData.gender,
      };
    }

    try {
      await completeTask(TASK_REGISTRATION_FORM, data);
    } catch (e) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return (
    <div id="page-register">
      <Helmet title={defaultTitle(intl.formatMessage(messages.register))} />
      <Grid className="grid-container" stackable stretched>
        <Grid.Column width={3} className="steps-container-wrapper top">
          <StepsContainer step={step} subStep={subStep} />
        </Grid.Column>
        <Grid.Column width={9} className="steps-content-wrapper">
          <Segment basic loading={loading}>
            {stepComponent}
          </Segment>
        </Grid.Column>
        <Grid.Column width={3} className="steps-container-wrapper bottom">
          <StepsContainer step={step} subStep={subStep} />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default RegistrationFormPage;
