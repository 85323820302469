import {
  EPICS_PATH_CPU_PAYMENT,
  EPICS_PATH_SIGNICAT_CAPTURE,
  EPICS_PATH_SUOMIFI_CALLBACK,
  EPICS_PATH_VERIFY_EMAIL,
} from './constants';

const applyConfig = (config) => {
  const publicPath = (process.env.PUBLIC_PATH || '').replace(/\/$/, '');
  config.settings = {
    ...config.settings,
    nonContentRoutes: [
      ...config.settings.nonContentRoutes,
      new RegExp(`${publicPath}${EPICS_PATH_VERIFY_EMAIL}/.*`),
      new RegExp(`${publicPath}${EPICS_PATH_SIGNICAT_CAPTURE}/.*`),
      new RegExp(`${publicPath}${EPICS_PATH_SUOMIFI_CALLBACK}/.*`),
      new RegExp(`${publicPath}${EPICS_PATH_CPU_PAYMENT}/.*`),
    ],
  };

  if (__SERVER__) {
    const middleware = require('./middleware');
    config.settings.expressMiddleware = [
      ...config.settings.expressMiddleware,
      middleware.default(publicPath),
    ];
  }

  return config;
};

export default applyConfig;
