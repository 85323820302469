import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Container, Form } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import StepsNavigation from '@package/components/theme/Identification/StepsNavigation';

const messages = defineMessages({
  documentType: {
    id: 'Document type',
    defaultMessage: 'Document type',
  },
  documentNumber: {
    id: 'Document number',
    defaultMessage: 'Document number',
  },
  firstName: {
    id: 'First name',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'Last name',
    defaultMessage: 'Last name',
  },
  birthday: {
    id: 'Birthday',
    defaultMessage: 'Birthday',
  },
  email: {
    id: 'Email',
    defaultMessage: 'Email',
  },
  nationality: {
    id: 'Nationality',
    defaultMessage: 'Nationality',
  },
  gender: {
    id: 'Gender',
    defaultMessage: 'Gender',
  },
  correct: {
    id: 'Information is correct',
    defaultMessage: 'Information is correct',
  },
  incorrect: {
    id: 'Information is not correct',
    defaultMessage: 'Information is not correct',
  },
  helpTitle: {
    id: 'personalInfoHelpTitle',
    defaultMessage: 'Please check your personal information',
  },
  helpContent: {
    id: 'personalInfoHelpContent',
    defaultMessage:
      'The following information was provided by the identity provider. Please check that the information is correct and choose continue.',
  },
});

const PersonalInfoStep = ({
  loading,
  formData,
  task,
  onNextStep,
  onPrevStep,
}) => {
  const intl = useIntl();

  return (
    <div className="reg-step-container">
      <Container>
        <h2>
          <FormattedMessage
            id="Personal Information"
            defaultMessage="Personal Information"
          />
        </h2>
        <div className="description-container">
          <h4 className="description-container-header">
            {intl.formatMessage(messages.helpTitle)}
          </h4>
          <p className="description-container-body">
            {intl.formatMessage(messages.helpContent)}
          </p>
        </div>
        <Form
          id="reg-form"
          loading={loading}
          aria-label="Please check your personal information"
          className="pic-36fc9d76"
        >
          <Form.Group widths="equal">
            <Form.Input
              fluid
              disabled
              label={intl.formatMessage(messages.firstName)}
              placeholder={intl.formatMessage(messages.firstName)}
              value={formData?.['firstName'] ?? ''}
            />
            <Form.Input
              fluid
              disabled
              label={intl.formatMessage(messages.lastName)}
              placeholder={intl.formatMessage(messages.lastName)}
              value={formData?.['lastName'] ?? ''}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              disabled
              label={intl.formatMessage(messages.birthday)}
              placeholder={intl.formatMessage(messages.birthday)}
              value={formData?.['dateOfBirth'] ?? ''}
            />
            <Form.Input
              fluid
              disabled
              label={intl.formatMessage(messages.email)}
              placeholder={intl.formatMessage(messages.email)}
              value={formData?.['email'] ?? ''}
            />
          </Form.Group>
          {task?.identificationMethod === 'signicat' ? (
            <Form.Group widths="equal">
              <Form.Input
                fluid
                disabled
                label={intl.formatMessage(messages.documentType)}
                placeholder={intl.formatMessage(messages.documentType)}
                value={formData?.['documentType'] ?? ' '}
              />
              <Form.Input
                fluid
                disabled
                label={intl.formatMessage(messages.documentNumber)}
                placeholder={intl.formatMessage(messages.documentNumber)}
                value={formData?.['documentNumber'] ?? ' '}
              />
            </Form.Group>
          ) : (
            <div></div>
          )}
        </Form>
        <StepsNavigation onPrevStep={onPrevStep} onNextStep={onNextStep} />
      </Container>
    </div>
  );
};

export default PersonalInfoStep;
