import React, { useState, useEffect } from 'react';
import config from '@plone/volto/registry';
import request from 'superagent';
import zxcvbn from 'zxcvbn';
import { Icon } from '@plone/volto/components';
import { VoltoHideIcon, VoltoShowIcon } from '@package/icons';
import { useIntl } from 'react-intl';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  medium: {
    id: 'Medium',
    defaultMessage: 'Medium',
  },
  strong: {
    id: 'Strong',
    defaultMessage: 'Strong',
  },
  veryStrong: {
    id: 'Very strong',
    defaultMessage: 'Very strong',
  },
});

const PasswordInput = (props) => {
  const [type, setType] = useState(props.show ? 'text' : 'password');
  const [score, setScore] = useState('null');
  const [value, setValue] = useState(props.value || '');
  const [message, setMessage] = useState('');

  const intl = useIntl();

  const showHide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setType(type === 'text' ? 'password' : 'text');
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    props.onChange(e);
  };

  const handleBlur = () => {
    const data = {
      newPassword: props.value || '',
      givenName: props.formData?.preferredName,
      familyName: props.formData?.lastName,
    };

    request
      .post(`${config.settings.publicPath}/validate-password`)
      .send(data)
      .then((res) => {
        if ('message' in res.body) {
          setMessage(res.body.message);
          setScore(0);
          props.onPasswordValidationChange(false);
        } else {
          setScore(zxcvbn(value).score);
          props.onPasswordValidationChange(true);
        }
      });
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (score === 2) {
      setMessage(intl.formatMessage(messages.medium));
    } else if (score === 3) {
      setMessage(intl.formatMessage(messages.strong));
    } else if (score === 4) {
      setMessage(intl.formatMessage(messages.veryStrong));
    }
  }, [score, intl]);

  return (
    <>
      <div className="ui fluid input">
        <input
          type={type}
          className="ui field input"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div
          className="password-show-hide-icon"
          aria-label="Show and hide password"
        >
          <Icon
            name={type === 'text' ? VoltoHideIcon : VoltoShowIcon}
            onClick={showHide}
          />
        </div>
      </div>
      {score !== null && (
        <div className="password-strength-wrapper" data-score={score}>
          <div className="strength-bar" />
          {message && <div className="password-error-message">{message}</div>}
        </div>
      )}
    </>
  );
};

export default PasswordInput;
