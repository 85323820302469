/**
 * Language selector component.
 * @module components/LanguageSelector/LanguageSelector
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import cx from 'classnames';
import { find, map } from 'lodash';

import { Helmet, flattenToAppURL } from '@plone/volto/helpers';

import config from '@plone/volto/registry';

import { defineMessages, useIntl } from 'react-intl';

import Cookies from 'universal-cookie';

const messages = defineMessages({
  switchLanguageTo: {
    id: 'Switch to',
    defaultMessage: 'Switch to',
  },
});

const LanguageSelector = (props) => {
  const intl = useIntl();
  const currentLang = useSelector((state) => state.intl.locale);
  const translations = useSelector(
    (state) => state.content.data?.['@components']?.translations?.items,
  );

  const currentLoc = useSelector((state) => state.router.location.pathname);

  const { settings } = config;

  return settings.isMultilingual ? (
    <div className="language-selector">
      {map(settings.supportedLanguages, (lang) => {
        const translation = find(translations, { language: lang });
        const showName = lang;

        const isNonContent = settings.nonContentRoutes.includes(currentLoc);

        var to;
        if (isNonContent) {
          to = currentLoc;
        } else {
          to = translation ? flattenToAppURL(translation['@id']) : `/${lang}`;
        }

        return (
          <Link
            aria-label={`${intl.formatMessage(
              messages.switchLanguageTo,
            )} ${showName.toLowerCase()}`}
            className={cx({ selected: lang === currentLang })}
            to={to}
            title={showName}
            onClick={() => {
              props.onClickAction(lang, isNonContent);
            }}
            key={`language-selector-${lang}`}
          >
            {showName}
          </Link>
        );
      })}
    </div>
  ) : (
    <Helmet>
      <html lang={settings.defaultLanguage} />
    </Helmet>
  );
};

LanguageSelector.propTypes = {
  onClickAction: PropTypes.func,
};

LanguageSelector.defaultProps = {
  onClickAction: (lang, nonContent) => {
    if (nonContent) {
      const cookies = new Cookies();
      cookies.set('I18N_LANGUAGE', lang, { path: '/' });
      window.location.reload();
    }
  },
};

export default LanguageSelector;
