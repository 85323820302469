import request from 'superagent';
import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  tableOfContents: {
    id: 'Table of Contents',
    defaultMessage: 'Table of Contents',
  },
});

export const defaultTitle = (pageTitle) => {
  return `${pageTitle} — JYU Course Registration`;
};

export function verifyEmailToken(data, callback) {
  request('GET', `/camunda/verify-email/${data.token}`).then((res) => {
    callback(res.body);
  });
}

export const validateEmail = (email) => {
  const regExp =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return email ? regExp.test(email.toLocaleLowerCase()) : false;
};

export const countryList = [
  { value: 'AF', flag: 'af', key: 'af', text: 'Afghanistan', alpha_3: 'AFG' },
  { value: 'AX', flag: 'ax', key: 'ax', text: 'Åland Islands', alpha_3: 'ALA' },
  { value: 'AL', flag: 'al', key: 'al', text: 'Albania', alpha_3: 'ALB' },
  { value: 'DZ', flag: 'dz', key: 'dz', text: 'Algeria', alpha_3: 'DZA' },
  {
    value: 'AS',
    flag: 'as',
    key: 'as',
    text: 'American Samoa',
    alpha_3: 'ASM',
  },
  { value: 'AD', flag: 'ad', key: 'ad', text: 'Andorra', alpha_3: 'AND' },
  { value: 'AO', flag: 'ao', key: 'ao', text: 'Angola', alpha_3: 'AGO' },
  { value: 'AI', flag: 'ai', key: 'ai', text: 'Anguilla', alpha_3: 'AIA' },
  { value: 'AQ', key: 'aq', text: 'Antarctica', alpha_3: 'ATA' },
  {
    value: 'AG',
    flag: 'ag',
    key: 'ag',
    text: 'Antigua and Barbuda',
    alpha_3: 'ATG',
  },
  { value: 'AR', flag: 'ar', key: 'ar', text: 'Argentina', alpha_3: 'ARG' },
  { value: 'AM', flag: 'am', key: 'am', text: 'Armenia', alpha_3: 'ARM' },
  { value: 'AW', flag: 'aw', key: 'aw', text: 'Aruba', alpha_3: 'ABW' },
  { value: 'AU', flag: 'au', key: 'au', text: 'Australia', alpha_3: 'AUS' },
  { value: 'AT', flag: 'at', key: 'at', text: 'Austria', alpha_3: 'AUT' },
  { value: 'AZ', flag: 'az', key: 'az', text: 'Azerbaijan', alpha_3: 'AZE' },
  { value: 'BS', flag: 'bs', key: 'bs', text: 'Bahamas', alpha_3: 'BHS' },
  { value: 'BH', flag: 'bh', key: 'bh', text: 'Bahrain', alpha_3: 'BHR' },
  { value: 'BD', flag: 'bd', key: 'bd', text: 'Bangladesh', alpha_3: 'BGD' },
  { value: 'BB', flag: 'bb', key: 'bb', text: 'Barbados', alpha_3: 'BRB' },
  { value: 'BY', flag: 'by', key: 'by', text: 'Belarus', alpha_3: 'BLR' },
  { value: 'BE', flag: 'be', key: 'be', text: 'Belgium', alpha_3: 'BEL' },
  { value: 'BZ', flag: 'bz', key: 'bz', text: 'Belize', alpha_3: 'BLZ' },
  { value: 'BJ', flag: 'bj', key: 'bj', text: 'Benin', alpha_3: 'BEN' },
  { value: 'BM', flag: 'bm', key: 'bm', text: 'Bermuda', alpha_3: 'BMU' },
  { value: 'BT', flag: 'bt', key: 'bt', text: 'Bhutan', alpha_3: 'BTN' },
  {
    value: 'BO',
    flag: 'bo',
    key: 'bo',
    text: 'Bolivia, Plurinational State of',
    alpha_3: 'BOL',
  },
  {
    value: 'BQ',
    key: 'bq',
    text: 'Bonaire, Sint Eustatius and Saba',
    alpha_3: 'BES',
  },
  {
    value: 'BA',
    flag: 'ba',
    key: 'ba',
    text: 'Bosnia and Herzegovina',
    alpha_3: 'BIH',
  },
  { value: 'BW', flag: 'bw', key: 'bw', text: 'Botswana', alpha_3: 'BWA' },
  { value: 'BV', flag: 'bv', key: 'bv', text: 'Bouvet Island', alpha_3: 'BVT' },
  { value: 'BR', flag: 'br', key: 'br', text: 'Brazil', alpha_3: 'BRA' },
  {
    value: 'IO',
    flag: 'io',
    key: 'io',
    text: 'British Indian Ocean Territory',
    alpha_3: 'IOT',
  },
  {
    value: 'BN',
    flag: 'bn',
    key: 'bn',
    text: 'Brunei Darussalam',
    alpha_3: 'BRN',
  },
  { value: 'BG', flag: 'bg', key: 'bg', text: 'Bulgaria', alpha_3: 'BGR' },
  { value: 'BF', flag: 'bf', key: 'bf', text: 'Burkina Faso', alpha_3: 'BFA' },
  { value: 'BI', flag: 'bi', key: 'bi', text: 'Burundi', alpha_3: 'BDI' },
  { value: 'CV', flag: 'cv', key: 'cv', text: 'Cabo Verde', alpha_3: 'CPV' },
  { value: 'KH', flag: 'kh', key: 'kh', text: 'Cambodia', alpha_3: 'KHM' },
  { value: 'CM', flag: 'cm', key: 'cm', text: 'Cameroon', alpha_3: 'CMR' },
  { value: 'CA', flag: 'ca', key: 'ca', text: 'Canada', alpha_3: 'CAN' },
  {
    value: 'KY',
    flag: 'ky',
    key: 'ky',
    text: 'Cayman Islands',
    alpha_3: 'CYM',
  },
  {
    value: 'CF',
    flag: 'cf',
    key: 'cf',
    text: 'Central African Republic',
    alpha_3: 'CAF',
  },
  { value: 'TD', flag: 'td', key: 'td', text: 'Chad', alpha_3: 'TCD' },
  { value: 'CL', flag: 'cl', key: 'cl', text: 'Chile', alpha_3: 'CHL' },
  { value: 'CN', flag: 'cn', key: 'cn', text: 'China', alpha_3: 'CHN' },
  {
    value: 'CX',
    flag: 'cx',
    key: 'cx',
    text: 'Christmas Island',
    alpha_3: 'CXR',
  },
  {
    value: 'CC',
    flag: 'cc',
    key: 'cc',
    text: 'Cocos (Keeling) Islands',
    alpha_3: 'CCK',
  },
  { value: 'CO', flag: 'co', key: 'co', text: 'Colombia', alpha_3: 'COL' },
  { value: 'KM', flag: 'km', key: 'km', text: 'Comoros', alpha_3: 'COM' },
  { value: 'CG', flag: 'cg', key: 'cg', text: 'Congo', alpha_3: 'COG' },
  {
    value: 'CD',
    flag: 'cd',
    key: 'cd',
    text: 'Congo, Democratic Republic of the',
    alpha_3: 'COD',
  },
  { value: 'CK', flag: 'ck', key: 'ck', text: 'Cook Islands', alpha_3: 'COK' },
  { value: 'CR', flag: 'cr', key: 'cr', text: 'Costa Rica', alpha_3: 'CRI' },
  { value: 'HR', flag: 'hr', key: 'hr', text: 'Croatia', alpha_3: 'HRV' },
  { value: 'CU', flag: 'cu', key: 'cu', text: 'Cuba', alpha_3: 'CUB' },
  { value: 'CW', key: 'cw', text: 'Curaçao', alpha_3: 'CUW' },
  { value: 'CY', flag: 'cy', key: 'cy', text: 'Cyprus', alpha_3: 'CYP' },
  { value: 'CZ', flag: 'cz', key: 'cz', text: 'Czechia', alpha_3: 'CZE' },
  { value: 'CI', flag: 'ci', key: 'ci', text: "Côte d'Ivoire", alpha_3: 'CIV' },
  { value: 'DK', flag: 'dk', key: 'dk', text: 'Denmark', alpha_3: 'DNK' },
  { value: 'DJ', flag: 'dj', key: 'dj', text: 'Djibouti', alpha_3: 'DJI' },
  { value: 'DM', flag: 'dm', key: 'dm', text: 'Dominica', alpha_3: 'DMA' },
  {
    value: 'DO',
    flag: 'do',
    key: 'do',
    text: 'Dominican Republic',
    alpha_3: 'DOM',
  },
  { value: 'EC', flag: 'ec', key: 'ec', text: 'Ecuador', alpha_3: 'ECU' },
  { value: 'EG', flag: 'eg', key: 'eg', text: 'Egypt', alpha_3: 'EGY' },
  { value: 'SV', flag: 'sv', key: 'sv', text: 'El Salvador', alpha_3: 'SLV' },
  {
    value: 'GQ',
    flag: 'gq',
    key: 'gq',
    text: 'Equatorial Guinea',
    alpha_3: 'GNQ',
  },
  { value: 'ER', flag: 'er', key: 'er', text: 'Eritrea', alpha_3: 'ERI' },
  { value: 'EE', flag: 'ee', key: 'ee', text: 'Estonia', alpha_3: 'EST' },
  { value: 'SZ', flag: 'sz', key: 'sz', text: 'Eswatini', alpha_3: 'SWZ' },
  { value: 'ET', flag: 'et', key: 'et', text: 'Ethiopia', alpha_3: 'ETH' },
  {
    value: 'FK',
    flag: 'fk',
    key: 'fk',
    text: 'Falkland Islands (Malvinas)',
    alpha_3: 'FLK',
  },
  { value: 'FO', flag: 'fo', key: 'fo', text: 'Faroe Islands', alpha_3: 'FRO' },
  { value: 'FJ', flag: 'fj', key: 'fj', text: 'Fiji', alpha_3: 'FJI' },
  { value: 'FI', flag: 'fi', key: 'fi', text: 'Finland', alpha_3: 'FIN' },
  { value: 'FR', flag: 'fr', key: 'fr', text: 'France', alpha_3: 'FRA' },
  { value: 'GF', flag: 'gf', key: 'gf', text: 'French Guiana', alpha_3: 'GUF' },
  {
    value: 'PF',
    flag: 'pf',
    key: 'pf',
    text: 'French Polynesia',
    alpha_3: 'PYF',
  },
  {
    value: 'TF',
    flag: 'tf',
    key: 'tf',
    text: 'French Southern Territories',
    alpha_3: 'ATF',
  },
  { value: 'GA', flag: 'ga', key: 'ga', text: 'Gabon', alpha_3: 'GAB' },
  { value: 'GM', flag: 'gm', key: 'gm', text: 'Gambia', alpha_3: 'GMB' },
  { value: 'GE', flag: 'ge', key: 'ge', text: 'Georgia', alpha_3: 'GEO' },
  { value: 'DE', flag: 'de', key: 'de', text: 'Germany', alpha_3: 'DEU' },
  { value: 'GH', flag: 'gh', key: 'gh', text: 'Ghana', alpha_3: 'GHA' },
  { value: 'GI', flag: 'gi', key: 'gi', text: 'Gibraltar', alpha_3: 'GIB' },
  { value: 'GR', flag: 'gr', key: 'gr', text: 'Greece', alpha_3: 'GRC' },
  { value: 'GL', flag: 'gl', key: 'gl', text: 'Greenland', alpha_3: 'GRL' },
  { value: 'GD', flag: 'gd', key: 'gd', text: 'Grenada', alpha_3: 'GRD' },
  { value: 'GP', flag: 'gp', key: 'gp', text: 'Guadeloupe', alpha_3: 'GLP' },
  { value: 'GU', flag: 'gu', key: 'gu', text: 'Guam', alpha_3: 'GUM' },
  { value: 'GT', flag: 'gt', key: 'gt', text: 'Guatemala', alpha_3: 'GTM' },
  { value: 'GG', key: 'gg', text: 'Guernsey', alpha_3: 'GGY' },
  { value: 'GN', flag: 'gn', key: 'gn', text: 'Guinea', alpha_3: 'GIN' },
  { value: 'GW', flag: 'gw', key: 'gw', text: 'Guinea-Bissau', alpha_3: 'GNB' },
  { value: 'GY', flag: 'gy', key: 'gy', text: 'Guyana', alpha_3: 'GUY' },
  { value: 'HT', flag: 'ht', key: 'ht', text: 'Haiti', alpha_3: 'HTI' },
  {
    value: 'HM',
    flag: 'hm',
    key: 'hm',
    text: 'Heard Island and McDonald Islands',
    alpha_3: 'HMD',
  },
  { value: 'VA', flag: 'va', key: 'va', text: 'Holy See', alpha_3: 'VAT' },
  { value: 'HN', flag: 'hn', key: 'hn', text: 'Honduras', alpha_3: 'HND' },
  { value: 'HK', flag: 'hk', key: 'hk', text: 'Hong Kong', alpha_3: 'HKG' },
  { value: 'HU', flag: 'hu', key: 'hu', text: 'Hungary', alpha_3: 'HUN' },
  { value: 'IS', flag: 'is', key: 'is', text: 'Iceland', alpha_3: 'ISL' },
  { value: 'IN', flag: 'in', key: 'in', text: 'India', alpha_3: 'IND' },
  { value: 'ID', flag: 'id', key: 'id', text: 'Indonesia', alpha_3: 'IDN' },
  {
    value: 'IR',
    flag: 'ir',
    key: 'ir',
    text: 'Iran, Islamic Republic of',
    alpha_3: 'IRN',
  },
  { value: 'IQ', flag: 'iq', key: 'iq', text: 'Iraq', alpha_3: 'IRQ' },
  { value: 'IE', flag: 'ie', key: 'ie', text: 'Ireland', alpha_3: 'IRL' },
  { value: 'IM', key: 'im', text: 'Isle of Man', alpha_3: 'IMN' },
  { value: 'IL', flag: 'il', key: 'il', text: 'Israel', alpha_3: 'ISR' },
  { value: 'IT', flag: 'it', key: 'it', text: 'Italy', alpha_3: 'ITA' },
  { value: 'JM', flag: 'jm', key: 'jm', text: 'Jamaica', alpha_3: 'JAM' },
  { value: 'JP', flag: 'jp', key: 'jp', text: 'Japan', alpha_3: 'JPN' },
  { value: 'JE', key: 'je', text: 'Jersey', alpha_3: 'JEY' },
  { value: 'JO', flag: 'jo', key: 'jo', text: 'Jordan', alpha_3: 'JOR' },
  { value: 'KZ', flag: 'kz', key: 'kz', text: 'Kazakhstan', alpha_3: 'KAZ' },
  { value: 'KE', flag: 'ke', key: 'ke', text: 'Kenya', alpha_3: 'KEN' },
  { value: 'KI', flag: 'ki', key: 'ki', text: 'Kiribati', alpha_3: 'KIR' },
  {
    value: 'KP',
    flag: 'kp',
    key: 'kp',
    text: "Korea, Democratic People's Republic of",
    alpha_3: 'PRK',
  },
  {
    value: 'KR',
    flag: 'kr',
    key: 'kr',
    text: 'Korea, Republic of',
    alpha_3: 'KOR',
  },
  { value: 'KW', flag: 'kw', key: 'kw', text: 'Kuwait', alpha_3: 'KWT' },
  { value: 'KG', flag: 'kg', key: 'kg', text: 'Kyrgyzstan', alpha_3: 'KGZ' },
  {
    value: 'LA',
    flag: 'la',
    key: 'la',
    text: "Lao People's Democratic Republic",
    alpha_3: 'LAO',
  },
  { value: 'LV', flag: 'lv', key: 'lv', text: 'Latvia', alpha_3: 'LVA' },
  { value: 'LB', flag: 'lb', key: 'lb', text: 'Lebanon', alpha_3: 'LBN' },
  { value: 'LS', flag: 'ls', key: 'ls', text: 'Lesotho', alpha_3: 'LSO' },
  { value: 'LR', flag: 'lr', key: 'lr', text: 'Liberia', alpha_3: 'LBR' },
  { value: 'LY', flag: 'ly', key: 'ly', text: 'Libya', alpha_3: 'LBY' },
  { value: 'LI', flag: 'li', key: 'li', text: 'Liechtenstein', alpha_3: 'LIE' },
  { value: 'LT', flag: 'lt', key: 'lt', text: 'Lithuania', alpha_3: 'LTU' },
  { value: 'LU', flag: 'lu', key: 'lu', text: 'Luxembourg', alpha_3: 'LUX' },
  { value: 'MO', flag: 'mo', key: 'mo', text: 'Macao', alpha_3: 'MAC' },
  { value: 'MG', flag: 'mg', key: 'mg', text: 'Madagascar', alpha_3: 'MDG' },
  { value: 'MW', flag: 'mw', key: 'mw', text: 'Malawi', alpha_3: 'MWI' },
  { value: 'MY', flag: 'my', key: 'my', text: 'Malaysia', alpha_3: 'MYS' },
  { value: 'MV', flag: 'mv', key: 'mv', text: 'Maldives', alpha_3: 'MDV' },
  { value: 'ML', flag: 'ml', key: 'ml', text: 'Mali', alpha_3: 'MLI' },
  { value: 'MT', flag: 'mt', key: 'mt', text: 'Malta', alpha_3: 'MLT' },
  {
    value: 'MH',
    flag: 'mh',
    key: 'mh',
    text: 'Marshall Islands',
    alpha_3: 'MHL',
  },
  { value: 'MQ', flag: 'mq', key: 'mq', text: 'Martinique', alpha_3: 'MTQ' },
  { value: 'MR', flag: 'mr', key: 'mr', text: 'Mauritania', alpha_3: 'MRT' },
  { value: 'MU', flag: 'mu', key: 'mu', text: 'Mauritius', alpha_3: 'MUS' },
  { value: 'YT', flag: 'yt', key: 'yt', text: 'Mayotte', alpha_3: 'MYT' },
  { value: 'MX', flag: 'mx', key: 'mx', text: 'Mexico', alpha_3: 'MEX' },
  {
    value: 'FM',
    flag: 'fm',
    key: 'fm',
    text: 'Micronesia, Federated States of',
    alpha_3: 'FSM',
  },
  {
    value: 'MD',
    flag: 'md',
    key: 'md',
    text: 'Moldova, Republic of',
    alpha_3: 'MDA',
  },
  { value: 'MC', flag: 'mc', key: 'mc', text: 'Monaco', alpha_3: 'MCO' },
  { value: 'MN', flag: 'mn', key: 'mn', text: 'Mongolia', alpha_3: 'MNG' },
  { value: 'ME', flag: 'me', key: 'me', text: 'Montenegro', alpha_3: 'MNE' },
  { value: 'MS', flag: 'ms', key: 'ms', text: 'Montserrat', alpha_3: 'MSR' },
  { value: 'MA', flag: 'ma', key: 'ma', text: 'Morocco', alpha_3: 'MAR' },
  { value: 'MZ', flag: 'mz', key: 'mz', text: 'Mozambique', alpha_3: 'MOZ' },
  { value: 'MM', flag: 'mm', key: 'mm', text: 'Myanmar', alpha_3: 'MMR' },
  { value: 'NA', flag: 'na', key: 'na', text: 'Namibia', alpha_3: 'NAM' },
  { value: 'NR', flag: 'nr', key: 'nr', text: 'Nauru', alpha_3: 'NRU' },
  { value: 'NP', flag: 'np', key: 'np', text: 'Nepal', alpha_3: 'NPL' },
  { value: 'NL', flag: 'nl', key: 'nl', text: 'Netherlands', alpha_3: 'NLD' },
  { value: 'NC', flag: 'nc', key: 'nc', text: 'New Caledonia', alpha_3: 'NCL' },
  { value: 'NZ', flag: 'nz', key: 'nz', text: 'New Zealand', alpha_3: 'NZL' },
  { value: 'NI', flag: 'ni', key: 'ni', text: 'Nicaragua', alpha_3: 'NIC' },
  { value: 'NE', flag: 'ne', key: 'ne', text: 'Niger', alpha_3: 'NER' },
  { value: 'NG', flag: 'ng', key: 'ng', text: 'Nigeria', alpha_3: 'NGA' },
  { value: 'NU', flag: 'nu', key: 'nu', text: 'Niue', alpha_3: 'NIU' },
  {
    value: 'NF',
    flag: 'nf',
    key: 'nf',
    text: 'Norfolk Island',
    alpha_3: 'NFK',
  },
  {
    value: 'MK',
    flag: 'mk',
    key: 'mk',
    text: 'North Macedonia',
    alpha_3: 'MKD',
  },
  {
    value: 'MP',
    flag: 'mp',
    key: 'mp',
    text: 'Northern Mariana Islands',
    alpha_3: 'MNP',
  },
  { value: 'NO', flag: 'no', key: 'no', text: 'Norway', alpha_3: 'NOR' },
  { value: 'OM', flag: 'om', key: 'om', text: 'Oman', alpha_3: 'OMN' },
  { value: 'PK', flag: 'pk', key: 'pk', text: 'Pakistan', alpha_3: 'PAK' },
  { value: 'PW', flag: 'pw', key: 'pw', text: 'Palau', alpha_3: 'PLW' },
  {
    value: 'PS',
    flag: 'ps',
    key: 'ps',
    text: 'Palestine, State of',
    alpha_3: 'PSE',
  },
  { value: 'PA', flag: 'pa', key: 'pa', text: 'Panama', alpha_3: 'PAN' },
  {
    value: 'PG',
    flag: 'pg',
    key: 'pg',
    text: 'Papua New Guinea',
    alpha_3: 'PNG',
  },
  { value: 'PY', flag: 'py', key: 'py', text: 'Paraguay', alpha_3: 'PRY' },
  { value: 'PE', flag: 'pe', key: 'pe', text: 'Peru', alpha_3: 'PER' },
  { value: 'PH', flag: 'ph', key: 'ph', text: 'Philippines', alpha_3: 'PHL' },
  { value: 'PN', flag: 'pn', key: 'pn', text: 'Pitcairn', alpha_3: 'PCN' },
  { value: 'PL', flag: 'pl', key: 'pl', text: 'Poland', alpha_3: 'POL' },
  { value: 'PT', flag: 'pt', key: 'pt', text: 'Portugal', alpha_3: 'PRT' },
  { value: 'PR', flag: 'pr', key: 'pr', text: 'Puerto Rico', alpha_3: 'PRI' },
  { value: 'QA', flag: 'qa', key: 'qa', text: 'Qatar', alpha_3: 'QAT' },
  { value: 'RO', flag: 'ro', key: 'ro', text: 'Romania', alpha_3: 'ROU' },
  {
    value: 'RU',
    flag: 'ru',
    key: 'ru',
    text: 'Russian Federation',
    alpha_3: 'RUS',
  },
  { value: 'RW', flag: 'rw', key: 'rw', text: 'Rwanda', alpha_3: 'RWA' },
  { value: 'RE', flag: 're', key: 're', text: 'Réunion', alpha_3: 'REU' },
  { value: 'BL', key: 'bl', text: 'Saint Barthélemy', alpha_3: 'BLM' },
  {
    value: 'SH',
    flag: 'sh',
    key: 'sh',
    text: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha_3: 'SHN',
  },
  {
    value: 'KN',
    flag: 'kn',
    key: 'kn',
    text: 'Saint Kitts and Nevis',
    alpha_3: 'KNA',
  },
  { value: 'LC', flag: 'lc', key: 'lc', text: 'Saint Lucia', alpha_3: 'LCA' },
  {
    value: 'MF',
    key: 'mf',
    text: 'Saint Martin (French part)',
    alpha_3: 'MAF',
  },
  {
    value: 'PM',
    flag: 'pm',
    key: 'pm',
    text: 'Saint Pierre and Miquelon',
    alpha_3: 'SPM',
  },
  {
    value: 'VC',
    flag: 'vc',
    key: 'vc',
    text: 'Saint Vincent and the Grenadines',
    alpha_3: 'VCT',
  },
  { value: 'WS', flag: 'ws', key: 'ws', text: 'Samoa', alpha_3: 'WSM' },
  { value: 'SM', flag: 'sm', key: 'sm', text: 'San Marino', alpha_3: 'SMR' },
  {
    value: 'ST',
    flag: 'st',
    key: 'st',
    text: 'Sao Tome and Principe',
    alpha_3: 'STP',
  },
  { value: 'SA', flag: 'sa', key: 'sa', text: 'Saudi Arabia', alpha_3: 'SAU' },
  { value: 'SN', flag: 'sn', key: 'sn', text: 'Senegal', alpha_3: 'SEN' },
  { value: 'RS', flag: 'rs', key: 'rs', text: 'Serbia', alpha_3: 'SRB' },
  { value: 'SC', flag: 'sc', key: 'sc', text: 'Seychelles', alpha_3: 'SYC' },
  { value: 'SL', flag: 'sl', key: 'sl', text: 'Sierra Leone', alpha_3: 'SLE' },
  { value: 'SG', flag: 'sg', key: 'sg', text: 'Singapore', alpha_3: 'SGP' },
  { value: 'SX', key: 'sx', text: 'Sint Maarten (Dutch part)', alpha_3: 'SXM' },
  { value: 'SK', flag: 'sk', key: 'sk', text: 'Slovakia', alpha_3: 'SVK' },
  { value: 'SI', flag: 'si', key: 'si', text: 'Slovenia', alpha_3: 'SVN' },
  {
    value: 'SB',
    flag: 'sb',
    key: 'sb',
    text: 'Solomon Islands',
    alpha_3: 'SLB',
  },
  { value: 'SO', flag: 'so', key: 'so', text: 'Somalia', alpha_3: 'SOM' },
  { value: 'ZA', flag: 'za', key: 'za', text: 'South Africa', alpha_3: 'ZAF' },
  {
    value: 'GS',
    flag: 'gs',
    key: 'gs',
    text: 'South Georgia and the South Sandwich Islands',
    alpha_3: 'SGS',
  },
  { value: 'SS', key: 'ss', text: 'South Sudan', alpha_3: 'SSD' },
  { value: 'ES', flag: 'es', key: 'es', text: 'Spain', alpha_3: 'ESP' },
  { value: 'LK', flag: 'lk', key: 'lk', text: 'Sri Lanka', alpha_3: 'LKA' },
  { value: 'SD', flag: 'sd', key: 'sd', text: 'Sudan', alpha_3: 'SDN' },
  { value: 'SR', flag: 'sr', key: 'sr', text: 'Suriname', alpha_3: 'SUR' },
  {
    value: 'SJ',
    flag: 'sj',
    key: 'sj',
    text: 'Svalbard and Jan Mayen',
    alpha_3: 'SJM',
  },
  { value: 'SE', flag: 'se', key: 'se', text: 'Sweden', alpha_3: 'SWE' },
  { value: 'CH', flag: 'ch', key: 'ch', text: 'Switzerland', alpha_3: 'CHE' },
  {
    value: 'SY',
    flag: 'sy',
    key: 'sy',
    text: 'Syrian Arab Republic',
    alpha_3: 'SYR',
  },
  {
    value: 'TW',
    flag: 'tw',
    key: 'tw',
    text: 'Taiwan, Province of China',
    alpha_3: 'TWN',
  },
  { value: 'TJ', flag: 'tj', key: 'tj', text: 'Tajikistan', alpha_3: 'TJK' },
  {
    value: 'TZ',
    flag: 'tz',
    key: 'tz',
    text: 'Tanzania, United Republic of',
    alpha_3: 'TZA',
  },
  { value: 'TH', flag: 'th', key: 'th', text: 'Thailand', alpha_3: 'THA' },
  { value: 'TL', flag: 'tl', key: 'tl', text: 'Timor-Leste', alpha_3: 'TLS' },
  { value: 'TG', flag: 'tg', key: 'tg', text: 'Togo', alpha_3: 'TGO' },
  { value: 'TK', flag: 'tk', key: 'tk', text: 'Tokelau', alpha_3: 'TKL' },
  { value: 'TO', flag: 'to', key: 'to', text: 'Tonga', alpha_3: 'TON' },
  {
    value: 'TT',
    flag: 'tt',
    key: 'tt',
    text: 'Trinidad and Tobago',
    alpha_3: 'TTO',
  },
  { value: 'TN', flag: 'tn', key: 'tn', text: 'Tunisia', alpha_3: 'TUN' },
  { value: 'TR', flag: 'tr', key: 'tr', text: 'Turkey', alpha_3: 'TUR' },
  { value: 'TM', flag: 'tm', key: 'tm', text: 'Turkmenistan', alpha_3: 'TKM' },
  {
    value: 'TC',
    flag: 'tc',
    key: 'tc',
    text: 'Turks and Caicos Islands',
    alpha_3: 'TCA',
  },
  { value: 'TV', flag: 'tv', key: 'tv', text: 'Tuvalu', alpha_3: 'TUV' },
  { value: 'UG', flag: 'ug', key: 'ug', text: 'Uganda', alpha_3: 'UGA' },
  { value: 'UA', flag: 'ua', key: 'ua', text: 'Ukraine', alpha_3: 'UKR' },
  {
    value: 'AE',
    flag: 'ae',
    key: 'ae',
    text: 'United Arab Emirates',
    alpha_3: 'ARE',
  },
  {
    value: 'GB',
    flag: 'gb',
    key: 'gb',
    text: 'United Kingdom',
    alpha_3: 'GBR',
  },
  {
    value: 'UM',
    flag: 'um',
    key: 'um',
    text: 'United States Minor Outlying Islands',
    alpha_3: 'UMI',
  },
  { value: 'US', flag: 'us', key: 'us', text: 'United States', alpha_3: 'USA' },
  { value: 'UY', flag: 'uy', key: 'uy', text: 'Uruguay', alpha_3: 'URY' },
  { value: 'UZ', flag: 'uz', key: 'uz', text: 'Uzbekistan', alpha_3: 'UZB' },
  { value: 'VU', flag: 'vu', key: 'vu', text: 'Vanuatu', alpha_3: 'VUT' },
  {
    value: 'VE',
    flag: 've',
    key: 've',
    text: 'Venezuela, Bolivarian Republic of',
    alpha_3: 'VEN',
  },
  { value: 'VN', flag: 'vn', key: 'vn', text: 'Viet Nam', alpha_3: 'VNM' },
  {
    value: 'VG',
    flag: 'vg',
    key: 'vg',
    text: 'Virgin Islands, British',
    alpha_3: 'VGB',
  },
  {
    value: 'VI',
    flag: 'vi',
    key: 'vi',
    text: 'Virgin Islands, U.S.',
    alpha_3: 'VIR',
  },
  {
    value: 'WF',
    flag: 'wf',
    key: 'wf',
    text: 'Wallis and Futuna',
    alpha_3: 'WLF',
  },
  {
    value: 'EH',
    flag: 'eh',
    key: 'eh',
    text: 'Western Sahara',
    alpha_3: 'ESH',
  },
  { value: 'YE', flag: 'ye', key: 'ye', text: 'Yemen', alpha_3: 'YEM' },
  { value: 'ZM', flag: 'zm', key: 'zm', text: 'Zambia', alpha_3: 'ZMB' },
  { value: 'ZW', flag: 'zw', key: 'zw', text: 'Zimbabwe', alpha_3: 'ZWE' },
];

export const nationalityList = countryList.map((country) => ({
  key: country.alpha_3,
  value: country.alpha_3,
  text: country.text,
}));
