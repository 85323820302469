import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { defineMessages } from 'react-intl';
import { Container, Button } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { completeTask, useTask } from 'volto-epics-addon/helpers';
import { TASK_APPLICATION_VERIFY_CONTACT_INFORMATION } from '@package/constants';
import { countryList } from '@package/helpers';

const messages = defineMessages({
  title: {
    id: 'verifyContactInformationTitle',
    defaultMessage: 'Verify contact information.',
  },
  description: {
    id: 'verifyContactInformationDescription',
    defaultMessage:
      'Please check that your contact information are correct and up to date.',
  },
  informationCorrect: {
    id: 'verifyContactInformationCorrectButton',
    defaultMessage: 'My information is correct',
  },
  name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  email: {
    id: 'emailAddress',
    defaultMessage: 'Email',
  },
  phone: {
    id: 'Phone',
    defaultMessage: 'Phone',
  },
  address: {
    id: 'address',
    defaultMessage: 'Address',
  },
});

const VerifyContactInformation = () => {
  const intl = useIntl();
  const [info, setInfo] = useState({
    name: '',
    email: '',
    phone: '',
    streeAddress: '',
    cityName: '',
    postalCode: '',
    countryCode: '',
  });
  const task = useTask(TASK_APPLICATION_VERIFY_CONTACT_INFORMATION);
  const lang = useSelector((state) => state.intl.locale);

  useEffect(() => {
    if (task) {
      const name =
        (task?.variables?.firstName || '') +
        ' ' +
        (task?.variables?.lastName || '');
      const values = {
        name: name,
        email: task?.variables?.email || '',
        phone: task?.variables?.phone || '',
        streetAddress: task?.variables?.streetAddress || '',
        cityName: task?.variables?.cityName || '',
        postalCode: task?.variables?.postalCode || '',
        countryCode: task?.variables?.countryCode || '',
      };
      setInfo(values);
    }
  }, [task]);

  const onCorrect = async () => {
    try {
      await completeTask(TASK_APPLICATION_VERIFY_CONTACT_INFORMATION);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return (
    <div className="application-verify-information">
      <Container>
        <div className="application-verify-information-container">
          <div className="title-description-container">
            <div className="title-container">
              <h2>{intl.formatMessage(messages.title)}</h2>
            </div>
            <div className="description-container">
              <p>{intl.formatMessage(messages.description)}</p>
            </div>
          </div>
          <div className="contact-information-container">
            <table>
              <tr>
                <td>
                  <strong>{intl.formatMessage(messages.name)}:</strong>
                </td>
                <td>{info.name}</td>
              </tr>
              <tr>
                <td>
                  <strong>{intl.formatMessage(messages.email)}:</strong>
                </td>
                <td>{info.email}</td>
              </tr>
              <tr>
                <td>
                  <strong>{intl.formatMessage(messages.phone)}:</strong>
                </td>
                <td>{info.phone}</td>
              </tr>
              <tr>
                <td>
                  <strong>{intl.formatMessage(messages.address)}:</strong>
                </td>
                <td>
                  {info.streetAddress}
                  <br />
                  {info.postalCode} {info.cityName}
                  <br />
                  {info.countryCode &&
                    countryList.filter((c) => c.key === info.countryCode)?.[0]
                      ?.text}
                </td>
              </tr>
            </table>
          </div>
          <hr></hr>
          <div className="help-container">
            {lang === 'fi' ? (
              <p>
                Jos yhteystietosi eivät ole ajan tasalla, keskeytä hakuprosessi,
                mene {'https://account.jyu.fi'}, päivitä puuttuvat /
                vanhentuneet tiedot, ja aloita alusta.
              </p>
            ) : (
              <p>
                If your contact information is not up to date, you should cancel
                the application process, go to {'https://account.jyu.fi'},
                update the missing / outdated information, and start again.
              </p>
            )}
          </div>
          <div className="buttons-container">
            <Button onClick={onCorrect}>
              {intl.formatMessage(messages.informationCorrect)}
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default VerifyContactInformation;
