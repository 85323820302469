import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Card, Container, Grid, Step } from 'semantic-ui-react';
import { Api, Helmet } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import jwtDecode from 'jwt-decode';
import {
  PortalSignInIcon,
  PortalStarIcon,
  VoltoCartIcon,
  VoltoContentListing,
} from '@package/icons';
import { TASK_CHECKOUT_CONFIRMATION_COURSE } from '@package/constants';
import { completeTask, useTask } from 'volto-epics-addon/helpers';
import { BarLoader } from 'react-spinners';
import moment from 'moment';
import PortalDefaultCardBg from './default_card_bg.svg';
import { Link } from 'react-router-dom';
import config from '@plone/volto/registry';
import { defaultTitle } from '@package/helpers';

const messages = defineMessages({
  title: {
    id: 'Course registration',
    defaultMessage: 'Course registration',
  },
  thankyou: {
    id: 'checkoutThankYou',
    defaultMessage: 'Thank you for your purchase',
  },
  newCourseTitle: {
    id: 'checkoutNewCourseTitle',
    defaultMessage: 'Start your studies',
  },
  newCourseDesc1: {
    id: 'checkoutNewCourseDesc1',
    defaultMessage:
      'The course has been added to your courses. You can access the course information by clicking the name of the course on the',
  },
  newCourseDesc1Link: {
    id: 'checkoutNewCourseDesc1Link',
    defaultMessage: 'Dashboard.',
  },
  newCourseDesc2: {
    id: 'checkoutNewCourseDesc2',
    defaultMessage:
      'Your JYU user ID is displayed on your course information. Always use your user ID to sign into the e-learning environment',
  },
  newCourseDashboardLink: {
    id: 'checkoutNewCourseDashboardLink',
    defaultMessage: 'Back to dashboard',
  },
  groupRegisterDesc1: {
    id: 'groupRegisterDesc',
    defaultMessage:
      'If you have registered for the course, which includes demo groups/labs/home groups, you have to register next in Sisu using these instructions: ',
  },
  groupRegisterLink1: {
    id: 'groupRegisterLink1',
    defaultMessage:
      'https://www.jyu.fi/digipalvelut/en/guides/for-new-student/sisu-instructions-for-new-students',
  },
  groupRegisterLinkText1: {
    id: 'groupRegisterLinkText1',
    defaultMessage:
      'Sisu instructions for new students — Digital Services (jyu.fi)',
  },
  groupRegisterDesc2: {
    id: 'groupRegisterDesc2',
    defaultMessage: ' ',
  },
  groupRegisterLink2: {
    id: 'groupRegisterLink2',
    defaultMessage: ' ',
  },
  groupRegisterLinkText2: {
    id: 'groupRegisterLinkText2',
    defaultMessage: ' ',
  },
  groupRegisterDesc3: {
    id: 'groupRegisterDesc3',
    defaultMessage:
      "If you have problems with registration, don't hesitate to get in touch with the Study services: ",
  },
  groupRegisterLink3: {
    id: 'groupRegisterLink3',
    defaultMessage: 'https://help.jyu.fi',
  },
  groupRegisterLinkText3: {
    id: 'groupRegisterLinkText3',
    defaultMessage: 'https://help.jyu.fi',
  },
  studentEmailConfirmedTitle: {
    id: 'studentEmailConfirmedTitle',
    defaultMessage: 'Student email address confirmation',
  },
  studentEmailConfirmedText1: {
    id: 'studentEmailConfirmedText1',
    defaultMessage:
      'Please confirm your student email address via the {omaLink} to start your studies.',
  },
  studentEmailConfirmedText2: {
    id: 'studentEmailConfirmedText2',
    defaultMessage:
      'Log in to the OMA service with your university username ({username}) and password. You can confirm your student email address on the User account and email page of the OMA service.',
  },
  studentEmailConfirmedText3: {
    id: 'studentEmailConfirmedText3',
    defaultMessage:
      'After confirming your student email, you will be able to access the course material within 1-2 hours.',
  },
});

const dateRegex =
  /\((?:19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])\s\u2013\s(?:19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])\)$/;
// eslint-disable-next-line no-useless-escape
const nameRegex = /^[A-Za-zа-яА-ЯÀ-ú0-9\-\s]+\:\s/;

const CheckoutConfirmationCoursePage = () => {
  const intl = useIntl();
  const { token } = useSelector((state) => state.userSession);
  const currentLang = useSelector((state) => state.intl.locale);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [registeredUsername, setRegisteredUsername] = useState('');
  const [enrolledState, setEnrolledState] = useState('');
  const task = useTask(TASK_CHECKOUT_CONFIRMATION_COURSE);
  const [counter, setCounter] = useState(0);
  const [studentEmailConfirmed, setStudentEmailConfirmed] = useState(true);
  const [showGroupInstructions, setShowGroupInstructions] = useState(false);

  useEffect(() => {
    if (!!task) {
      setLoading(false);
    }
  }, [task, setLoading]);

  useEffect(() => {
    if (!token || !task) {
      return;
    }

    const api = new Api();

    let username;
    try {
      username = jwtDecode(token).sub;
    } catch (e) {}

    (async () => {
      if (username) {
        const path = `/students/${username.replace('@', '-40')}`;
        const thisCourse = task.variables.course.jsonld_id;
        try {
          const response = await api.get('/@search', {
            params: {
              portal_type: 'Enrolment',
              fullobjects: 1,
              path,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          for (const item of response['items']) {
            const enrolledCourse = item?.course?.['@id'];

            function getShort(path) {
              try {
                const pathArray = path.split('/');
                return pathArray[pathArray.length - 1];
              } catch {
                return '';
              }
            }

            if (
              getShort(thisCourse) &&
              getShort(enrolledCourse) &&
              getShort(thisCourse) === getShort(enrolledCourse)
            ) {
              setRegisteredUsername(username.split('@')[0]);
              setEnrolledState(item.review_state);

              // Get the group_registration information.
              const courseUrl = enrolledCourse.replace(
                config.settings.publicURL,
                config.settings.publicURL + '/++api++',
              );
              const courseResponse = await api.get(courseUrl, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  Accept: `application/json`,
                },
              });
              if ('group_registration' in courseResponse) {
                setShowGroupInstructions(courseResponse['group_registration']);
              }
            }
          }
          if (enrolledState !== 'enrolled') {
            setTimeout(() => {
              setCounter(counter + 1);
            }, 3000);
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, task, counter]);

  useEffect(() => {
    // Conditionally instruct the student to confirm email address
    if (task?.variables?.studentEmailConfirmed === false) {
      setStudentEmailConfirmed(false);
    }

    // Complete on view
    if (task?.completed === false && completed === false) {
      setCompleted(true);
      (async () => {
        await completeTask(TASK_CHECKOUT_CONFIRMATION_COURSE, {}, false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  // get content path by splitting at the language root folder
  const courseId = task?.variables?.course?.jsonld_id || '/';
  const match = courseId.match(/(\/[a-z]{2}\/)(.*)/);
  const courseUrl = match ? match[0] : '/';

  return (
    <div id="page-signup">
      <Helmet title={defaultTitle(intl.formatMessage(messages.title))} />
      <Container>
        <div>
          <Step.Group className="signup-steps" fluid size="mini" unstackable>
            <Step completed>
              <Icon name={PortalSignInIcon} />
            </Step>
            <Step completed>
              <Icon name={VoltoContentListing} />
            </Step>
            <Step completed>
              <Icon name={VoltoCartIcon} />
            </Step>
            <Step active>
              <Icon name={PortalStarIcon} />
            </Step>
          </Step.Group>
        </div>
        <div>
          <h2
            style={{
              fontFamily: 'Aleo',
              fontSize: '2.7em',
              textAlign: 'center',
              marginTop: '2em',
              marginBottom: '1.5em',
              fontWeight: 'bold',
            }}
          >
            {intl.formatMessage(messages.thankyou)}
          </h2>
          <div className="page-signup-content">
            <Grid stretched stackable>
              <Grid.Row verticalAlign="middle" columns="equal">
                <Grid.Column width={7}>
                  {!studentEmailConfirmed && (
                    <>
                      <h2>
                        {intl.formatMessage(
                          messages.studentEmailConfirmedTitle,
                        )}
                      </h2>
                      <p>
                        <strong>
                          {intl.formatMessage(
                            messages.studentEmailConfirmedText1,
                            {
                              omaLink:
                                currentLang === 'fi' ? (
                                  <a
                                    href="https://account.jyu.fi"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    OMA-palvelussa
                                  </a>
                                ) : (
                                  <a
                                    href="https://account.jyu.fi"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    OMA service
                                  </a>
                                ),
                            },
                          )}
                        </strong>
                      </p>
                      <p>
                        {intl.formatMessage(
                          messages.studentEmailConfirmedText2,
                          { username: registeredUsername },
                        )}
                      </p>
                      <p>
                        {intl.formatMessage(
                          messages.studentEmailConfirmedText3,
                        )}
                      </p>
                    </>
                  )}
                  <h2>{intl.formatMessage(messages.newCourseTitle)}</h2>
                  <p>
                    {intl.formatMessage(messages.newCourseDesc1)}{' '}
                    <a href={`/${currentLang}`}>
                      {intl.formatMessage(messages.newCourseDesc1Link)}
                    </a>
                  </p>
                  <p>{intl.formatMessage(messages.newCourseDesc2)}</p>
                  {showGroupInstructions && (
                    <p>
                      {intl.formatMessage(messages.groupRegisterDesc1)}
                      <a href={intl.formatMessage(messages.groupRegisterLink1)}>
                        {intl.formatMessage(messages.groupRegisterLinkText1)}
                      </a>
                      {intl.locale === 'fi' && (
                        <>
                          {intl.formatMessage(messages.groupRegisterDesc2)}
                          <a
                            href={intl.formatMessage(
                              messages.groupRegisterLink2,
                            )}
                          >
                            {intl.formatMessage(
                              messages.groupRegisterLinkText2,
                            )}
                          </a>
                        </>
                      )}

                      {intl.formatMessage(messages.groupRegisterDesc3)}
                      <a href={intl.formatMessage(messages.groupRegisterLink3)}>
                        {intl.formatMessage(messages.groupRegisterLinkText3)}
                      </a>
                    </p>
                  )}
                </Grid.Column>
                <Grid.Column width={5}>
                  {registeredUsername ? (
                    <div className="course-cards-list-template">
                      <Card
                        className={
                          enrolledState !== 'enrolled' ? 'disabled' : ''
                        }
                        fluid
                        as={Link}
                        to={courseUrl}
                      >
                        {enrolledState !== 'enrolled' && (
                          <div className="checkout-loader-wrapper">
                            <div className="checkout-loader-content">
                              <p>Wait for registration to complete.</p>
                              <div className="barloader-wrapper">
                                <BarLoader width={200} />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="course-cards-header">
                          <div
                            className="course-cards-image"
                            style={{
                              backgroundImage: `url(${PortalDefaultCardBg})`,
                            }}
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1440 320"
                          >
                            <path
                              fill="#F6F7F7"
                              fillOpacity="1"
                              d="M0,224L80,234.7C160,245,320,267,480,266.7C640,267,800,245,960,213.3C1120,181,1280,139,1360,117.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
                            ></path>
                          </svg>
                        </div>
                        <Card.Content>
                          <div className="course-cards-title">
                            {task?.variables?.course?.title
                              .replace(nameRegex, '')
                              .replace(dateRegex, '')}
                          </div>
                        </Card.Content>
                        <Card.Content extra>
                          <Grid>
                            <Grid.Row columns={2}>
                              <Grid.Column className="course-cards-meta"></Grid.Column>
                              <Grid.Column
                                verticalAlign="bottom"
                                className="course-cards-date"
                              >
                                <div>
                                  {moment(
                                    task?.variables?.course?.course_start_date,
                                  ).format('D.M.')}
                                  {'–'}
                                  {moment(
                                    task?.variables?.course?.course_end_date,
                                  ).format('D.M.Y')}
                                </div>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Card.Content>
                      </Card>
                    </div>
                  ) : (
                    <div className="barloader-wrapper">
                      <BarLoader width={200} />
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CheckoutConfirmationCoursePage;
