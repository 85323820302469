import React from 'react';

export const EditRedirectAuthenticated = (props) => {
  return (
    <div className="redirect-edit">
      <div className="placeholder">
        <p> Placeholder for the redirect block, shown only on edit mode </p>
      </div>
    </div>
  );
};

export default EditRedirectAuthenticated;
