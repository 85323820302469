/**
 * LRF view that shows a dashboard for logged-in and welcome splash screen for anonymous.
 * @module components/theme/View/LRFView
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { defineMessages, useIntl } from 'react-intl';
import Dashboard from '@package/components/manage/Blocks/Dashboard/View';

const messages = defineMessages({
  frontpageTitle: {
    id: 'frontpageTitle',
    defaultMessage: 'ILPO - Registration portal',
  },
  frontpageSubtitle: {
    id: 'frontpageSubtitle',
    defaultMessage: 'Registration portal',
  },
  frontpageText1: {
    id: 'frontpageText1',
    defaultMessage:
      'Welcome to the registration portal of continuous learning at the University of Jyväskylä!',
  },
  frontpageText2: {
    id: 'frontpageText2',
    defaultMessage:
      'Here you can create a JYU user ID and register for the Open University studies in English as well as some of the separate studies offered by the faculties of the University of Jyväskylä.',
  },
});

const LRFView = (props) => {
  const { token } = useSelector((state) => state.userSession);
  const currentLang = useSelector((state) => state.intl.locale);
  const intl = useIntl();

  return (
    <Container id="lrf-view">
      {token ? (
        <>
          <Dashboard />
        </>
      ) : (
        <>
          <div id="frontpage-cover-container" className="grid-container">
            <div className="Info">
              <h1>{intl.formatMessage(messages.frontpageTitle)}</h1>
              <h2>ILPO</h2>
              <h3>{intl.formatMessage(messages.frontpageSubtitle)}</h3>
              <p>{intl.formatMessage(messages.frontpageText1)}</p>
              <p>{intl.formatMessage(messages.frontpageText2)}</p>
              {currentLang === 'fi' ? (
                <a href="/fi/courses" className="cover-button">
                  Selaa opintojaksoja
                </a>
              ) : (
                <a href="/en/courses" className="cover-button">
                  Browse courses
                </a>
              )}
            </div>
            <div className="image-field"></div>
            <div className="links-section">
              {currentLang === 'fi' ? (
                <>
                  <p>
                    <a href="https://www.avoin.jyu.fi/opinto-opas/fi/avoinyo">
                      Avoimen yliopiston koko opintotarjonta
                    </a>
                  </p>
                  <p>
                    <a href="https://www.jyu.fi/fi/opiskelijalle/opiskeluoikeudet/erillinen-opiskeluoikeus">
                      Lisätietoa erillisistä opinto-oikeuksista
                    </a>
                  </p>
                </>
              ) : (
                <>
                  <p>
                    <a href="https://www.avoin.jyu.fi/en/courses-offered">
                      All studies offered at the Open University
                    </a>
                  </p>
                  <p>
                    <a href="https://www.jyu.fi/en/study/study-rights/separate-study-right">
                      Further information on separate study rights
                    </a>
                  </p>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default withRouter(LRFView);
