import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Form } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import { validateEmail } from '@package/helpers';
import { PortalAlertIcon } from '@package/icons';

const defaultPrompt = (
  <FormattedMessage
    id="Please enter your email"
    defaultMessage="Please enter your email"
  />
);

const EmailInputContainer = ({
  email,
  onChange,
  onSubmit: onSubmitCallback,
  prompt = defaultPrompt,
}) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    if (email && !validateEmail(email)) {
      setError('Invalid email address');
      setLoading(false);
    } else {
      setError('');
      await onSubmitCallback();
      setLoading(false);
    }
  };

  return (
    <Container>
      <div style={{ padding: '3em 0 4em 0' }}>
        <h3>{prompt}</h3>
        <Form loading={loading} className="enter-email-form">
          <Form.Input
            fluid
            focus
            placeholder="jane.doe@example.com"
            value={email}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
          {error && (
            <div className="email-input-error">
              <Icon name={PortalAlertIcon} size="20px" />
              <span style={{ padding: '0 0.2em' }}>{error}</span>
            </div>
          )}
          <Form.Button fluid disabled={!email} onClick={onSubmit}>
            <FormattedMessage id="Next" defaultMessage="Next" />
          </Form.Button>
        </Form>
      </div>
    </Container>
  );
};

export default EmailInputContainer;
