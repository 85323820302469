import React, { useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from '@plone/volto/helpers';
import { Button, Form, Grid, Input, Segment } from 'semantic-ui-react';
import { PortalAlertIcon, PortalLoginPageLogo } from '@package/icons';
import { defaultTitle, validateEmail } from '@package/helpers';
import { Icon } from '@plone/volto/components';

const messages = defineMessages({
  title: {
    id: 'Sign in',
    defaultMessage: 'Sign in',
  },
  login: {
    id: 'Log in',
    defaultMessage: 'Log in',
  },
  register: {
    id: 'Register',
    defaultMessage: 'Register',
  },
  cancel: {
    id: 'Cancel',
    defaultMessage: 'Cancel',
  },
  emailAddress: {
    id: 'Email address',
    defaultMessage: 'address@example.com',
  },
  registerBeforeProceed: {
    id: 'registerBeforeProceed',
    defaultMessage:
      "If you don't already have a JYU user ID, please register before you proceed.",
  },
  welcome: {
    id: 'Welcome',
    defaultMessage: 'Welcome',
  },
});

const EmailInputContainer = ({
  loading,
  email,
  onChange,
  onSubmit: onSubmitCallback,
  onCancel,
  onRegister,
  error: errorProp,
}) => {
  const intl = useIntl();
  const [canceling, setCanceling] = useState(false);
  const [registering, setRegistering] = useState(false);
  const [error, setError] = useState(errorProp);

  const onSubmit = async () => {
    if (email && !validateEmail(email)) {
      setError('Invalid email address');
    } else {
      setError('');
      await onSubmitCallback();
    }
  };

  return (
    <div id="main-login-page">
      <Helmet title={defaultTitle(intl.formatMessage(messages.login))} />
      <div className="login-container">
        <div className="login-logo">
          <Icon name={PortalLoginPageLogo} size="80px" color="#012957" />
        </div>
        <div>
          <div className="email-login-title">
            <h1>
              {intl.formatMessage(messages.welcome)}
              {'!'}
            </h1>
          </div>
          <Form onSubmit={onSubmit}>
            <Segment.Group>
              <Segment.Group
                style={{
                  margin: '0 auto',
                  maxWidth: '400px',
                }}
              >
                <Segment className="email-login-message">
                  <FormattedMessage
                    id="Please enter your email address:"
                    defaultMessage="Please enter your email address:"
                  />
                </Segment>
                <Segment>
                  <Form.Field inline>
                    <Grid>
                      <Grid.Row stretched>
                        <Grid.Column>
                          <Input
                            id="email"
                            name="email"
                            placeholder={intl.formatMessage(
                              messages.emailAddress,
                            )}
                            aria-label={intl.formatMessage(
                              messages.emailAddress,
                            )}
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus
                            value={email}
                            error={!!error}
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                          />
                          {error && (
                            <div className="email-input-error">
                              <Icon name={PortalAlertIcon} size="20px" />
                              <span style={{ padding: '0 0.2em' }}>
                                {error}
                              </span>
                            </div>
                          )}
                          {onRegister && (
                            <div className="email-input-error">
                              <span
                                style={{ color: '#012957', padding: '0 0.2em' }}
                              >
                                {intl.formatMessage(
                                  messages.registerBeforeProceed,
                                )}
                              </span>
                            </div>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form.Field>
                </Segment>
                <Segment className="login-button-container" clearing>
                  <Grid stretched>
                    <Grid.Row>
                      {onCancel && (
                        <Grid.Column width={6}>
                          <Button
                            style={{
                              background: 'none',
                              color: '#012957',
                              margin: '0',
                            }}
                            className="additional-button"
                            primary
                            type="button"
                            id="login-form-cancel"
                            aria-label={intl.formatMessage(messages.cancel)}
                            title={intl.formatMessage(messages.cancel)}
                            disabled={loading && !registering && !canceling}
                            loading={loading && canceling}
                            onClick={async (e) => {
                              setCanceling(true);
                              await onCancel();
                            }}
                          >
                            {intl.formatMessage(messages.cancel)}
                          </Button>
                        </Grid.Column>
                      )}
                      {onRegister && (
                        <Grid.Column width={6}>
                          <Button
                            style={{
                              background: 'none',
                              color: '#012957',
                              margin: '0',
                            }}
                            className="additional-button"
                            primary
                            type="button"
                            id="login-form-register"
                            aria-label={intl.formatMessage(messages.register)}
                            title={intl.formatMessage(messages.register)}
                            disabled={loading && !registering && !canceling}
                            loading={loading && registering}
                            onClick={(e) => {
                              setRegistering(true);
                              onRegister(e);
                            }}
                          >
                            {intl.formatMessage(messages.register)}
                          </Button>
                        </Grid.Column>
                      )}
                      <Grid.Column width={6}>
                        <Button
                          primary
                          type="submit"
                          id="login-form-next"
                          aria-label={intl.formatMessage(messages.login)}
                          title={intl.formatMessage(messages.login)}
                          loading={loading && !registering && !canceling}
                          disabled={!email || !onSubmitCallback}
                          style={{ margin: '0', border: '1px solid #012957' }}
                        >
                          {intl.formatMessage(messages.login)}
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Segment.Group>
            </Segment.Group>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EmailInputContainer;
