import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Container, Grid } from 'semantic-ui-react';
import { Helmet } from '@plone/volto/helpers';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTask } from 'volto-epics-addon/helpers';
import { TASK_PROCEED_TO_PAY } from '@package/constants';
import { EPICS_PATH_CONTINUE } from 'volto-epics-addon/constants';
import { defaultTitle } from '@package/helpers';
import { BarLoader } from 'react-spinners';

const messages = defineMessages({
  title: {
    id: 'Registration',
    defaultMessage: 'Registration',
  },
  redirectMessage: {
    id: 'redirectMessage',
    defaultMessage: 'Please wait while you are being redirected...',
  },
});

const CheckoutRedirectPage = () => {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const task = useTask(TASK_PROCEED_TO_PAY);

  const intl = useIntl();
  const history = useHistory();

  useEffect(() => {
    if (!!task) {
      setLoading(false);
    }
    if (
      task?.taskDefinitionKey === TASK_PROCEED_TO_PAY &&
      task?.completed === false &&
      task?.variables?.paymentAddress
    ) {
      window.location = task.variables.paymentAddress;
    }

    if (task?.completed === true) {
      history.push(EPICS_PATH_CONTINUE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  return (
    <>
      <Helmet title={defaultTitle(intl.formatMessage(messages.title))} />
      <Container
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <Grid stackable centered stretched verticalAlign="middle">
          <Grid.Row>
            <Grid.Column
              className="grid-column-content"
              width={8}
              textAlign="center"
            >
              <h2>
                <FormattedMessage
                  id="Electronic payment"
                  defaultMessage="Electronic payment"
                />
              </h2>
              <p>{intl.formatMessage(messages.redirectMessage)}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              className="grid-column-content"
              width={8}
              textAlign="center"
              id="bar-loader-column"
            >
              <BarLoader width={200} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
};

export default CheckoutRedirectPage;
