import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Grid } from 'semantic-ui-react';
import { AsyncPaginate } from 'react-select-async-paginate';

import {
  Option,
  DropdownIndicator,
  selectTheme,
  customSelectStyles as customSelectStyles_,
} from '@plone/volto/components/manage/Widgets/SelectStyling';

export const customSelectStyles = {
  ...customSelectStyles_,
  option: (styles, state) => ({
    ...customSelectStyles_.option(styles, state),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
};

const SisuField = ({
  id,
  title,
  description = null,
  required = false,
  error = [],
  value = '',
  onChange,
  formData,
  fieldSet,
  fieldDeps,
  sisuGetQuery,
  sisuSearchQuery,
  isMultiValue,
}) => {
  const [choices, setChoices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState(() => {
    if (isMultiValue && value) {
      return value.map((elem) => ({ label: elem, value: elem }));
    }
    return value ? { label: value, value: value } : null;
  });

  useEffect(() => {
    if (selectedOption === null) {
      return;
    }
    if (isMultiValue) {
      setSelectedOption(
        selectedOption.map((option) =>
          option.label === option.value
            ? choices.find((choice) => option.value === choice.value) || option
            : option,
        ),
      );
    } else {
      setSelectedOption(
        choices.find((choice) => selectedOption?.value === choice.value) ||
          selectedOption,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choices]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (choices.length === 0) {
          if (isMultiValue && value) {
            const choicesPromises = value.map((elem) => sisuGetQuery(elem));
            const resolvedChoices = await Promise.all(choicesPromises);
            setChoices(resolvedChoices.flat());
          } else if (value) {
            const res = await sisuGetQuery(value);
            setChoices(res || []);
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error resolving choices:', error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const loadOptions = async (search, _, additional) => {
    const offset = search !== searchTerm ? 0 : additional.offset;
    const fieldDepsData = Object.fromEntries(
      Object.entries(fieldDeps).map(([key, value]) => [value, formData?.[key]]),
    );
    try {
      const newChoices =
        (await sisuSearchQuery(search, offset, fieldDepsData)) || [];
      setSearchTerm(search);
      setChoices(newChoices);
      return {
        options: newChoices,
        hasMore: newChoices.length >= 1,
        additional: { offset: newChoices.length ? offset + 10 : offset },
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error loading options:', error);
      return {
        options: [],
        hasMore: false,
        additional: { offset },
      };
    }
  };

  const handleChange = (selectedOption) => {
    if (isMultiValue) {
      setSelectedOption(selectedOption);
      onChange(
        id,
        selectedOption ? selectedOption.map((item) => item.value) : null,
      );
    } else {
      const option = Array.isArray(selectedOption)
        ? selectedOption[0]
        : selectedOption;
      setSelectedOption(option);
      onChange(id, option ? option.value : '');
    }
  };

  return (
    <Form.Field
      inline
      required={required}
      error={error.length > 0}
      className={'sisu-widget ' + (description ? 'help' : '')}
      id={`${fieldSet || 'field'}-${id}`}
    >
      <Grid>
        <Grid.Row stretched>
          <Grid.Column width="4">
            <div className="wrapper">
              <label htmlFor={`field-${id}`}>{title}</label>
            </div>
          </Grid.Column>
          <Grid.Column width="8">
            <AsyncPaginate
              className="react-select-container"
              classNamePrefix="react-select"
              options={choices}
              styles={customSelectStyles}
              theme={selectTheme}
              components={{ DropdownIndicator, Option }}
              isMulti={true}
              value={selectedOption || null}
              loadOptions={loadOptions}
              onChange={handleChange}
              debounceTimeout={300}
              additional={{
                offset: 0,
              }}
            />
          </Grid.Column>
        </Grid.Row>
        {description && (
          <Grid.Row stretched>
            <Grid.Column stretched width="12">
              <p className="help">{description}</p>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Form.Field>
  );
};

SisuField.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  sisuGetQuery: PropTypes.func.isRequired,
  sisuSearchQuery: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  fieldDeps: PropTypes.object,
  isMultiValue: PropTypes.bool,
  formData: PropTypes.object,
  fieldSet: PropTypes.string,
};

const widgetConstructor = (
  sisuGetQuery,
  sisuSearchQuery,
  fieldDeps,
  isMultiValue,
) => {
  return (props) => (
    <SisuField
      {...props}
      sisuGetQuery={sisuGetQuery}
      sisuSearchQuery={sisuSearchQuery}
      fieldDeps={fieldDeps}
      isMultiValue={isMultiValue}
    />
  );
};

export default widgetConstructor;
