/**
 * Anontools component.
 * @module components/theme/Anontools/Anontools
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import ButtonLink from '@package/components/theme/Button/ButtonLink';
import { EPIC_SIGN_IN } from '@package/constants';
import { startEpic } from 'volto-epics-addon/helpers';

/**
 * Anontools container class.
 * @class Anontools
 * @extends Component
 */
class Anontools extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string,
    content: PropTypes.shape({
      '@id': PropTypes.string,
    }),
    lang: PropTypes.string.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
    content: {
      '@id': null,
    },
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { content } = this.props;
    return (
      !this.props.token && (
        <Menu pointing secondary floated="right">
          <Menu.Item>
            <ButtonLink
              className="anontools-login-button"
              aria-label="Sign in"
              onClick={async () => {
                await startEpic(EPIC_SIGN_IN, {
                  contentUid: content.UID,
                });
              }}
            >
              <FormattedMessage id="Sign in" defaultMessage="Sign in" />
            </ButtonLink>
          </Menu.Item>
        </Menu>
      )
    );
  }
}

export default compose(
  injectIntl,
  connect((state) => ({
    token: state.userSession.token,
    lang: state.intl.locale,
    content: state.content.data,
  })),
)(Anontools);
