import { defineMessages, useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { Helmet } from '@plone/volto/helpers';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router';
import { Grid, Segment } from 'semantic-ui-react';
import { completeTask, useTask } from 'volto-epics-addon/helpers';
import { TASK_ACCOUNT_AUTHENTICATION_FORM } from '@package/constants';
import { EPICS_PATH_CONTINUE } from 'volto-epics-addon/constants';
import qs from 'query-string';
import PersonalInfoStep from '@package/components/theme/Identification/PersonalInfoStep';
import ContinuationToast from 'volto-epics-addon/components/theme/Epics/ContinuationToast';
import AuthenticationStepsContainer from '@package/components/theme/Authentication/AuthenticationStepsContainer';
import { getSignicatFinalResult } from 'ilpo-epics-extensions-addon/signicat';
import { defaultTitle } from '@package/helpers';

const messages = defineMessages({
  title: {
    id: 'Identity verification',
    defaultMessage: 'Identity verification',
  },
  identityVerified: {
    id: 'identityVerified',
    defaultMessage: 'Identity verified',
  },
});

const AuthenticationFormPage = () => {
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();

  // eslint-disable-next-line no-unused-vars
  const [step, setStep] = useState(2); // No use case for other "cold" step
  const [stepComponent, setStepComponent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [finalResult, setFinalResult] = useState({});
  const task = useTask(TASK_ACCOUNT_AUTHENTICATION_FORM);

  useEffect(() => {
    if (!!task) {
      setLoading(false);
    }
    if (task?.completed === true) {
      history.push(EPICS_PATH_CONTINUE);
    }
    if (task && task?.variables?.password) {
      setFormData({ ...formData, password: task.variables.password });
    }
    if (task && !task?.variables?.errorMessage) {
      toast.success(
        <ContinuationToast
          success
          title={intl.formatMessage(messages.identityVerified)}
          content={task?.variables?.email}
        />,
        {
          autoClose: true,
        },
      );
    }
    if (task?.variables?.identificationMethod === 'signicat') {
      (async () => {
        const finalResult = await getSignicatFinalResult(
          TASK_ACCOUNT_AUTHENTICATION_FORM,
        );
        setFormData({
          ...formData,
          ...task.variables,
          ...finalResult,
        });
        setFinalResult(finalResult);
      })();
    } else if (task?.variables?.identificationMethod === 'suomifi') {
      setFormData({
        ...formData,
        ...task.variables,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        step: step,
      }),
      state: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    switch (step) {
      case 2:
        setStepComponent(
          <PersonalInfoStep
            loading={loading}
            formData={formData}
            setFormData={setFormData}
            onNextStep={onSubmit}
          />,
        );
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, formData]);

  const onSubmit = async () => {
    setLoading(true);
    try {
      await completeTask(TASK_ACCOUNT_AUTHENTICATION_FORM, {});
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setLoading(false);
    }
  };

  return (
    <div id="page-register">
      <Helmet title={defaultTitle(intl.formatMessage(messages.title))} />
      <Grid className="grid-container" stackable stretched>
        <Grid.Column width={3} className="steps-container-wrapper top">
          <AuthenticationStepsContainer step={step} />
        </Grid.Column>
        <Grid.Column width={9} className="steps-content-wrapper">
          <Segment basic loading={loading}>
            {stepComponent}
          </Segment>
        </Grid.Column>
        <Grid.Column width={3} className="steps-container-wrapper bottom">
          <AuthenticationStepsContainer step={step} />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default AuthenticationFormPage;
