import React, { useState, useEffect } from 'react';
import { getBaseUrl } from '@plone/volto/helpers';
import CustomApplicationForm from '@package/components/manage/Form/CustomApplicationForm';
import { concat, filter, map, last } from 'lodash';
import { connect } from 'react-redux';
import { Button, Container } from 'semantic-ui-react';
import { find } from 'lodash';
import { defineMessages, useIntl } from 'react-intl';
import {
  updateContent,
  transitionWorkflow,
  getWorkflow,
  getContent,
  listActions,
} from '@plone/volto/actions';
import { withRouter } from 'react-router';
import { S3FileWidgetContext } from 'volto-s3-widget-addon/components/manage/Widgets/S3FileWidget/S3FileWidget';

/* Does rudimentary validation on data */
function validateInputs(schema, data) {
  const errors = {};

  // check if required fields are present and non-empty in data
  (schema.required || []).forEach((field) => {
    if (!data[field]) {
      errors[field] = 'required';
    }
  });

  return errors;
}

const messages = defineMessages({
  application: {
    id: 'Application',
    defaultMessage: 'Application',
  },
  saveAsDraft: {
    id: 'saveAsDraft',
    defaultMessage: 'Save as draft',
  },
  saveAsFinished: {
    id: 'saveAsFinished',
    defaultMessage: 'Save as finished',
  },
  save: {
    id: 'save',
    defaultMessage: 'Save',
  },
  backToDashboard: {
    id: 'backToDashboard',
    defaultMessage: 'Back to dashboard',
  },
});

const ApplicationEditForm = (props) => {
  const [title, setTitle] = useState('');
  const [applicationData, setApplicationData] = useState(null);
  const [applicationSchema, setApplicationSchema] = useState(null);
  const [workflowState, setWorkflowState] = useState('');
  const [validInputs, setValidInputs] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    // Ensure up to date workflow information
    props.getWorkflow(getBaseUrl(props.location.pathname));

    // Ensure up to date content information
    props.getContent(getBaseUrl(props.location.pathname));

    // Ensure actions
    props.listActions(getBaseUrl(props.location.pathname));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.content?.module?.title) {
      const moduleTitle = props.content.module.title;
      setTitle(intl.formatMessage(messages.application) + ' - ' + moduleTitle);
    }

    const applicationDataJson = props.content.application_data;
    const applicationSchemaJson = props.content.application_schema;

    if (applicationDataJson && applicationSchemaJson) {
      const applicationData = JSON.parse(applicationDataJson);
      const applicationSchema = JSON.parse(applicationSchemaJson);

      setApplicationData(applicationData);
      setApplicationSchema(applicationSchema);

      // Set `validInputs` based on inputs.
      const errors = validateInputs(applicationSchema, applicationData);
      if (Object.keys(errors).length === 0) {
        setValidInputs(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.content]);

  useEffect(() => {
    // Set workflow state.
    if (props?.workflow?.currentState?.id) {
      setWorkflowState(props.workflow.currentState.id);
    }
  }, [props.workflow]);

  const onChange = (id, value) => {
    // Update data
    const newApplicationData = {
      ...applicationData,
      [id]: value,
    };
    setApplicationData(newApplicationData);

    // Set `validInputs` based on inputs.
    const errors = validateInputs(applicationSchema, newApplicationData);
    if (Object.keys(errors).length === 0) {
      setValidInputs(true);
    } else {
      setValidInputs(false);
    }
  };

  /* Saves the data unvalidated and without changing the state */
  const onSubmitDraft = () => {
    const newApplicationDataJson = JSON.stringify(applicationData);
    const contentPath = props.location.pathname.replace('/edit', '');
    props
      .updateContent(contentPath, {
        application_data: newApplicationDataJson,
      })
      .then((newContent) => {
        props.history.push(contentPath);
      });
  };

  /* Saves the data 'permanently' with validation and state transition */
  const onSubmitFinished = () => {
    // Just to make sure, validate inputs once more.
    const errors = validateInputs(applicationSchema, applicationData);
    if (!(Object.keys(errors).length === 0)) {
      return;
    }

    const newApplicationDataJson = JSON.stringify(applicationData);
    const contentPath = props.location.pathname.replace('/edit', '');

    const transitionUrl = filter(
      map(concat(props.workflow.transitions), (item) => item['@id']),
      (x) => last(x.split('/')) === 'draft_to_finished',
    );

    props
      .transitionWorkflow(transitionUrl, false)
      .then(() => {
        props.updateContent(contentPath, {
          application_data: newApplicationDataJson,
        });
      })
      .then(() => {
        props.history.push(contentPath);
      });
  };

  const onBackToDashboard = () => {
    const dashboardPath = `/${props.lang}`;
    props.history.push(dashboardPath);
  };

  const editPermission = find(props.objectActions, { id: 'edit' });

  return (
    (applicationSchema != null && applicationData != null && (
      <Container id="application-edit-view">
        <h2>{title}</h2>
        <S3FileWidgetContext.Provider
          value={{ replaceOnly: workflowState !== 'draft' }}
        >
          <CustomApplicationForm
            readOnly={!['draft', 'finished'].includes(workflowState)}
            schema={applicationSchema}
            onChangeField={onChange}
            formData={applicationData}
          />
        </S3FileWidgetContext.Provider>
        <div className="buttons-container">
          {workflowState === 'draft' && editPermission && validInputs && (
            <>
              <Button
                onClick={onSubmitDraft}
                role="button"
                aria-label="Save draft"
                type="button"
              >
                {intl.formatMessage(messages.saveAsDraft)}
              </Button>
              <Button
                onClick={onSubmitFinished}
                role="button"
                aria-label="Save finished"
                type="button"
              >
                {intl.formatMessage(messages.saveAsFinished)}
              </Button>
            </>
          )}
          {workflowState === 'draft' && editPermission && !validInputs && (
            <>
              <Button
                onClick={onSubmitDraft}
                role="button"
                aria-label="Save draft"
                type="button"
              >
                {intl.formatMessage(messages.saveAsDraft)}
              </Button>
              <Button
                disabled={true}
                role="button"
                aria-label="Save finished"
                type="button"
              >
                {intl.formatMessage(messages.saveAsFinished)}
              </Button>
            </>
          )}
          {workflowState === 'finished' && editPermission && validInputs && (
            <>
              <Button
                onClick={onSubmitFinished}
                role="button"
                aria-label="Save"
                type="button"
              >
                {intl.formatMessage(messages.save)}
              </Button>
            </>
          )}
          {workflowState === 'finished' && editPermission && !validInputs && (
            <>
              <Button
                disabled={true}
                role="button"
                aria-label="Save"
                type="button"
              >
                {intl.formatMessage(messages.save)}
              </Button>
            </>
          )}
          <Button
            onClick={onBackToDashboard}
            role="button"
            aria-label="Back to Dashboard"
            type="button"
          >
            {intl.formatMessage(messages.backToDashboard)}
          </Button>
        </div>
      </Container>
    )) ||
    null
  );
};

const mapStateToProps = (state) => ({
  content: state.content.data,
  workflow: state.workflow,
  lang: state.intl.locale,
  objectActions: state.actions.actions.object,
});
const mapDispatchToProps = (dispatch) => ({
  updateContent: (url, data) => dispatch(updateContent(url, data)),
  getWorkflow: (url) => dispatch(getWorkflow(url)),
  getContent: (url) => dispatch(getContent(url)),
  transitionWorkflow: (url, include_children) =>
    dispatch(transitionWorkflow(url, include_children)),
  listActions: (url) => dispatch(listActions(url)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApplicationEditForm),
);
