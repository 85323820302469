import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { EPICS_PATH_CONTINUE } from 'volto-epics-addon/constants';
import { TASK_EMAIL_LOGIN } from '@package/constants';
import { cancelEpic, completeTask, useTask } from 'volto-epics-addon/helpers';
import EmailInputContainer from '@package/components/theme/Login/EmailInputContainer';

const Page = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const task = useTask(TASK_EMAIL_LOGIN);

  useEffect(() => {
    if (task?.completed === true) {
      setLoading(true);
      history.push(EPICS_PATH_CONTINUE);
    } else {
      if (!email) {
        setEmail(task?.variables?.email ?? '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  const onSubmit = async () => {
    setLoading(true);
    try {
      await completeTask(TASK_EMAIL_LOGIN, { email });
    } catch (e) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return (
    <EmailInputContainer
      loading={loading}
      setLoading={setLoading}
      email={email}
      onSubmit={onSubmit}
      onChange={setEmail}
      onCancel={async () => {
        setLoading(true);
        cancelEpic();
      }}
    />
  );
};

export default Page;
