import React from 'react';
import DefaultAdd from '@package/components/manage/Add/DefaultAdd';
import config from '@plone/volto/registry';
import qs from 'query-string';

const CustomAdd = (props) => {
  const contentTypesAdds = config.settings.contentTypesAdds;

  const { location } = props;
  const params = qs.parse(location.search);
  const contentType = params.type;

  // Check we have defined a custom Add view for this content type
  if (Object.keys(contentTypesAdds).includes(contentType)) {
    const Component = contentTypesAdds[contentType];
    return <Component {...props} />;
  }

  // Otherwise, carry on as normal.
  return <DefaultAdd {...props} />;
};

export default CustomAdd;
