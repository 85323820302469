import React from 'react';
import PropTypes from 'prop-types';
import defaultSVG from './default.svg';
import moment from 'moment';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Card, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  Card_message_private: {
    id: 'Cards_message_private',
    defaultMessage: 'Private',
  },

  Card_message_coming: {
    id: 'Card_message_coming',
    defaultMessage: 'Coming soon',
  },

  Card_message_open: {
    id: 'Card_message_open',
    defaultMessage: 'Open',
  },

  Card_message_closed: {
    id: 'Card_message_closed',
    defaultMessage: 'Closed',
  },

  registrationInterval: {
    id: 'registrationInterval',
    defaultMessage: 'Registration:',
  },
});

const CourseListingTemplate = ({ items }) => {
  const intl = useIntl();

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  function coursestate(e, a, b) {
    const endDate = new Date(a);
    endDate.setHours(0, 0, 0, 0);

    const startDate = new Date(b);
    startDate.setHours(0, 0, 0, 0);

    if (e === 'private') {
      return (
        <p className="private">
          {intl.formatMessage(messages.Card_message_private)}
        </p>
      );
    } else if (e === 'published') {
      if (startDate > today) {
        return (
          <p className="published-coming">
            {intl.formatMessage(messages.Card_message_coming)}
          </p>
        );
      } else if (endDate >= today) {
        return (
          <p className="published">
            {intl.formatMessage(messages.Card_message_open)}
          </p>
        );
      } else if (endDate < today) {
        return (
          <p className="private">
            {intl.formatMessage(messages.Card_message_closed)}
          </p>
        );
      }
    }
  }

  return (
    <div className="course-cards-list-template">
      <Grid stackable>
        {items.map((item, index) => (
          <Grid.Column tablet={6} computer={4} widescreen={3} key={index}>
            <Card fluid key={index} as={Link} to={flattenToAppURL(item['@id'])}>
              <div className="course-cards-header">
                <div
                  className="course-cards-image"
                  style={{
                    backgroundImage: `url(${
                      flattenToAppURL(
                        item.image?.scales.large.download || '',
                      ) || defaultSVG
                    })`,
                  }}
                >
                  {coursestate(
                    item.review_state,
                    item.registration_end_date,
                    item.registration_start_date,
                  )}
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                  <path
                    fill="#F6F7F7"
                    fillOpacity="1"
                    d="M0,224L80,234.7C160,245,320,267,480,266.7C640,267,800,245,960,213.3C1120,181,1280,139,1360,117.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
                  ></path>
                </svg>
                <div className="course-cards-fee">€{item.price}</div>
              </div>
              <Card.Content>
                <div className="course-cards-title">{item.title}</div>
                <div className="course-cards-sub-title">
                  {item.assessment_item}
                </div>
              </Card.Content>

              <Card.Content extra>
                <Grid>
                  <Grid.Row columns={1}>
                    <Grid.Column
                      verticalAlign="bottom"
                      className="course-cards-date"
                    >
                      <span>
                        {intl.formatMessage(messages.registrationInterval)}
                      </span>
                      <div>
                        {moment(item.registration_start_date).format('Y') ===
                        moment(item.registration_end_date).format('Y')
                          ? moment(item.registration_start_date).format('D.M') +
                            '-' +
                            moment(item.registration_end_date).format('D.M.Y')
                          : moment(item.registration_start_date).format(
                              'D.M.Y',
                            ) +
                            '-' +
                            moment(item.registration_end_date).format('D.M.Y')}
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>
          </Grid.Column>
        ))}
      </Grid>
      <br />
    </div>
  );
};

CourseListingTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default CourseListingTemplate;
