import CourseListingBlockTemplate from '@package/components/manage/Blocks/Listing/CourseListingItem';
import ModuleListingBlockTemplate from '@package/components/manage/Blocks/Listing/ModuleListingItem';
import EnrolmentListingBlockTemplate from '@package/components/manage/Blocks/Listing/EnrolmentListingItem';
import LRFView from '@package/components/theme/View/LRFView';
import CourseView from '@package/components/theme/View/CourseView';
import ModuleView from '@package/components/theme/View/ModuleView';
import ApplicationView from '@package/components/theme/View/ApplicationView';
import ApplicationEdit from '@package/components/manage/Edit/ApplicationEdit';
import ApplicationAdd from '@package/components/manage/Add/ApplicationAdd';
import ViewBodyCaption from '@package/components/manage/Blocks/BodyCaption/View';
import EditBodyCaption from '@package/components/manage/Blocks/BodyCaption/Edit';
import ViewDashboard from '@package/components/manage/Blocks/Dashboard/View';
import EditMyCourses from '@package/components/manage/Blocks/MyCourses/Edit';
import ViewMyCourses from '@package/components/manage/Blocks/MyCourses/View';
import EditDashboard from '@package/components/manage/Blocks/Dashboard/Edit';
import ViewRedirectAuthenticated from '@package/components/manage/Blocks/RedirectAuthenticated/View';
import EditRedirectAuthenticated from '@package/components/manage/Blocks/RedirectAuthenticated/Edit';
import { VoltoPhotoIcon } from '@package/icons';
import { EPICS_TASK_VIEWS } from 'volto-epics-addon/constants';
import EditMyModules from '@package/components/manage/Blocks/MyModules/Edit';
import ViewMyModules from '@package/components/manage/Blocks/MyModules/View';
import cookie from 'cookie';
import { getCookie } from 'volto-epics-addon/middleware/helpers';
import contentMiddleware from '@package/middleware/content';

import {
  TASK_APPLICATION_VERIFY_CONTACT_INFORMATION,
  TASK_ACCOUNT_AUTHENTICATION_FORM,
  TASK_ACCOUNT_AUTHENTICATION_SIGNICAT_PROMPT,
  TASK_ACCOUNT_AUTHENTICATION_IDENTIFICATION_METHOD,
  TASK_CHECKOUT,
  TASK_CHECKOUT_CONFIRMATION_COURSE,
  TASK_CHECKOUT_CONFIRMATION_MODULE,
  TASK_EMAIL_LOGIN,
  TASK_PASSWORD_CHANGE_FORM,
  TASK_PASSWORD_CHANGE_SIGNICAT_PROMPT,
  TASK_PASSWORD_CHANGE_IDENTIFICATION_METHOD,
  TASK_PROCEED_TO_PAY,
  TASK_REGISTRATION_FORM,
  TASK_IDENTIFICATION_METHOD,
  TASK_SIGNICAT_CAPTURE,
  TASK_SIGNICAT_PROMPT,
  TASK_SUOMIFI_REDIRECT,
  TASK_VERIFY_EMAIL,
  TASK_VERIFY_EMAIL_PROMPT,
} from '@package/constants';
import {
  RadioButtonWidget,
  SisuCourseUnitWidget,
  SisuCourseUnitRealisationWidget,
  SisuAssessmentItemWidget,
  SisuCompletionMethodWidget,
  SisuEducationWidget,
  SisuStudySubgroupWidget,
  SisuModuleWidget,
  ApplicationSchemaWidget,
} from '@package/components';

import request from 'superagent';
import '@plone/volto/config';

export default function applyConfig(config) {
  config.settings.defaultPageSize = 100;
  config.settings.isMultilingual = true;
  config.settings.supportedLanguages = ['en', 'fi'];
  config.settings.defaultLanguage = 'fi';
  config.settings.nonContentRoutes = [
    ...config.settings.nonContentRoutes,
    EPICS_TASK_VIEWS[TASK_EMAIL_LOGIN],
    EPICS_TASK_VIEWS[TASK_VERIFY_EMAIL_PROMPT],
    EPICS_TASK_VIEWS[TASK_VERIFY_EMAIL],
    EPICS_TASK_VIEWS[TASK_IDENTIFICATION_METHOD],
    EPICS_TASK_VIEWS[TASK_SUOMIFI_REDIRECT],
    EPICS_TASK_VIEWS[TASK_SIGNICAT_PROMPT],
    EPICS_TASK_VIEWS[TASK_SIGNICAT_CAPTURE],
    EPICS_TASK_VIEWS[TASK_REGISTRATION_FORM],
    EPICS_TASK_VIEWS[TASK_CHECKOUT],
    EPICS_TASK_VIEWS[TASK_PROCEED_TO_PAY],
    EPICS_TASK_VIEWS[TASK_CHECKOUT_CONFIRMATION_COURSE],
    EPICS_TASK_VIEWS[TASK_CHECKOUT_CONFIRMATION_MODULE],
    EPICS_TASK_VIEWS[TASK_PASSWORD_CHANGE_SIGNICAT_PROMPT],
    EPICS_TASK_VIEWS[TASK_PASSWORD_CHANGE_IDENTIFICATION_METHOD],
    EPICS_TASK_VIEWS[TASK_PASSWORD_CHANGE_FORM],
    EPICS_TASK_VIEWS[TASK_ACCOUNT_AUTHENTICATION_SIGNICAT_PROMPT],
    EPICS_TASK_VIEWS[TASK_ACCOUNT_AUTHENTICATION_IDENTIFICATION_METHOD],
    EPICS_TASK_VIEWS[TASK_ACCOUNT_AUTHENTICATION_FORM],
    EPICS_TASK_VIEWS[TASK_APPLICATION_VERIFY_CONTACT_INFORMATION],
  ];

  config.settings.publicPath = '';

  config.widgets = {
    ...config.widgets,
    widget: {
      ...config.widgets.widget,
      radio: RadioButtonWidget,
    },
    id: {
      ...config.widgets.id,
      sisu_course_unit_id: SisuCourseUnitWidget,
      sisu_course_unit_realisation_id: SisuCourseUnitRealisationWidget,
      sisu_assessment_item_id: SisuAssessmentItemWidget,
      sisu_completion_method_id: SisuCompletionMethodWidget,
      sisu_education_id: SisuEducationWidget,
      sisu_study_subgroup_id: SisuStudySubgroupWidget,
      sisu_module_id: SisuModuleWidget,
      application_schema: ApplicationSchemaWidget,
    },
  };

  config.views.contentTypesViews = {
    ...config.views.contentTypesViews,
    LRF: LRFView,
    Course: CourseView,
    Module: ModuleView,
    Application: ApplicationView,
  };

  config.settings.contentTypesEdits = {
    Application: ApplicationEdit,
  };

  config.settings.contentTypesAdds = {
    Application: ApplicationAdd,
  };

  config.blocks.blocksConfig.listing.variations = [
    ...config.blocks.blocksConfig.listing.variations,
    {
      id: 'courselisting',
      title: 'Course listing',
      template: CourseListingBlockTemplate,
      fullobjects: true,
    },
    {
      id: 'modulelisting',
      title: 'Module listing',
      template: ModuleListingBlockTemplate,
      fullobjects: true,
    },
    {
      id: 'enrolmentlisting',
      title: 'Enrolment listing',
      template: EnrolmentListingBlockTemplate,
      fullobjects: true,
    },
  ];

  config.blocks.blocksConfig.search.schemaEnhancer = ({ schema }) => {
    delete schema?.properties?.facets?.schema?.properties?.field?.filterOptions;
    return schema;
  };

  config.blocks.blocksConfig.bodycaption = {
    id: 'bodycaption',
    title: 'Body Caption',
    icon: VoltoPhotoIcon,
    group: 'media',
    view: ViewBodyCaption,
    edit: EditBodyCaption,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    icon: VoltoPhotoIcon,
    group: 'media',
    view: ViewDashboard,
    edit: EditDashboard,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.mycourses = {
    id: 'mycourses',
    title: 'mycourses',
    icon: VoltoPhotoIcon,
    group: 'media',
    view: ViewMyCourses,
    edit: EditMyCourses,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.mymodules = {
    id: 'mymodules',
    title: 'mymodules',
    icon: VoltoPhotoIcon,
    group: 'media',
    view: ViewMyModules,
    edit: EditMyModules,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.redirectauthenticated = {
    id: 'redirectauthenticated',
    title: 'Redirect authenticated',
    icon: VoltoPhotoIcon,
    group: 'media',
    view: ViewRedirectAuthenticated,
    edit: EditRedirectAuthenticated,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.__grid = {
    ...config.blocks.blocksConfig.__grid,
    gridAllowedBlocks: [
      ...config.blocks.blocksConfig.__grid.gridAllowedBlocks,
      ...['html'],
    ],
  };

  // Add redux middleware to trigger content update camunda processes.
  const addCustomMiddleware = (middlewares) => {
    return [...middlewares, contentMiddleware];
  };
  config.settings.storeExtenders = [
    ...config.settings.storeExtenders,
    addCustomMiddleware,
  ];

  // Fix issue where users' browser misbehaved by POSTing login form to /login
  if (__SERVER__) {
    const express = require('express');
    const middleware = express.Router();
    middleware.post(
      `${config.settings.publicPath}/login`,
      function (req, res, next) {
        res.redirect(302, req.url);
      },
    );
    middleware.post(
      `${config.settings.publicPath}/validate-password`,
      function (req, res, next) {
        const cookies = cookie.parse(req.headers?.cookie || '');
        const lang =
          cookies?.['I18N_LANGUAGE'] || config.settings.defaultLanguage;
        request
          .post(`${config.settings.processApiPath}/validate-password`)
          .set('Accept-Language', lang)
          .send(req.body)
          .then((apiResp) => {
            res.send({});
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              // Validation error
              res.send(error.response.body);
            } else {
              // Other errors
              // eslint-disable-next-line no-console
              console.error('Could not validate:', error);
              res.send({});
            }
          });
      },
    );
    middleware.post(
      `${config.settings.publicPath}/trigger-password-change`,
      async function (req, res, next) {
        const processId = await getCookie(req, 'processId');
        const businessKey = await getCookie(req, 'businessKey');

        if (processId && businessKey) {
          try {
            const { body: epic } = await request
              .post(
                `${config.settings.processApiPath}/process/${processId}/${businessKey}/message/portal.password.change`,
              )
              .send({
                variables: req.body || {},
              });
            // eslint-disable-next-line no-console
            console.log(new Date(), '[epic]', 'message', epic);
            res.send(epic);
          } catch (e) {
            //
            // 400-500 epic not found
            //
            // eslint-disable-next-line no-console
            console.log(
              new Date(),
              '[epic]',
              'message',
              e?.status ?? 500,
              e?.response?.text ?? e,
            );
            res.status(e?.status ?? 404);
            res.send(
              e?.response?.type === 'application/json'
                ? JSON.parse(e?.response?.text ?? 'null')
                : e?.response?.text ?? 'n/a',
            );
          }
        } else {
          //
          // 400 Invalid cookie
          //
          res.status(400);
          res.send({ processId, businessKey });
        }
      },
    );
    middleware.id = 'root-middleware';
    config.settings.expressMiddleware = [
      ...config.settings.expressMiddleware,
      middleware,
    ];
  }

  return config;
}
