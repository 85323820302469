import React, { useEffect } from 'react';
import { defaultTitle } from '@package/helpers';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from '@plone/volto/helpers';
import { EPICS_PATH_CONTINUE } from 'volto-epics-addon/constants';
import { TASK_VERIFY_EMAIL_PROMPT } from '@package/constants';
import { completeTask, useTask } from 'volto-epics-addon/helpers';
import EmailConfirmContainer from '@package/components/theme/Login/EmailConfirmContainer';
import EmailInputContainer from '@package/components/theme/Login/EmailInputContainer';
import EmailRegisterContainer from '@package/components/theme/Login/EmailRegisterContainer';
import { useHistory } from 'react-router';
import './EmailSignUpPage.less';

const messages = defineMessages({
  title: {
    id: 'Sign in',
    defaultMessage: 'Sign in',
  },
  signUp: {
    id: 'Sign up',
    defaultMessage: 'Sign up',
  },
  couldNotFind: {
    id: 'signUpCouldNotFind',
    defaultMessage: 'We could not find your account.',
  },
  createAccount: {
    id: 'signUpCreateAccount',
    defaultMessage:
      'An email will be sent to you to confirm registration. Please check that the email below is correct.',
  },
});

const EmailSignUpPage = () => {
  const intl = useIntl();
  const history = useHistory();
  const [email, setEmail] = React.useState('');
  const [confirmedEmail, setConfirmedEmail] = React.useState('');
  const [signUp, setSignUp] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const task = useTask(TASK_VERIFY_EMAIL_PROMPT);
  const signUpForm =
    email && (!confirmedEmail || email === confirmedEmail) ? (
      <EmailConfirmContainer
        email={email}
        onConfirm={() => onSubmit(email)}
        loading={loading}
        onCancel={() => {
          setEmail('');
        }}
      />
    ) : (
      <EmailRegisterContainer
        email={confirmedEmail}
        onChange={setConfirmedEmail}
        onSubmit={() => onSubmit(confirmedEmail)}
      />
    );

  useEffect(() => {
    if (task?.completed === true) {
      setLoading(true);
      history.push(EPICS_PATH_CONTINUE);
    } else {
      if (!email) {
        setEmail(task?.variables?.email ?? '');
        setConfirmedEmail(task?.variables?.email ?? '');
      }
      setSignUp(task?.variables?.signUp ?? false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  const onSubmit = async (confirmedEmail) => {
    setLoading(true);
    try {
      await completeTask(TASK_VERIFY_EMAIL_PROMPT, {
        confirmedEmail,
        signUp,
      });
    } catch (e) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return signUp === false ? (
    <EmailInputContainer
      loading={loading}
      setLoading={setLoading}
      email={confirmedEmail}
      onSubmit={
        email !== confirmedEmail ? () => onSubmit(confirmedEmail) : null
      }
      onRegister={() => setSignUp(true)}
      error={intl.formatMessage(messages.couldNotFind)}
      onChange={setConfirmedEmail}
    />
  ) : (
    <div id="main-login-page" className="EmailSignUpPage">
      <Helmet title={defaultTitle(intl.formatMessage(messages.signUp))} />
      <div className="login-container">
        <div>
          <div className="email-login-title">
            <h1>
              <FormattedMessage
                id="Registration"
                defaultMessage="Registration"
              />
            </h1>

            <p>{intl.formatMessage(messages.createAccount)} </p>
          </div>
          {signUpForm}
        </div>
      </div>
    </div>
  );
};

export default EmailSignUpPage;
